import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Card, Row , Col, Form, Modal, Accordion, Spinner, Alert} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';
import axios from 'axios';


import Plus from '../../images/add.svg';
import Trash from '../../images/trash.svg';
import Eye from '../../images/olho.svg';
import Delete from '../../images/close.svg';
import Check from '../../images/check.png';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { toast, ToastContainer } from 'react-toastify';
import InputMask from "react-input-mask";


import './styles.css';
import { Collapse } from '@material-ui/core';

export default function Processos(){
    const [loading, setLoading] = useState(true);
    const [fornecedor, setFornecedor] = useState([]);
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [alert1, setAlert1] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [alert3, setAlert3] = useState(false);
    const [alert4, setAlert4] = useState(false);
    const [filtred, setFiltred] = useState([]);
    const [search, setSearch] = useState("");
    var [forId_Fornecedor, setForId_Fornecedor] = useState('');
    var [forNome, setForNome] = useState('');
    var [forEmail, setForEmail] = useState('');
    var [forIE, setForIE] = useState('');
    var [forIM, setForIM] = useState('');
    var [forCEP, setForCEP] = useState('');
    var [forUF, setForUF] = useState('');
    var [forNum, setForNum] = useState('');
    var [forTelefone1, setForTelefone1] = useState('');
    var [forTelefone2, setForTelefone2] = useState('');
    var [forBairro, setForBairro] = useState('');
    var [forCidade, setForCidade] = useState('');
    var [forRua, setForRua] = useState('');
    var [forComplemento, setForComplemento] = useState('');
    var [forCNPJ, setForCNPJ] = useState('');
    var [forData, setForData] = useState('');
    var [forId_Usuario, setForId_Usuario] = useState('');
  const [mask, setMask] = useState("");
  const [idDelete, setIdDelete] = useState('');



    
async function handleCEP(){
    let one = "http://viacep.com.br/ws/"+forCEP+"/json";
    axios.get(one).then(response =>{
      //console.log(response.data);
      setForRua(response.data.logradouro);
      setForCidade(response.data.localidade);
      setForBairro(response.data.bairro);
      setForUF(response.data.uf);
    }).catch(function (error){
        setForRua('');
        setForCidade('');
        setForBairro('');
        setForUF('');
    });

  }

  const notify = (txt) => toast.success(txt, {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });


    const erro = (txt) => toast.error(txt, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

    
    const history = useHistory();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    async function handlerDelete(id,index){
        document.getElementById('modalDel').style.width = '0px';
        document.getElementById('modalDel').style.display = 'none';
        api.post("api/deleteFornecedor",{Id_Fornecedor: id}).then(function(response) {
            console.log(response.data);
            notify("Excluído com sucesso.");
            
      
            setFornecedor(response.data);
            setSearch('');
            setFiltred(response.data);
        });
        
    }

    async function handleCreate(e){
        const Id_Usuario = localStorage.getItem('Id_Usuario');

        if(!forNome
             == ''){
            const data = {
                forNome,
                forEmail,
                forIE,
                forIM,
                forCEP,
                forUF,
                forNum,
                forTelefone1,
                forTelefone2,
                forBairro,
                forCidade,
                forRua,
                forComplemento,
                forCNPJ,
                //forData,
                Id_Usuario
            };
            try {
                api.post('api/createFornecedor', data)
                .then(async res => {
                    handleClose();
                    notify("Criado com sucesso.");
                    setFornecedor([...fornecedor,res.data]);
                });
            } catch (err) {
                erro("Forneça todos os dados.");
               
            }
        }else{
            erro("Forneça todos os dados.");

        }
    }

    async function handlerEdit(Fornecedor,index){
        forId_Fornecedor = Fornecedor.Id_Fornecedor;
        forId_Usuario = localStorage["Id_Usuario"];
        if(forNome==''){
            forNome = Fornecedor.Nome;
        }
        if(forEmail==''){
            forEmail = Fornecedor.email;
        }
        if(forIE==''){
            forIE = Fornecedor.IE;
        }
        if(forIM==''){
            forIM = Fornecedor.IM;
        }
        if(forTelefone1==''){
            forTelefone1 = Fornecedor.Telefone1;
        }
        if(forTelefone2==''){
            forTelefone2 = Fornecedor.Telefone2;
        }
        if(forCEP==''){
            forCEP = Fornecedor.CEP;
        }
        if(forUF===''){
            forUF = Fornecedor.UF;
        }
        if(forCidade===''){
            forCidade = Fornecedor.Cidade;
        }
        if(forBairro===''){
            forBairro = Fornecedor.Bairro;
        }
        if(forRua===''){
            forRua = Fornecedor.Rua;
        }
        if(forNum===''){
            forNum = Fornecedor.Num;
        }
        if(forComplemento===''){
            forComplemento = Fornecedor.Complemento;
        }
        if(forCNPJ===''){
            forCNPJ = Fornecedor.CNPJ;
        }
        try{
                api.post("api/editarFornecedor",{
                forId_Fornecedor,
                forId_Usuario,
                forNome,
                forEmail,
                forBairro,
                forCEP,
                forCNPJ,
                forCidade,
                forComplemento,
                forIE,
                forNum,
                forRua,
                forTelefone1,
                forTelefone2,
                forUF,
                forIM
            }).then(function(response){
                let newState = [...fornecedor];
                newState[index] = response.data;
                setFornecedor(newState);  
                notify("Atualizado com sucesso.");
                
            });


        }catch(e){
            console.log(e);
        }
    }
    function handlerOpenDelete(id){
        setIdDelete(id);
        document.getElementById('modalDel').style.width = '17rem';
        document.getElementById('modalDel').style.display = 'inline-block';

    }

    function handlerCancelDelete(id){
        setIdDelete('');
        document.getElementById('modalDel').style.width = '0px';
        document.getElementById('modalDel').style.display = 'none';
    }

        useEffect(() => {
            try{
                api.post("api/allFornecedor")
                .then(function(response) {
                setFornecedor(response.data);
                setLoading(false);
                });
                
                }catch(error){
                    console.log(error);
                }

            }, []);

            useEffect(() => {
                setFiltred(
                  fornecedor.filter((fornecedo) =>
                  fornecedo.Nome.toLowerCase().includes(search.toLowerCase())
                  )
                );
              }, [search, fornecedor]);
            console.log(fornecedor);

            if(loading == false){
    return (
       
        <Container>
                <div id="modalDel" className="confirmarModal">
                    <h3>Confirmar Exclusão</h3>
                    <Row className="justify-content-md-center mt-4">
                        <img className="checks mr-2" src={Check} onClick={() => handlerDelete(idDelete)}/>
                        <img className="checks neg mr-2" src={Delete} onClick={() => handlerCancelDelete()}/>
                    </Row>
                </div>
            <h1 className="il">Q</h1>
            <Link to="/dashboard">
                <Button className="ir">
                        Voltar
                </Button>
            </Link>
            <ToastContainer autoClose={4000} />
            
            {alert1  && <Alert variant="success" size="md" dismissible onClose={() =>setAlert1(false)}>Excluído com sucesso.</Alert>}
            {alert2  && <Alert variant="success" size="md" dismissible onClose={() =>setAlert2(false)}>Criado com sucesso.</Alert>}
            {alert4  && <Alert variant="success" size="md" dismissible onClose={() =>setAlert4(false)}>Atualizado com sucesso.</Alert>}
            <Row className="justify-content-md-center">
                <Col xs={12} md={8}>
                    <Row>
                        <Col xs={12} md={6}><h2 className="ml-2">Fornecedor</h2></Col> 
                        <h2>Filtro:</h2><Col className="mb-2" xs={12} md={4}><Form.Control value={search} onChange={(e) => setSearch(e.target.value)}></Form.Control></Col>
                    </Row>
                    <Card className="card_items">
                        <Card.Body>
                        <Accordion defaultActiveKey="0">
                            {filtred.map((fornecedor,index)=>
                                <Row className="item_tipo">
                                    <Col xs={12} md={12}>
                                        <Accordion.Toggle as={Card.Header} className="mt-2" variant="link" eventKey={fornecedor.Id_Fornecedor}><Row><Col xs={8} md={10}>{fornecedor.Nome}</Col>
                                        {(fornecedor.Usado == 1)? '' :
                                        <Col xs={2} md={2}><div className="mg-top"><button  onClick={() => handlerOpenDelete(fornecedor.Id_Fornecedor,index)}><img className="img" src={Trash}></img></button></div></Col>
                                        }
                                        </Row></Accordion.Toggle>

                                        <Accordion.Collapse eventKey={fornecedor.Id_Fornecedor}>
                                            <Col xs={12} md={12}>
                                                <Row className="justify-content-md-center ml-2 mr-2 mt-2 mb-2">
                                                    <Col>
                                                        <Form.Label>Nome</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={fornecedor.Nome} onChange={e => setForNome(e.target.value)} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>E-mail</Form.Label>
                                                        <Form.Control size="md" type="email" placeholder={fornecedor.Email} onChange={e => setForEmail(e.target.value)} />
                                                    </Col>
                                                </Row>
                                                <Row className="justify-content-md-center ml-2 mr-2 mt-2">
                                                    <Col>
                                                        <Form.Label>IE</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={fornecedor.IE} onChange={e => setForIE(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>IM</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={fornecedor.IM} onChange={e => setForIM(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} />
                                                    </Col>
                                                </Row >
                                                <Row className="justify-content-md-center ml-2 mr-2 mt-2">
                                                    <Col>
                                                        <Form.Label>Rua</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={fornecedor.Rua} onChange={e => setForRua(e.target.value)} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>Número</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={fornecedor.Num} onChange={e => setForNum(e.target.value)}  onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                                                    </Col>
                                                   
                                                </Row>
                                                <Row className="justify-content-md-center ml-2 mr-2 mt-2 mb-2">
                                                    <Col>
                                                        <Form.Label>Telefone 1</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={fornecedor.Telefone1} onChange={e => setForTelefone1(e.target.value)} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>Telefone 2</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={fornecedor.Telefone2} onChange={e => setForTelefone2(e.target.value)} />
                                                    </Col>
                                                </Row>
                                                <Row className="justify-content-md-center ml-2 mr-2 mt-2 mb-2">
                                                    <Col>
                                                        <Form.Label>UF</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={fornecedor.UF} onChange={e => setForUF(e.target.value)} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>Bairro</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={fornecedor.Bairro} onChange={e => setForBairro(e.target.value)} />
                                                    </Col>
                                                </Row>
                                                <Row className="justify-content-md-center ml-2 mr-2 mt-2 mb-2">
                                                    <Col>
                                                        <Form.Label>Complemento</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={fornecedor.Complemento} onChange={e => setForComplemento(e.target.value)} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>CNPJ</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={fornecedor.CNPJ} onChange={e => setForCNPJ(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                                                    </Col>
                                                </Row>
                                                <Row className="justify-content-md-center ml-2 mr-2 mt-2 mb-2">
                                                    <Col>
                                                        <Form.Label>Cidade</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={fornecedor.Cidade} onChange={e => setForCidade(e.target.value)} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>CEP</Form.Label>
                                                        <Form.Control maxLength="8" size="md" type="text" placeholder={fornecedor.CEP} onChange={e => setForCEP(e.target.value)}  onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                                                    </Col>
                                                </Row>
                                                {(fornecedor.Usado == 1)? '' :
                                                <Button className="ml-3 mb-2" variant="primary"  onClick={() => handlerEdit(fornecedor)}>
                                                    Confirmar
                                                </Button>
                                                }   
                                            </Col>
                                        </Accordion.Collapse>

                                    </Col>
                                    
                                </Row>
                            )}
                            </Accordion>
                            <Col>
                                <div className="item_tipo text-center">
                                   <p>Adicionar <button className="btn btn-link" onClick={handleShow}> <img src={Plus} className="img"/></button></p>
                                        <Modal show={show} onHide={handleClose}>
                                            {alert3  && <Alert variant="danger" size="md" className="mt-2" dismissible onClose={() =>setAlert3(false)}>Dados incompletos.</Alert>}
                                            <Modal.Header closeButton>
                                            <Modal.Title>Criando fornecedor</Modal.Title>
                                            </Modal.Header>
                                            <Row className="justify-content-md-center ml-2 mr-2 mt-2">
                                                <Col>
                                                    <Form.Label>Nome</Form.Label>
                                                    <Form.Control size="md" type="text" placeholder="Digite o nome" onChange={e => setForNome(e.target.value)} />
                                                </Col>
                                                <Col>
                                                    <Form.Label>E-mail</Form.Label>
                                                    <Form.Control size="md" type="email" placeholder="Digite o email" onChange={e => setForEmail(e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Row className="justify-content-md-center ml-2 mr-2 mt-2">
                                                <Col>
                                                <Form.Label>IE</Form.Label>
                                                    <Form.Control maxLength="20" size="md" type="text" placeholder="Digite a IE" onChange={e => setForIE(e.target.value)} />
                                                </Col>
                                                <Col>
                                                <Form.Label>IM</Form.Label>
                                                    <Form.Control maxLength="20" size="md" type="text" placeholder="Digite a IM" onChange={e => setForIM(e.target.value)} />
                                                </Col>
                                            </Row >

                                            <Row className="justify-content-md-center ml-2 mr-2 mt-2 mb-2">
                                                <Col>
                                                <Form.Label>Telefone 1</Form.Label>
                                                    <InputMask className="form-control" id="Telefone1" mask="99 99999-9999"   onChange={e => setForTelefone1(e.target.value)} />
                                                </Col>
                                                <Col>
                                                <Form.Label>Telefone 2</Form.Label>
                                                    <InputMask className="form-control" id="Telefone1" mask="99 99999-9999"   onChange={e => setForTelefone2(e.target.value)} />

                                                </Col>
                                            </Row>
                                            <Row className="justify-content-md-center ml-2 mr-2 mt-2 mb-2">
                                                <Col>
                                                <Form.Label>CEP</Form.Label>
                                                    <Form.Control maxLength="8" size="md" type="text" placeholder="Digite o CEP" value={forCEP} onChange={e => setForCEP(e.target.value)} onBlur={()=> handleCEP()} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} />
                                                </Col>
                                                <Col>
                                                <Form.Label>Cidade</Form.Label>
                                                    <Form.Control size="md" type="text" placeholder="Digite o nome da cidade" value={forCidade} onChange={e => setForCidade(e.target.value)} />
                                                </Col>
                                                
                                            </Row>
                                            <Row className="justify-content-md-center ml-2 mr-2 mt-2">
                                                <Col>
                                                <Form.Label>Número</Form.Label>
                                                    <Form.Control maxLength="5" size="md" type="text" placeholder="Digite o número " value={forNum} onChange={e => setForNum(e.target.value)} />
                                                </Col>
                                                <Col>
                                                <Form.Label>Rua</Form.Label>
                                                    <Form.Control size="md" type="text" placeholder="Digite a rua" value={forRua} onChange={e => setForRua(e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Row className="justify-content-md-center ml-2 mr-2 mt-2 mb-2">
                                                <Col>
                                                <Form.Label>UF</Form.Label>
                                                    <Form.Control size="md" type="text" placeholder="Digite a UF" value={forUF} onChange={e => setForUF(e.target.value)} />
                                                </Col>
                                                <Col>
                                                <Form.Label>Bairro</Form.Label>
                                                    <Form.Control size="md" type="text" placeholder="Digite o bairro" value={forBairro} onChange={e => setForBairro(e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Row className="justify-content-md-center ml-2 mr-2 mt-2 mb-2">
                                                <Col>
                                                <Form.Label>Complemento</Form.Label>
                                                    <Form.Control maxLength="10" size="md" type="text" placeholder="Digite o complemento" value={forComplemento} onChange={e => setForComplemento(e.target.value)} />
                                                </Col>
                                                <Col>
                                                <Form.Label>CNPJ</Form.Label>
                                                <CpfCnpj
                                                id="CPFCNPJ"
                                                type="text"
                                                value={forCNPJ}
                                                placeholder="Digite o CNPJ"
                                                className="form-control"
                                                onChange={(event, type) => {
                                                    setForCNPJ(event.target.value);
                                                    setMask(type === "CNPJ");
                                                }}
                                                />
                                                </Col>
                                            </Row>
                                            
                                            <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Fechar
                                            </Button>
                                            <Button variant="primary" onClick={handleCreate}>
                                                Adicionar
                                            </Button>
                                            </Modal.Footer>
                                    </Modal>
                                </div>
                            </Col>
                        </Card.Body>
                        
                    </Card>
                </Col>
            </Row>
            
        </Container>
    
    );
    }else{
        return (
            <Row className="justify-content-center">
        <Spinner animation="border" className="central" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
        </Row>
        );
    }
}