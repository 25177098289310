import api from '../../../services/api';
import React, { useEffect, useState } from 'react';
import { Button, Form , Col, Row, Container, Card, Spinner, Alert} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Trash from '../../../images/trash.svg';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import NumberFormat from 'react-number-format';

export default  function Entrada(){
        //console.log(localStorage.getItem('token'));
        const [loading, setLoading] = useState(true);
        const [produtos, setProdutos] = useState('');
        const [processos, setProcessos] = useState('');
        const [selected, setSelected] = useState('');
        const [Chave_Acesso, setChave_Acesso] = useState('');
        const [Valor_Total, setValor_Total] = useState(0);
        const [Data_Entrada, setData_Entrada] = useState('');
        const [Preco, setPreco] = useState('');
        const [Quantidade, setQuantidade] = useState('');
        const [CodProduto, setCodProduto] = useState('');
        const [Id_Fornecedor, setId_Fornecedor] = useState('');
        const [screen, setScreen] = useState(false);
        const [screenCadastro, setScreenCadastro] = useState(false);
        const [allProcessos, setAllProcessos] = useState([]);
        const [allProdutos, setAllProdutos] = useState([]);
        var [selectedsIndex, setSelectedsIndex] = useState([]);
        var [selectedsPecas, setSelectedsPecas] = useState([]);
        const [alert1, setAlert1] = useState(false);
        const [alert2, setAlert2] = useState(false);
        const [alert3, setAlert3] = useState(false);
        const [alert4, setAlert4] = useState(false);
        const [alert5, setAlert5] = useState(false);
        const [alert6, setAlert6] = useState(false);
        const [alert7, setAlert7    ] = useState(false);
        const [qtd, setQtd] = useState('');
        const [categorias, setCategorias] = useState('');
        const [nomeMateria, setNomeMateria] = useState('');
        const [selectedTipoProduto, setSelectedTipoProduto] = useState('');
        const [nomePeca, setNomePeca] = useState('');
        const [unit, setUnit] = useState('');
        const [produtoResultado, setProdutoResultado] = useState('');


        const notify = (txt) => toast.success(txt, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
            
            const erro = (txt) => toast.error(txt, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });

        async function handlerCreate(CodProduto){
            document.getElementById("NOME").style.borderColor = "";

            const Id_Usuario = localStorage.getItem('Id_Usuario');
            if(selectedTipoProduto != '')
            var Id_Tipo_Produto = categorias[selectedTipoProduto].Id_Tipo_Produto;
            var nomeProduto =  nomePeca;
            if(nomePeca != ''){
                        if(Quantidade != ''){
                            document.getElementById("QUANTIDADE").style.borderColor = "";
                            var selecteds = allProcessos;
                            if(CodProduto){
                                var data ={
                                    Id_Usuario, Quantidade, selecteds, allProdutos,Id_Tipo_Produto,CodProduto
                                }
                            }else{
                                var data ={
                                    Id_Usuario, Quantidade, selecteds, allProdutos,Id_Tipo_Produto,nomeProduto
                                }  
                            }
                            
                            api.post('api/createProdutoResultado', data)
                            .then(async res => {
                                
                                if(res.data[1] == "Error"){
                                    console.log(res.data);

                                    setQtd(res.data[2]);
                                    setNomeMateria(res.data[3]);
                                    erro("Verifique o estoque de "+res.data[3]);
                                }else{
                                    setAlert6(false);
                                    if(res.data[1] == "ErroEstoque"){
                                        erro("Verifique o estoque de "+res.data[3]);

                                    }else{
                                        setAlert7(false);
                                        notify("Produzido com sucesso.");
                                        localStorage.setItem('Producao',1);
                                        history.push('/quansquie/produtosResultados');
                                    }
                                }

                            }).catch(function() {
                                erro("Matéria-Prima com quantidade vazia.")
                            });
                        }else{
                            document.getElementById("QUANTIDADE").style.borderColor = "red";
                            erro("Informe a quantidade.");
                        }
                }else{
                    document.getElementById("NOME").style.borderColor = "red";
                    erro("Informe o nome.");

                }
                    
        }

        function todoEdit(){
            if(!processos[selectedsIndex]){
                setAllProcessos([...allProcessos,processos[0]]);
            }else{
                setAllProcessos([...allProcessos,processos[selectedsIndex]])
            }
        }  

        function todoPeca(){
            if(!produtos[selectedsPecas]){
                produtos[0]["pivot"] = {
                    Quantidade : 0,
                    Porcentagem : 100
                };
                setAllProdutos([...allProdutos,produtos[0]]);
            }else{
                produtos[selectedsPecas]["pivot"] = {
                    Quantidade : 0,
                    Porcentagem : 100
                };
                setAllProdutos([...allProdutos,produtos[selectedsPecas]])
            }
        } 

        async function handlerTela(e){
            console.log(produtos,e);
            if(e > -1){
                setSelected(e);
                setCodProduto(produtoResultado[e].Cod_ProdutoResultado);
                setAllProdutos(produtoResultado[e].produto_acabado);
                setAllProcessos(produtoResultado[e].processo);
                setScreen(true);
            }else if(e == -1){
                setScreen(true);
                setScreenCadastro(true);
            }else{
                setScreen(false);

            }
        }

        async function removeTodoAll(){
            setAllProcessos([]);
        }

        async function removeTodoPecas(){
            setAllProdutos([]);
        }

        function removeTodo(index){
            const list = allProcessos;
            list.splice(index,1);
            setAllProcessos([...list]);
        }

        function removeTodoProdutos(index){
            const list = allProdutos;
            list.splice(index,1);
            setAllProdutos([...list]);
        }

        function setQntPivot(valor, objeto){
            objeto.pivot.Quantidade = valor;
        }
        

        const history = useHistory();

        useEffect(() => {
            api.post("api/getProdutos_Acabados")
            .then(function(response) {
                setProdutos(response.data);
                api.post("api/indexProcesso")
                .then(function(response) {

                    response.data.forEach(fcusto =>{
                        fcusto.Custo = parseFloat(fcusto.Custo).toFixed(2).toString().replace('.',',');
                    })
                    setProcessos(response.data);
                    api.post("api/getTipoProduto")
                    .then(function(response){
                        
                        setCategorias(response.data);

                        api.post("api/getProdutoResultado")
                        .then(function(response){
                            setProdutoResultado(response.data);
                            console.log(response.data);
                            //setSelectedTipoProduto(-1);
                            setSelectedTipoProduto('0');  
                            setLoading(false);
                        });
                    });
                });
            });

        }, []);
       // console.log(produtos);


if(loading == false){
    if(screen == false){
        return (
            
            
            <Container>
                <h1 className="il">Q</h1>
                <Link to="/quansquie/">
                    <Button className="ir">
                            Voltar
                    </Button>
                </Link>
                <Row className="justify-content-md-center mt-5 mb-1">
                            <Col md={6}>
                            <h2>Entrada</h2>
                                <Card className="card_items mt-5 mb-5">
                                    <Card.Body>
                                        <h2>Selecione o produto</h2>
                                        <select className="form-control form-control-md" onChange={(e) => handlerTela(e.target.value)}>
                                            <option value="-2">Selecione...</option>
                                            <option value="-1">Novo Produto</option>
                                            {produtoResultado.map((produto, index) =>
                                                <option value={index}>{produto.Nome}</option>
                                            )}
                                            
                                        </select>
                                    </Card.Body>
                                    
                                </Card>
                            </Col>
                        </Row>
            </Container>
        );
    }else if(screenCadastro == false){
        return (
            !loading &&
            <Container>
                <h1 className="il">Q</h1>
                <Link to="/quansquie/">
                    <Button className="ir">
                            Voltar
                    </Button>
                </Link>
            <ToastContainer autoClose={4000} />

                {alert1  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert1(false)}>Excluído com sucesso.</Alert>}
                {alert2  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert2(false)}>Adicionado com sucesso.</Alert>}
                {alert3  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert3(false)}>Preencha corretamente os campos.</Alert>}
                {alert4  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert4(false)}>Atualizado com sucesso.</Alert>}
                {alert5  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert5(false)}>Saída cadastrada com sucesso.</Alert>}
                {alert6  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert6(false)}>Há apenas {qtd} {unit} de(a) {nomeMateria} no estoque.</Alert>}
                {alert7  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert7(false)}>A quantidade a ser debitada de matéria-prima fará o estoque ir abaixo do estoque mínimo. Favor conferir ou dar entrada.</Alert>}
                <Row className="justify-content-md-center mt-5 mb-1">
                            <Col xs={12} md={8}>
                            <h2>Produção</h2>
                                <Card className="card_items  mb-2">
                                    <Card.Body>
                                       <Row className="mb-2">
                                            <Col xs={12} md={6} className="mt-2">
                                                <Form.Label>Nome</Form.Label>
                                                <Form.Control value={produtoResultado[selected].Nome} disabled />
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <Form.Label className="mt-2">Tipo</Form.Label>
                                                <Form.Control id="TIPO"  placeholder={produtoResultado[selected].tipo_produto[0].Nome+'-'+produtoResultado[selected].tipo_produto[0].Tamanho} disabled/>
                                                
                                            </Col>
                                           <Col xs={12} md={2}>
                                                <Form.Label className="mt-2">Quantidade</Form.Label>
                                                <Form.Control id="QUANTIDADE"  placeholder="00" onChange={(e) => setQuantidade(e.target.value)}/>
                                            </Col>

                                       </Row>
                                       <Row className="justify-content-md-center mt-4 item_tipo">
                                            <Form.Label> <h4 className="ml-4 mt-2">Lista de peças</h4></Form.Label>
                                                <Container className="justify-content-sm-center">
                                                    <Row className="ml-2"><Col xs={12} md={9}><Form.Label><h4>Nome</h4></Form.Label></Col><Col xs={12} md={3}><Form.Label><h4>Quantidade</h4></Form.Label></Col></Row>
                                                    {allProdutos.map((produtosSelected) => (
                                                        <Row className="ml-2">
                                                            <Col xs={12} md={9}>
                                                                <Form.Label className="form-control form-control-gb">{produtosSelected.Nome}</Form.Label>
                                                            </Col>
                                                            <Col xs={3} md={2}>
                                                                <Form.Control onChange={(e) => setQntPivot(e.target.value,produtosSelected)} placeholder={produtosSelected.pivot.Quantidade}/>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </Container>
                                            </Row> 
                                            <Row className="justify-content-md-center mt-2 item_tipo">
                                            <Form.Label> <h4 className="ml-4 mt-2">Lista de processos</h4></Form.Label>
                                                <Container className="justify-content-sm-center">
                                                    <Row className="ml-2"><Col xs={4} md={6}><Form.Label><h4>Nome</h4></Form.Label></Col><Col xs={4} md={4}><Form.Label><h4>Custo</h4></Form.Label></Col></Row>
                                                    {allProcessos.map((selecteds) => (
                                                        <Col md={12} xs={12}>
                                                        <Row className="ml-2">
                                                            <Col xs={4} md={6}>
                                                                <Form.Label className="form-control form-control-gb">{selecteds.Nome}</Form.Label>
                                                            </Col>
                                                            <Col xs={4} md={6}>
                                                                <NumberFormat as={Form.Label}  prefix={'R$'} allowNegative={false} allowLeadingZeros={false} decimalSeparator={','} isNumericString={true} decimalScale={2} fixedDecimalScale={true} value={selecteds.Custo} className="form-control form-control-md" inputmode="decimal" readOnly={true} />
                                                            </Col>
                                                        </Row>
                                                        </Col>
                                                    ))}
                                                </Container>
                                            </Row>                                        
                                    </Card.Body>                                                                       
                                </Card>
                                <Row className="justify-content-center mt-3">
                                    <Button variant="warning" className="text-center" onClick={()=> handlerCreate(CodProduto)}>Produzir</Button>
                                </Row>
                            </Col>
                        </Row>
            </Container>
        );


        //Dando distância
    }else{
        //ScreanCadasrtro


        return (
            !loading &&
            
            <Container>
                <h1 className="il">Q</h1>
                <Link to="/quansquie/">
                    <Button className="ir">
                            Voltar
                    </Button>
                </Link>
            <ToastContainer autoClose={4000} />

                {alert1  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert1(false)}>Excluído com sucesso.</Alert>}
                {alert2  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert2(false)}>Adicionado com sucesso.</Alert>}
                {alert3  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert3(false)}>Preencha corretamente os campos.</Alert>}
                {alert4  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert4(false)}>Atualizado com sucesso.</Alert>}
                {alert5  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert5(false)}>Saída cadastrada com sucesso.</Alert>}
                {alert6  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert6(false)}>Há apenas {qtd} {unit} de(a) {nomeMateria} no estoque.</Alert>}
                {alert7  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert7(false)}>A quantidade a ser debitada de matéria-prima fará o estoque ir abaixo do estoque mínimo. Favor conferir ou dar entrada.</Alert>}
                <Row className="justify-content-md-center mt-5 mb-1">
                            <Col xs={12} md={9}>
                            <h2>Produção</h2>
                                <Card className="card_items  mb-1">
                                    <Card.Body>
                                       <Row className="">
                                            <Col xs={12} md={6} className="mt-2">
                                                <Form.Label>Nome</Form.Label>
                                                <Form.Control id="NOME" onChange={(e) => setNomePeca(e.target.value)}  />
                                            </Col>
                                           <Col xs={12} md={4}>
                                                <Form.Label className="mt-2">Tipo</Form.Label>
                                                <select className="form-control form-control-md" value={selectedTipoProduto} onChange={(e) => setSelectedTipoProduto(e.target.value)}>
                                                        {categorias.map((categoria, index) =>
                                                            <option value={index}>{categoria.Nome+"-"+categoria.Tamanho}</option>
                                                        )}
                                                    </select>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <Form.Label className="mt-2">Quantidade</Form.Label>
                                                <Form.Control id="QUANTIDADE"  placeholder="00" onChange={(e) => setQuantidade(e.target.value)}/>
                                            </Col>

                                            <Col xs={12} md={4}>
                                                    <Form.Label className="mt-2">Peças</Form.Label>
                                                    <select className="form-control form-control-md" id="TIPOS" onChange={(e) => setSelectedsPecas(e.target.value)}>
                                                        {produtos.map((produtos, index) =>
                                                            <option value={index}>{produtos.Nome}</option>
                                                        )}
                                                    </select>
                                            </Col>
                                            <Col xs={12} md={2} className="mt-2 text-right">
                                                    <Button variant="secondary" className="mt-4" onClick={()=> todoPeca(selectedsPecas)}>Adicionar</Button>
                                            </Col>

                                            <Col xs={12} md={4}>
                                                    <Form.Label className="mt-2">Processos</Form.Label>
                                                    <select className="form-control form-control-md" onChange={(e) => setSelectedsIndex(e.target.value)}>
                                                        {processos.map((processo, index) =>
                                                            <option value={index}>{processo.Nome +' - '+ "R$ "+processo.Custo}</option>
                                                        )}
                                                    </select>
                                            </Col>
                                            <Col xs={12} md={2} className="mt-2 text-right">
                                                    <Button variant="secondary" className="mt-4" onClick={()=> todoEdit(selected)}>Adicionar</Button>
                                            </Col>
                                       </Row>
                                       <Row className="justify-content-md-center mt-2 item_tipo">
                                            <Form.Label> <h4 className="ml-4 mt-2">Lista de peças</h4></Form.Label>
                                                <Container className="justify-content-sm-center">
                                                <Row className="ml-2"><Col xs={12} md={7}><Form.Label><h4>Nome</h4></Form.Label></Col><Col xs={12} md={3}><Form.Label><h4>Quantidade</h4></Form.Label></Col></Row>
                                                    {allProdutos.map((produtosSelected) => (
                                                        <Row className="ml-2">
                                                            <Col xs={7} md={7}>
                                                                <Form.Label className="form-control form-control-gb">{produtosSelected.Nome}</Form.Label>
                                                            </Col>
                                                            <Col xs={3} md={3}>
                                                                <Form.Control onChange={(e) => setQntPivot(e.target.value,produtosSelected)} placeholder={produtosSelected.pivot.Quantidade}/>
                                                            </Col>
                                                            <Row>
                                                                <Col xs={2} md={2}><img className="img_mini" src={Trash} onClick={() =>  removeTodoProdutos(produtosSelected)}/></Col>
                                                            </Row>

                                                        </Row>
                                                    ))}
                                                </Container>
                                            </Row> 
                                            <Row className="justify-content-md-center mt-2 item_tipo">
                                            <Form.Label> <h4 className="ml-4 mt-2">Lista de processos</h4></Form.Label>
                                                <Container className="justify-content-sm-center">
                                                    <Row className="ml-2"><Col xs={4} md={5}><Form.Label><h4>Nome</h4></Form.Label></Col><Col xs={4} md={4}><Form.Label><h4>Custo</h4></Form.Label></Col><Col xs={2} md={2}><img className="img_mini ml-5" src={Trash} onClick={()=> removeTodoAll()}/></Col></Row>
                                                    {allProcessos.map((selecteds) => (
                                                        <Row className="ml-2 ">
                                                                <Col xs={4} md={5}>
                                                                    <Form.Label className="form-control form-control-gb">{selecteds.Nome}</Form.Label>
                                                                </Col>
                                                                <Col xs={4} md={5}>
                                                                    
                                                                    <Form.Label className="form-control form-control-gb">{"R$ "+selecteds.Custo}</Form.Label>
                                                                </Col>
                                                                <Row>
                                                                    <Col xs={2} md={2}><img className="img_mini" src={Trash} onClick={() =>  removeTodo(selecteds)}/></Col>
                                                                </Row>
                                                        </Row>

                                                    ))}
                                                </Container>
                                            </Row>                                        
                                    </Card.Body>                                                                       
                                </Card>
                                <Row className="justify-content-center mt-3">
                                    <Button variant="warning" className="text-center" onClick={()=> handlerCreate()}>Produzir</Button>
                                </Row>
                            </Col>
                        </Row>
            </Container>
        );
    }

}else{
    return (
        <Row className="justify-content-center">
    <Spinner animation="border" className="central" role="status">
        <span className="sr-only">Loading...</span>
    </Spinner>
    </Row>
    );
}
}

