import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Card, Row , Col, Form, Modal, Accordion,Alert, Spinner} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';

import Plus from '../../images/add.svg';
import Trash from '../../images/trash.svg';
import Eye from '../../images/olho.svg';
import Check from '../../images/check.png';
import Delete from '../../images/close.svg';
import './styles.css';
import { Collapse } from '@material-ui/core';
import { toast, ToastContainer } from 'react-toastify';


export default function Processos(){
    const [loading, setLoading] = useState(true);
    const [insumos, setInsumos] = useState([]);
    const [tipos, setTipos] = useState([]);

    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    var [forId_Fornecedor, setForId_Fornecedor] = useState('');
    var [nome, setNome] = useState('');
    var [selectedValue, setSelectedValue] = useState('');
    const [inId_Insumo, setInId_Insumo] = useState();
    const [filtredInsumos, setFiltredInsumos] = useState([]);
    const [search, setSearch] = useState("");
    const [alert1, setAlert1] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [alert3, setAlert3] = useState(false);
    const [idDelete, setIdDelete] = useState('');

    const [alert4, setAlert4] = useState(false);

    const notify = (txt) => toast.success(txt, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });


    const history = useHistory();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    async function handlerDelete(id,index){
        document.getElementById('modalDel').style.width = '0px';
        document.getElementById('modalDel').style.display = 'none';
        api.post("api/deleteInsumo",{Id_Insumo: id}).then(function(response) {
            notify("Excluído com sucesso.");
            setInsumos(response.data);
        });
        
    }

    function handlerOpenDelete(id){
        setIdDelete(id);
        document.getElementById('modalDel').style.width = '17rem';
        document.getElementById('modalDel').style.display = 'inline-block';

    }

    function handlerCancelDelete(id){
        setIdDelete('');
        document.getElementById('modalDel').style.width = '0px';
        document.getElementById('modalDel').style.display = 'none';
    }

    async function handleCreate(e){
        const Id_Usuario = localStorage.getItem('Id_Usuario');
        console.log(selectedValue);

        if(! nome
             == ''){
            const data = {
                nome,
                selectedValue,
                Id_Usuario
            };
            try {
                api.post('api/createInsumo', data)
                .then(async res => {
                    handleClose();
                    notify("Criado com sucesso.");

                    setInsumos(res.data);
                });
            } catch (err) {
                notify("Verifique os campos.");

            }
        }else{
            notify("Verifique os campos.");

        }
    }

    async function handlerEdit(Insumo,index){
        var inId_Insumo = Insumo.Id_Insumo;
        var Id_Usuario = localStorage.getItem('Id_Usuario');
        if(nome === ''){
            nome = Insumo.Nome;
        }
        if(selectedValue === ''){
            selectedValue = Insumo.Id_Tipo.Id_Tipo;
        }
           var data = {
               Id_Usuario,
               inId_Insumo,
               nome,
               selectedValue
           };
                api.post('api/editInsumo', data)
               .then(function(response){
                setInsumos(response.data);
                notify("Editado com sucesso.");
            });
        }   
        useEffect(() => {
            setFiltredInsumos(
              insumos.filter((tipo) =>
              tipo.Nome.toLowerCase().includes(search.toLowerCase())
              )
            );
          }, [search, insumos]);

        useEffect(() => {
         
                api.post("api/getInsumos")
                .then(function(response) {
                    setInsumos(response.data);
                
                    api.post("api/getTipos").then(function(response2){
                    setTipos(response2.data);
                    setSelectedValue(response2.data[0].Id_Tipo);
                    setLoading(false);

                });
                });
          
            }, []);

            function reset(){
                api.post("api/getInsumos")
                .then(function(response) {
                    setInsumos(response.data);
                    });
            }


        console.log(insumos);

            if(loading == false){
    return (
       
        <Container>
            <h1 className="il">Q</h1>
            <ToastContainer autoClose={4000} />
            <div id="modalDel" className="confirmarModal">
                    <h3>Confirmar Exclusão</h3>
                    <Row className="justify-content-md-center mt-4">
                        <img className="checks mr-2" src={Check} onClick={() => handlerDelete(idDelete)}/>
                        <img className="checks neg mr-2" src={Delete} onClick={() => handlerCancelDelete()}/>
                    </Row>
            </div>
            <Link to="/dashboard">
                <Button className="ir">
                        Voltar
                </Button>
            </Link>
            {alert1  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert1(false)}>Excluído com sucesso.</Alert>}
            {alert2  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert2(false)}>Criado com sucesso.</Alert>}
            {alert4  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert4(false)}>Atualizado com sucesso.</Alert>}
            <Row className="justify-content-md-center">
                <Col xs={12} md={8}>
                    
                        <Row>
                            <Col xs={12} md={6}><h2 className="ml-2">Insumos</h2></Col> 
                            <h2>Filtro:</h2><Col className="mb-2" xs={12} md={4}><Form.Control onChange={(e) => setSearch(e.target.value)}></Form.Control></Col>
                        
                        </Row>

                    <Card className="card_items">
                        <Card.Body>
                        <Accordion defaultActiveKey="0">
                            {filtredInsumos.map((insumo,index)=> 
                                <Row className="item_tipo">
                                    <Col xl={12} md={12}>
                                        <Accordion.Toggle as={Card.Header} className="mt-2" variant="link" eventKey={insumo.Id_Insumo} onClick={() => reset()}> <Row><Col  xs={10} md={11}>{insumo.Nome + ' - ' + insumo.tipo.Nome} </Col>
                                        {(insumo.Usado == 1)? '' :
                                        
                                        <Col xs={2} md={1}><div className="mg-top"><button className="ml-2" onClick={() => handlerOpenDelete(insumo.Id_Insumo,index)}><img className="img" src={Trash}></img></button></div></Col>
                                        }
                                        </Row></Accordion.Toggle>
                                        
                                        <Accordion.Collapse eventKey={insumo.Id_Insumo}>
                                            <Col xs={12} md={12}>
                                                <Row className="mt-2">
                                                    <Col  xs={5} md={8}>
                                                        <Form.Label>Insumo</Form.Label>
                                                        <Form.Control maxLength="50" size="md" type="text" placeholder={insumo.Nome} onChange={e => setNome(e.target.value)} />
                                                    </Col>

                                                    <Col  xs={5} md={4} className="mb-3">
                                                        <Form.Label>Tipo</Form.Label>
                                                        <select className="form-control" value={insumo.Id_Tipo} onChange={(e) => (setSelectedValue(e.target.value),(insumo.Id_Tipo = e.target.value))}>
                                      
                                                            {tipos.map(tipo =>
                                                                <option value={tipo.Id_Tipo}>{tipo.Nome}</option>
                                                            )}
                                                        </select>
                                                    </Col>
                                                    {(insumo.Usado == 1)? '' :
                                                    <Button className=" ml-3 mb-2" variant="primary" onClick={(e) => handlerEdit(insumo,index)} >
                                                    Confirmar
                                                    </Button>
                                                    }
                                                </Row>
                                                
                                            </Col>
                                        </Accordion.Collapse>

                                    </Col>
                                </Row>
                            )}
                            </Accordion>
                            <Col>
                                <div className="item_tipo text-center">
                                   <p>Adicionar <button className="btn btn-link" onClick={handleShow}> <img src={Plus} className="img"/></button></p>
                                        <Modal show={show} onHide={handleClose}>
                                        {alert3  && <Alert variant="danger" size="sm" className="mt-2" dismissible onClose={() =>setAlert3(false)}>Dados incompletos.</Alert>}
                                            <Modal.Header closeButton>
                                            <Modal.Title>Criando Insumo</Modal.Title>
                                            </Modal.Header>
                                            <Row className="justify-content-md-center mb-2 ml-2 mr-2 mt-2">
                                                <Col>
                                                    <Form.Control maxLength="50" size="md" type="text" placeholder="Digite o nome" onChange={e => setNome(e.target.value)} />
                                                </Col>
                                                <Col>
                                                <select className="form-control" value={selectedValue} onChange={e => setSelectedValue(e.target.value)}>
                                                    {tipos.map(tipo =>
                                                        <option value={tipo.Id_Tipo}>{tipo.Nome}</option>
                                                    )}
                                                </select>
                                                </Col>
                                            </Row>
                                            <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Fechar
                                            </Button>
                                            <Button variant="primary" onClick={handleCreate}>
                                                Adicionar
                                            </Button>
                                            </Modal.Footer>
                                    </Modal>
                                </div>
                            </Col>
                        </Card.Body>
                        
                    </Card>
                </Col>
            </Row>
        </Container>
    );
    }else{
        return (
            <Row className="justify-content-center">
        <Spinner animation="border" className="central" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
        </Row>
        );
    }
}