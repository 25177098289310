import api from '../../services/api';
import React, { useEffect, useState } from 'react';
import { Button, Form , Col, Row, Container, Card, Spinner,Alert} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import ReactInputMask from 'react-input-mask'; 

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default  function PrimeiroAcesso(){
        //console.log(localStorage.getItem('token'));
        const [loading, setLoading] = useState(true);
        const [email, setEmail] = useState('');
        const [nome, setNome] = useState('');
        const [senha, setSenha] = useState('');
        const [senhacc, setSenhaCC] = useState('');
        const [password, setPassword] = useState('');
        const [alert1, setAlert1] = useState(false);
        const [alert2, setAlert2] = useState(false);
        const [alert3, setAlert3] = useState(false);
        const [screen, setScreen] = useState(false);

        const notify = () => toast.success("Senha salva!", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });

            const error = (txt) => toast.error(txt, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });

        async function verCodigo(email,password,flag){
            const Id_Usuario = localStorage.getItem('Id_Usuario');

            api.post('api/auth/login', {email, password }).then(function(response){
                console.log(flag);
                var data = {
                    Id_Usuario,
                    email,
                    nome,
                    senha,
                    senhacc,
                    password
                }
                if(flag == true){
                    api.post('api/changePassword', data).then(function(response){
                        notify();
                        localStorage.setItem('SenhaAlterada',1);
                        history.push('/dashboard');
                    });
                }

            }).catch((e) => {
                error("Código incorreto");
                document.getElementById('chave-acesso').style.borderColor = 'red';
                return false;
            });
        }

         async function handlerCreate(){
            var flag = true;
            setAlert1(false);
            setAlert2(false);
            setAlert3(false);
            document.getElementById('senha').style.borderColor = '';
            document.getElementById('senhacc').style.borderColor = '';
            document.getElementById('chave-acesso').style.borderColor = '';
            console.log(senha);
            if(senha == ''){
                flag = false;
                document.getElementById('senha').style.borderColor = 'red';
                error("Digite uma senha");

            }

            if(senhacc == ''){
                flag = false;
                document.getElementById('senhacc').style.borderColor = 'red';
                error("Confirme a senha");

            }
            if(flag == true){
                if(senha != senhacc){
                    //setAlert2(true);
                    error("Senhas Não Conferem");
                    document.getElementById('senha').style.borderColor = 'red';
                    document.getElementById('senhacc').style.borderColor = 'red';
                    flag = false;
                }

                if(senha.length < 6){
                    //setAlert2(true);
                    error("A senha deve ser superior a 6 caracteres");
                    document.getElementById('senha').style.borderColor = 'red';
                    flag = false;
                }



                var flag2 = await verCodigo(email,password,flag);
            }
        }


        const history = useHistory();

        useEffect(() => {
                setEmail(localStorage.getItem('Email'));
                setNome(localStorage.getItem('Nome'));
                setLoading(false);

        }, []);

if(loading == false){
        return (
            !loading &&
            
            <Container>
                <h1 className="il">Q</h1>
                <Link to="/quansquie/">
                    <Button disabled className="ir">
                            Voltar
                    </Button>
                </Link>
                {alert1  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert1(false)}>Preencha todos os campos corretamente.</Alert>}
                {alert2  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert2(false)}>Senhas não conferem.</Alert>}
                {alert3  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert3(false)}>Código incorreto.</Alert>}

                <Row className="justify-content-md-center mt-5 mb-1">
                            <Col xs={12} md={7}>
                            <h2 className="ml-2">Meu Primeiro Acesso</h2>
                            <p className="ml-2">Informe os dados de acesso para redefinir sua senha pessoal</p>
                                <Card className="card_items  mb-1">
                                    <Card.Body>
                                       <Row className="justify-content-md-center">
                                           <Col xs={12} md={6} className="mt-1">
                                                <Form.Label>Nome</Form.Label>
                                                <Form.Control value={nome} disabled />
                                            </Col>
                                            <Col xs={12}  md={6} className="mt-1">
                                                <Form.Label>E-mail</Form.Label>
                                                <Form.Control  value={email} disabled />
                                            </Col>
                                       </Row>
                                       <Row className="justify-content-md-center mt-3">
                                           <Col xs={12}  md={4}>
                                                <Form.Label className="mt-1">Chave de acesso</Form.Label>
                                                <Form.Control  type="password" id="chave-acesso" onChange={e => setPassword(e.target.value)} />

                                                
                                            </Col>
                                            <Col xs={12}  md={4}>
                                                <Form.Label className="mt-1">Nova Senha</Form.Label>
                                                <Form.Control type="password" id="senha"   onChange={e => setSenha(e.target.value)}/>
                                            </Col>
                                            <Col xs={12}  md={4}>
                                                <Form.Label className="mt-1">Confirmar Senha</Form.Label>
                                                <Form.Control type="password"  id="senhacc" onChange={e => setSenhaCC(e.target.value)}  />
                                            </Col>
                                       </Row>
                                            
                                      
                                    </Card.Body>
                                   
                                    
                                </Card>
                                <Row className="justify-content-center mb-2 mt-3">
                                    <Button variant="warning" className="text-center" onClick={()=> handlerCreate()}>Salva Senha</Button>
                                    <ToastContainer autoClose={4000} />
                                </Row>
                            </Col>
                        </Row>
            </Container>
        );
    

    }else{
        return (
            <Row className="justify-content-center">
        <Spinner animation="border" className="central" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
        </Row>
        );
    }
}

