import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Card, Row , Col, Form, Modal, Accordion, Spinner, ListGroup, Alert} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import api from '../../../services/api';

import Plus from '../../../images/add.svg';
import Trash from '../../../images/trash.svg';
import Eye from '../../../images/olho.svg';
import Delete from '../../../images/close.svg';
import { toast, ToastContainer } from 'react-toastify';

import Check from '../../../images/check.png';
import { Collapse } from '@material-ui/core';

export default function Material(){
    const [loading, setLoading] = useState(true);
    const [produtoResultado, setProdutoResultado] = useState([]);
    const [alert1, setAlert1] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [idDelete, setIdDelete] = useState('');

    const notify = (txt) => toast.success(txt, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        
    const [filtred, setFiltred] = useState([]);
    const [search, setSearch] = useState("");   

    const history = useHistory();
    var [custoTotal, setCustoTotal] = useState('');

    async function handlerDelete(id){
        document.getElementById('modalDel').style.width = '0px';
        document.getElementById('modalDel').style.display = 'none';
        var Cod_ProdutoResultado = filtred[id].Cod_ProdutoResultado;
        var data = {
            Cod_ProdutoResultado,
        };
        console.log(data);
        api.post("api/deleteProdutoResultado",data).then(function(response){
            setProdutoResultado(response.data);
            notify("Excluído com sucesso.");
        });
    }

    function handlerOpenDelete(id){
        setIdDelete(id);
        document.getElementById('modalDel').style.width = '17rem';
        document.getElementById('modalDel').style.display = 'inline-block';

    }

    function handlerCancelDelete(id){
        setIdDelete('');
        document.getElementById('modalDel').style.width = '0px';
        document.getElementById('modalDel').style.display = 'none';
    }

    useEffect(() => {
        api.post("api/getProdutoResultado")
        .then(function(response) {
            setProdutoResultado(response.data);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setFiltred(
          produtoResultado.filter((prod) =>
          prod.Nome.toLowerCase().includes(search.toLowerCase())
          )
        );
      }, [search, produtoResultado]);
      console.log(produtoResultado);


    /*produtos_n_acabados.forEach(element => {
        element['CustoTotal'] = 0;
        element.processo.forEach(pcusto=> {
            element['CustoTotal'] += pcusto.Custo;
        });
        element['CustoTotal'] += element.produto_inacabado.Custo;
    });*/

if(loading == false){
    return (
       
        <Container>
            <h1 className="il">Q</h1>
            <Link to="/quansquie/">
                <Button className="ir">
                        Voltar
                </Button>
            </Link>
            
            <Row className="justify-content-md-center">
            {alert1  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert1(false)}>Excluído com sucesso.</Alert>}
            {alert2  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert2(false)}>Produzido com sucesso.</Alert>}
            <ToastContainer autoClose={4000} />
            <div id="modalDel" className="confirmarModal">
                    <h3>Confirmar Exclusão</h3>
                    <Row className="justify-content-md-center mt-4">
                        <img className="checks mr-2" src={Check} onClick={() => handlerDelete(idDelete)}/>
                        <img className="checks neg mr-2" src={Delete} onClick={() => handlerCancelDelete()}/>
                    </Row>
            </div>
                <Col xs={12} md={10}>
                <Row>
                        <Col xs={12} md={6}><h2 className="ml-2">Produtos</h2></Col> 
                        <h2>Filtro:</h2><Col className="mb-2" xs={12} md={4}><Form.Control value={search} onChange={(e) => setSearch(e.target.value)}></Form.Control></Col>
                    </Row>
                    <Card className="card_items">
                        <Card.Body>
                            <Row><Col md={4}><h2>Nome</h2></Col></Row>
                        <Accordion defaultActiveKey="0">
                            {filtred.map((resultados,indexM)=> 
                                <Row className="item_tipo  mt-2 mb-2">
                                    <Col xs={12} md={12}>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey={resultados}> <Row><Col xs={5} md={10}><Form.Label>{resultados.Nome+" - "+resultados.tipo_produto[0].Nome+" "+resultados.tipo_produto[0].Tamanho}</Form.Label></Col><Col xs={2} md={1}>
                                        <div className="mg-top">
                                            <button className="ml-2" onClick={() => handlerOpenDelete(indexM)}><img className="img" src={Trash}></img></button>
                                        </div>
                                    </Col>
                              </Row></Accordion.Toggle>
                                        <Accordion.Collapse eventKey={resultados}>
                                            <Col>
                                                <Row className="justify-content-md-center mt-2">
                                                    <Col>
                                                        <Form.Label>Nome</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={resultados.Nome} disabled/>
                                                    </Col>
                                                </Row>
                                               
                                                <Row className="justify-content-md-center mt-2 mb-2">
                                                    <Col>
                                                        <Form.Label>Quantidade</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={resultados.quantidade_produto_resultado} disabled/>
                                                    </Col>
                                                </Row>
                                                <Row className="justify-content-md-center mt-2 mb-2">
                                            </Row>
                                            <Row className="justify-content-md-center  mt-4 item_tipo">
                                                <Form.Label> <h4 className="ml-4 mt-2">Peças</h4></Form.Label>
                                                <Container className="justify-content-sm-center">
                                                    <Row className="ml-2"><Col md={5} xs={5}><Form.Label><h4>Nome</h4></Form.Label></Col><Col  md={5} xs={5}><Form.Label><h4>Descrição</h4></Form.Label></Col></Row>
                                                    {resultados.produto_acabado.map((selected,index) => (
                                                        <Row className="ml-2 mr-2">
                                                            <Col xs={12} md={12}>
                                                                <Form.Label className="form-control form-control-gb">{selected.Nome}</Form.Label>
                                                            </Col>

                                                        </Row>
                                                    ))}
                                                </Container>
                                            </Row>  
                                            <Row className="justify-content-md-cente mt-4 item_tipo">
                                                <Form.Label> <h4 className="ml-4 mt-2">Processos</h4></Form.Label>
                                                <Container className="justify-content-sm-center">
                                                    <Row className="ml-2"><Col md={5} xs={5}><Form.Label><h4>Nome</h4></Form.Label></Col><Col md={5} xs={5}><Form.Label><h4>Custo</h4></Form.Label></Col></Row>
                                                    {resultados.processo.map((selected,index) => (
                                                        <Row className="ml-2 mr-2">
                                                            <Col>
                                                                <Form.Label className="form-control form-control-gb">{selected.Nome}</Form.Label>
                                                            </Col>
                                                            <Col>
                                                                <Form.Label className="form-control form-control-gb">{"R$ "+selected.Custo}</Form.Label>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </Container>
                                            </Row>  
                                            </Col>
                                        </Accordion.Collapse>
                                    </Col>

                                </Row>
                            )}
                            </Accordion>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
    }else{
        return (
            <Row className="justify-content-center">
        <Spinner animation="border" className="central" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
        </Row>
        );
    }
}