import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import axios from 'axios';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import api from '../../services/api';
import InputMask from "react-input-mask";

import './styles.css';
import { Button, Col, Container, Form, Row ,Alert} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';


export default function Register() {
  const [firstPhase, setFirstPhase] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [alert0, setAlert0] = useState(false);
  const [alert1, setAlert1] = useState(false);
  const [alert2, setAlert2] = useState(false);
  const [alert3, setAlert3] = useState(false);
  const [alert4, setAlert4] = useState(false);
  const [alert5, setAlert5] = useState(false);
  const [alert6, setAlert6] = useState(false);
  const [mask, setMask] = useState("");

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  var [rs, setRS] = useState('');
  var [ie, setIE] = useState('');
  var [im, setIM] = useState('');
  const [cep, setCEP] = useState('');
  var [uf, setUF] = useState('');
  var [num, setNum] = useState('');
  var [telefone1, setTelefone1] = useState('');
  var [telefone2, setTelefone2] = useState('');
  var [bairro, setBairro] = useState('');
  var [cidade, setCidade] = useState('');
  var [rua, setRua] = useState('');
  var [complemento, setComplemento] = useState('');
  var [cnpj, setCNPJ] = useState('');
  var [success, setSuccess] = useState('');
  

  const notify = (txt) => toast.success(txt, {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });

    const erro = (txt) => toast.error(txt, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

  const history = useHistory();

  function verifica_cpf_cnpj ( valor ) {

    // Garante que o valor é uma string
    valor = valor.toString();
    
    // Remove caracteres inválidos do valor
    valor = valor.replace(/[^0-9]/g, '');

    // Verifica CPF
    if ( valor.length === 11 ) {
        return 'CPF';
    } 
    
    // Verifica CNPJ
    else if ( valor.length === 14 ) {
        return 'CNPJ';
    } 
    
    // Não retorna nada
    else {
        return false;
    }
    
}
function calc_digitos_posicoes( digitos, posicoes = 10, soma_digitos = 0 ) {

    // Garante que o valor é uma string
    digitos = digitos.toString();

    // Faz a soma dos dígitos com a posição
    // Ex. para 10 posições:
    //   0    2    5    4    6    2    8    8   4
    // x10   x9   x8   x7   x6   x5   x4   x3  x2
    //   0 + 18 + 40 + 28 + 36 + 10 + 32 + 24 + 8 = 196
    for ( var i = 0; i < digitos.length; i++  ) {
        // Preenche a soma com o dígito vezes a posição
        soma_digitos = soma_digitos + ( digitos[i] * posicoes );

        // Subtrai 1 da posição
        posicoes--;

        // Parte específica para CNPJ
        // Ex.: 5-4-3-2-9-8-7-6-5-4-3-2
        if ( posicoes < 2 ) {
            // Retorno a posição para 9
            posicoes = 9;
        }
    }

    // Captura o resto da divisão entre soma_digitos dividido por 11
    // Ex.: 196 % 11 = 9
    soma_digitos = soma_digitos % 11;

    // Verifica se soma_digitos é menor que 2
    if ( soma_digitos < 2 ) {
        // soma_digitos agora será zero
        soma_digitos = 0;
    } else {
        // Se for maior que 2, o resultado é 11 menos soma_digitos
        // Ex.: 11 - 9 = 2
        // Nosso dígito procurado é 2
        soma_digitos = 11 - soma_digitos;
    }

    // Concatena mais um dígito aos primeiro nove dígitos
    // Ex.: 025462884 + 2 = 0254628842
    var cpf = digitos + soma_digitos;

    // Retorna
    return cpf;
    
} 
function valida_cpf( valor ) {

  if(valor == '11111111111') return false;
  if(valor == '22222222222') return false;
  if(valor == '33333333333') return false;
  if(valor == '44444444444') return false;
  if(valor == '55555555555') return false;
  if(valor == '66666666666') return false;
  if(valor == '77777777777') return false;
  if(valor == '88888888888') return false;
  if(valor == '99999999999') return false;


  // Garante que o valor é uma string
  valor = valor.toString();
  
  // Remove caracteres inválidos do valor
  valor = valor.replace(/[^0-9]/g, '');


  // Captura os 9 primeiros dígitos do CPF
  // Ex.: 02546288423 = 025462884
  var digitos = valor.substr(0, 9);

  // Faz o cálculo dos 9 primeiros dígitos do CPF para obter o primeiro dígito
  var novo_cpf = calc_digitos_posicoes( digitos );

  // Faz o cálculo dos 10 dígitos do CPF para obter o último dígito
  var novo_cpf = calc_digitos_posicoes( novo_cpf, 11 );

  // Verifica se o novo CPF gerado é idêntico ao CPF enviado
  if ( novo_cpf === valor ) {
      // CPF válido
      return true;
  } else {
      // CPF inválido
      return false;
  }
  
} 
function valida_cnpj ( valor ) {

    // Garante que o valor é uma string
    valor = valor.toString();
    
    // Remove caracteres inválidos do valor
    valor = valor.replace(/[^0-9]/g, '');

    
    // O valor original
    var cnpj_original = valor;

    // Captura os primeiros 12 números do CNPJ
    var primeiros_numeros_cnpj = valor.substr( 0, 12 );

    // Faz o primeiro cálculo
    var primeiro_calculo = calc_digitos_posicoes( primeiros_numeros_cnpj, 5 );

    // O segundo cálculo é a mesma coisa do primeiro, porém, começa na posição 6
    var segundo_calculo = calc_digitos_posicoes( primeiro_calculo, 6 );

    // Concatena o segundo dígito ao CNPJ
    var cnpj = segundo_calculo;

    // Verifica se o CNPJ gerado é idêntico ao enviado
    if ( cnpj === cnpj_original ) {
        return true;
    }
    
    // Retorna falso por padrão
    return false;
    
} 
function valida_cpf_cnpj ( valor ) {

    // Verifica se é CPF ou CNPJ
    var valida = verifica_cpf_cnpj( valor );

    // Garante que o valor é uma string
    valor = valor.toString();
    
    // Remove caracteres inválidos do valor
    valor = valor.replace(/[^0-9]/g, '');


    // Valida CPF
    if ( valida === 'CPF' ) {
        // Retorna true para cpf válido
        return valida_cpf( valor );
    } 
    
    // Valida CNPJ
    else if ( valida === 'CNPJ' ) {
        // Retorna true para CNPJ válido
        return valida_cnpj( valor );
    } 
    
    // Não retorna nada
    else {
        return false;
    }
    
} 
function formata_cpf_cnpj( valor ) {

    // O valor formatado
    var formatado = false;
    
    // Verifica se é CPF ou CNPJ
    var valida = verifica_cpf_cnpj( valor );

    // Garante que o valor é uma string
    valor = valor.toString();
    
    // Remove caracteres inválidos do valor
    valor = valor.replace(/[^0-9]/g, '');


    // Valida CPF
    if ( valida === 'CPF' ) {
    
        // Verifica se o CPF é válido
        if ( valida_cpf( valor ) ) {
        
            // Formata o CPF ###.###.###-##
            formatado  = valor.substr( 0, 3 ) + '.';
            formatado += valor.substr( 3, 3 ) + '.';
            formatado += valor.substr( 6, 3 ) + '-';
            formatado += valor.substr( 9, 2 ) + '';
            
        }
        
    }
    
    // Valida CNPJ
    else if ( valida === 'CNPJ' ) {
    
        // Verifica se o CNPJ é válido
        if ( valida_cnpj( valor ) ) {
        
            // Formata o CNPJ ##.###.###/####-##
            formatado  = valor.substr( 0,  2 ) + '.';
            formatado += valor.substr( 2,  3 ) + '.';
            formatado += valor.substr( 5,  3 ) + '/';
            formatado += valor.substr( 8,  4 ) + '-';
            formatado += valor.substr( 12, 14 ) + '';
            
        }
    } 
    // Retorna o valor 
    return formatado;
}


  async function handlerFirst(){
    if(email !== ''){
      if(password !== '' && password.length >=6){
        if(confirmPassword === password){
            api.post('api/findEmail', { email }).then(function(response) {
              console.log(response.data);
              if(response.data.cod == '0'){
                setFirstPhase(true);

              }else{
                erro("Email encontrado no sistema.");
              }

            });

        }else{
          erro("Senhas não conferem.");

        }
      }else{
        erro("Preencha a Senha corretamente.");

      }
    }else{
      erro("Forneça um Email.");

    }
  }

  async function handleCEP(){
    console.log(cep);
    let one = "http://viacep.com.br/ws/"+cep+"/json";
    axios.get(one).then(response =>{
      //console.log(response.data);
      setSuccess('true');
      setRua(response.data.logradouro);
      setCidade(response.data.localidade);
      setBairro(response.data.bairro);
      setUF(response.data.uf);
    }).catch(function (error){
      //console.log(error.message);
      //setRua('');
      setCidade('');
      //setBairro('');
      setUF('');
      setSuccess('');
    });

  }

  async function handleRegister(e) {
    setAlert0(false);
    setAlert1(false);
    setAlert2(false);
    setAlert3(false);
    setAlert4(false);
    setAlert5(false);
    setAlert6(false);
    document.getElementById("CPFCNPJ").style.borderColor = "";
    e.preventDefault();
    cnpj =  cnpj.replace('/','');
    cnpj =  cnpj.replace('.','');
    cnpj =  cnpj.replace('.','');
    cnpj =  cnpj.replace('-','');
    console.log(valida_cpf_cnpj(cnpj)+cnpj);
    if(valida_cpf_cnpj(cnpj) != false){
      if((name != null && name !='')&&(cnpj !== null && cnpj != '')&&(telefone1 !== null && telefone1.search('_') == -1 && telefone1 != '')){
        cnpj =  cnpj.replace('/','');
        cnpj =  cnpj.replace('.','');
        cnpj =  cnpj.replace('-','');
        const data = {
          name,
          email,
          password,
          rs,
          ie,
          im,
          cep,
          uf,
          num,
          telefone1,
          telefone2,
          bairro,
          cidade,
          rua,
          complemento,
          cnpj
        };
        console.log(cnpj);
        if(success == "true"){
          api.post('api/findUser',data).then(function(response) {
            console.log(response.data.OK);
            if(response.data.OK == 'true'){
              try {
                  api.post('api/register', data)
                  .then( () => {
                        api.post('api/auth/login', { email, password }).then(function(response) {
                          localStorage.setItem('Id_Usuario',response.data[1].Id_Usuario);
                          localStorage.setItem('Nome',response.data[1].Nome);
                          localStorage.setItem('UserJaLogado',response.data[1].Id_Usuario);
                          localStorage.setItem('token', response.data.access_token);
                          history.push('/dashboard');
                      });
                  });
              } catch (err) {
                erro("Email já registrado no sistema.");
              }
            }else{
              erro("Email já registrado no sistema.");
            }
          });
        }else{
          erro("Forneça um CEP válido.");
        }
      }else{
        erro("Por favor, forneça os campos assinalados com (*)");
      }
    }else{
      //voltaraq
      erro("CPF/CNPJ inválido.");
      document.getElementById("CPFCNPJ").style.borderColor = "red";
    }
  }

  
  
if(firstPhase === true){
  return (

    <Container fluid >
    <ToastContainer autoClose={4000} />

      <Row className="justify-content-md-center marginTop">
        <Col md={3}>
            <h1>Faça já o seu cadastro</h1>
            <h3>A <strong>Quans</strong> lhe permite realizar o controle total das finanças de sua empresa</h3>
            <Link to='/'><h5 className="marginTop">Voltar</h5></Link>

        </Col>
        <Col md={{ span: 4, offset: 2 }} className="">
          {alert3  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert3(false)}>Por favor, forneça os campos assinalados com (*)</Alert>}
          {alert4  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert4(false)}>Forneça um CEP válido</Alert>}
          {alert5  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert5(false)}>Email já registrado no sistema</Alert>}
          {alert6  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert6(false)}>CPF/CNPJ inválido.</Alert>}

          <Form className="jBorder">
            <Container>
            <Row>
              <Col xs={12} md={8}>
                  <Form.Label>Nome*</Form.Label>
                  <Form.Control maxLength="100" type="text" onChange={e => setName(e.target.value)}/>
              </Col>
              <Col xs={12} md={4}>
                   <Form.Label>CPF/CNPJ*</Form.Label>
                    <CpfCnpj
                        id="CPFCNPJ"
                        type="text"
                        value={cnpj}
                        className="form-control"
                          onChange={(event, type) => {
                            setCNPJ(event.target.value);
                            setMask(type === "CNPJ");
                          }}
                        />
              </Col>
              </Row>

              <Row>
              <Col xs={12} md={12}>
                  <Form.Label>Razão Social</Form.Label>
                  <Form.Control maxLength="200" type="text" onChange={e => setRS(e.target.value)}/>
              </Col>

              </Row>
              <Row>
                
              <Col xs={12} md={6}>
              <Form.Label>Telefone 1*</Form.Label>
              <InputMask className="form-control" id="Telefone1" mask="99 99999-9999"  onChange={e => setTelefone1(e.target.value)} />
              </Col>

              <Col xs={12} md={6}>
              <Form.Label>Telefone 2</Form.Label>
              <InputMask className="form-control" id="Telefone2" mask="99 99999-9999"  onChange={e => setTelefone2(e.target.value)}/>
              </Col>

              </Row>
              <Row>
                
                <Col xs={12} md={6}>
                <Form.Label>Inscrição Estadual</Form.Label>
                <Form.Control maxLength="20" type="text"  pattern="[0-9]*" onChange={e => setIE(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                </Col>
  
                <Col xs={12} md={6}>
                <Form.Label>Inscrição Municipal</Form.Label>
                <Form.Control maxLength="20" type="text" pattern="[0-9]*" onChange={e => setIM(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                </Col>
  
                </Row>

                <Row>
                
                <Col xs={12} md={6}>
                <Form.Label>CEP*</Form.Label>
                <Form.Control maxLength="8" onChange={(e) => setCEP(e.target.value)} onBlur={()=> handleCEP()} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                </Col>
  
                <Col xs={12} md={6}>
                <Form.Label>Cidade</Form.Label>
                <Form.Control  value={cidade} disabled />               
                </Col>
  
                </Row>

                <Row>
                  <Col xs={12} md={9}>
                    <Form.Label>Rua</Form.Label>
                    <Form.Control maxLength="50" value={rua} className="col col-md-12" onChange={e => setRua(e.target.value)}/> 
                  </Col>
                  <Col xs={12} md={3}>
                    <Form.Label>Número</Form.Label>
                    <Form.Control maxLength="5" onChange={e => setNum(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                  </Col>
                </Row>              
            
                <Row>
                
                <Col xs={12} md={6}>
                <Form.Label>Estado</Form.Label>
                <Form.Control maxLength="2" value={uf} disabled/>
                </Col>
  
                <Col xs={12} md={6}>
                <Form.Label>Bairro</Form.Label>
                <Form.Control maxLength="50" value={bairro} onChange={e => setBairro(e.target.value)} />
                </Col>
                </Row>                        
                <Row>

                <Col xs={12} md={12}>
                <Form.Label>Complemento</Form.Label>
                <Form.Control maxLength="10" onChange={(e) => setComplemento(e.target.value)}/>
                </Col>
  
                </Row>
          
                <Form.Row className="ml-3 mb-3"><Button variant="secondary" className="mr-4" onClick={() => setFirstPhase(false)}>Voltar</Button><Button onClick={handleRegister}>Cadastrar</Button> <h5 className="ml-3 mt-1" >Itens marcados em (*) são obrigatórios</h5></Form.Row>
      
              </Container>         
       
              </Form>
        </Col>
       </Row>
   </Container>
    );
  }else{
  return (
  <Container fluid >
    <ToastContainer autoClose={4000} />

    <Row className="justify-content-md-center marginTop">
      <Col md={4}>
        <h1>Faça já o seu cadastro</h1>
        <h3>A <strong>Quans</strong> lhe permite realizar o controle total das finanças de sua empresa</h3>
        <Link to='/'><h5 className="marginTop">Voltar</h5></Link>

      </Col>
      <Col md={{ span: 3, offset: 1 }}>
        <Col className="justify-content-md-center ">
                  {alert0  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert0(false)}>Forneça um Email</Alert>}
                  <Form.Group  controlId="Nome">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control maxLength="100" type="email" onChange={e => setEmail(e.target.value)}/>
                    </Form.Group>
                    {alert1  && <Alert variant="danger" dismissible onClose={() =>setAlert1(false)}>Preencha a Senha corretamente</Alert>}
                    <Form.Group  controlId="formGridCNPJ">
                        <Form.Label>Senha</Form.Label>
                        <Form.Control maxLength="100" type="password" onChange={e => setPassword(e.target.value)}/>
                        <small id="emailHelp" class="form-text text-muted">Senha deve possuir 6 ou mais caracteres.</small>

                    </Form.Group>
                    {alert2  && <Alert variant="danger" dismissible onClose={() => setAlert2(false)}>Senhas não conferem</Alert>}
                    <Form.Group  controlId="formGridTelefone1">
                      <Form.Label>Confirme sua senha</Form.Label>
                        <Form.Control maxLength="100" type="password"  onChange={e => setConfirmPassword(e.target.value)}/>
                    </Form.Group>
                <Row className="justify-content-md-center"><Button onClick={handlerFirst}>Cadastrar</Button></Row>
            </Col>
        </Col>
     </Row>

 </Container>
  );
}
  
}