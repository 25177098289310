import api from '../../../services/api';
import React, { useEffect, useState } from 'react';
import { Button, Form , Col, Row, Container, Card, Spinner,Alert} from 'react-bootstrap';
import './styles.css';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import Material from '../../Material';
import { toast, ToastContainer } from 'react-toastify';
import NumberFormat from 'react-number-format';


export default  function Entrada(){
        //console.log(localStorage.getItem('token'));
        const [loading, setLoading] = useState(true);
        const [material, setMaterial] = useState('');
        const [fornecedores, setFornecedores] = useState('');
        const [selected, setSelected] = useState('');
        const [Chave_Acesso, setChave_Acesso] = useState('');
        const [Valor_Total, setValor_Total] = useState(0);
        const [Data_Entrada, setData_Entrada] = useState('');
        const [Preco, setPreco] = useState('');
        const [Quantidade, setQuantidade] = useState('');
        const [CodProduto, setCodProduto] = useState('');
        const [Maximo, setMaximo] = useState('');
        const [Id_Fornecedor, setId_Fornecedor] = useState('');
        const [alert1, setAlert1] = useState(false);
        const [alert2, setAlert2] = useState(false);
        const [alert3, setAlert3] = useState(false);
        const [alert4, setAlert4] = useState(false);
        const [screen, setScreen] = useState(false);



        async function handlerCreate(){
            const Id_Usuario = localStorage.getItem('Id_Usuario');
                if(Valor_Total != ''){
                    document.getElementById("DATA").style.borderColor = "";
                            document.getElementById("FORNECEDOR").style.borderColor = "";
                            document.getElementById("QUANTIDADE").style.borderColor = "";
                            document.getElementById("VALOR").style.borderColor = "";
                    if(Data_Entrada != ''){
                        document.getElementById("DATA").style.borderColor = "";
                            document.getElementById("FORNECEDOR").style.borderColor = "";
                            document.getElementById("QUANTIDADE").style.borderColor = "";
                            document.getElementById("VALOR").style.borderColor = "";
                        if(Id_Fornecedor != '' && Id_Fornecedor != -1){
                            document.getElementById("DATA").style.borderColor = "";
                            document.getElementById("FORNECEDOR").style.borderColor = "";
                            document.getElementById("QUANTIDADE").style.borderColor = "";
                            document.getElementById("VALOR").style.borderColor = "";
                            
                            var Data_Saida = Data_Entrada;
                            setCodProduto(material[selected].CodProduto);
                            setPreco(Preco*1.00);
                            var data ={
                                Id_Usuario,Chave_Acesso, Valor_Total, Data_Saida, Preco, Quantidade, CodProduto,Id_Fornecedor
                            }
                            console.log(data);
                            api.post('api/saidaMateria', data)
                            .then(async res => {
                                localStorage.setItem('Saida',1);
                                history.push('/');
                                history.replace('/quansquie/material');

                            }).catch(function() {alert("Erro na saída.");});
                        }else{
                            document.getElementById("FORNECEDOR").style.borderColor = "red";
                            erro("Preencha os campos corretamente");
                            
                        }
                    }else{
                        erro("Preencha os campos corretamente");
                        document.getElementById("DATA").style.borderColor = "red";
                    }
                }else{
                    document.getElementById("QUANTIDADE").style.borderColor = "red";
                    document.getElementById("VALOR").style.borderColor = "red";
                    erro("Preencha os campos corretamente");
                }
        }

        function handlePreco(e){
            e = e.replace(',','.');
            setQuantidade(Quantidade.replace(",","."));
            setPreco(Preco.replace(",","."));
            setPreco(e);
            setValor_Total(Quantidade*Preco);
        }

        function handleQuantidade(e){
            e = e.replace(',','.');
            setQuantidade(Quantidade.replace(",","."));
            setPreco(Preco.replace(",","."));

            var qtd = e;
            var minimo = material[selected].Estoque_Minimo/1;

            console.log(qtd + Quantidade +">"+minimo+Preco);

                if(qtd > Maximo/1){
                    erro("Valor superior ao máximo permitido.");
                    setQuantidade(0);
                    document.getElementById("BOTAOSAIDA").disabled = true;
                }else{
                    document.getElementById("BOTAOSAIDA").disabled = false;
                }
            
            
           

            setValor_Total(Quantidade.replace(',','.')*Preco.replace(',','.'));
        }

        async function handlerTela(e){
            if(e != -1){
                setCodProduto(material[e].CodProduto);
                setSelected(e);
                if(material[e].Estoque_Minimo == 0 || material[e].Estoque_Minimo == null){
                    erro("Favor cadastrar estoque mínimo");
                }else{
                    if(material[e].Estoque_Total - material[e].Estoque_Minimo <= 0){
                        erro("Saída máxima igual ou inferior a zero. Favor verificar estoque mínimo e atual");
                    }else{
                        setQuantidade(0);
                        setScreen(true);
                        setMaximo(material[e].Estoque_Total - material[e].Estoque_Minimo);
                    }

                    
                }
            }else{
                setScreen(false);
            }
        }

        const notify = (txt) => toast.success(txt, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    
            
        const erro = (txt) => toast.error(txt, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        const history = useHistory();

        useEffect(() => {
            api.post("api/getMateria")
            .then(function(response) {
                setMaterial(response.data);
                
                api.post("api/allFornecedor")
                .then(function(response) {
                    setFornecedores(response.data);
                    setLoading(false);
                });
            });

        }, []);

if(loading == false){
    if(screen == false){
        return (
            
            
            <Container>
            <ToastContainer autoClose={4000} />

                
                <h1 className="il">Q</h1>
                <Link to="/quansquie/">
                    <Button className="ir">
                            Voltar
                    </Button>
                </Link>
                <Row className="justify-content-md-center mt-5 mb-1">
                            <Col md={6}>
                            <h2>Saída</h2>
                            {alert1  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert1(false)}>Favor cadastrar Estoque Mínimo.</Alert>}

                                <Card className="card_items mt-5 mb-5">
                                    <Card.Body>
                                        <h2>Selecione a Matéria-Prima</h2>
                                        <select className="form-control form-control-md" onChange={(e) => handlerTela(e.target.value)}>
                                            <option value="-1">Selecione...</option>
                                            {material.map((materiaPrima, index = 0) =>
                                                <option value={index}>{materiaPrima.Nome +' - '+materiaPrima.insumos.Nome}</option>
                                            )}
                                        </select>
                                        
                                    </Card.Body>
                                    
                                </Card>
                            </Col>
                        </Row>
            </Container>
        );
    }else{
        return (
            !loading &&
            
            <Container>
                <h1 className="il">Q</h1>
                <Link to="/quansquie/">
                    <Button className="ir">
                            Voltar
                    </Button>
                </Link>
            <ToastContainer autoClose={4000} />

                <Row className="justify-content-md-center mt-5 mb-1">
                            <Col md={7}>
                            <h2>Saída</h2>
                            {alert3  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert3(false)}>Não é possível registrar saída menor que o Estoque Mínimo!</Alert>}
                            {alert2  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert2(false)}>Preencha os campos corretamente!</Alert>}
                            {alert4  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert4(false)}>Valor foi superior ao máximo permitido!</Alert>}
                                <Card className="card_items  mb-1">
                                    <Card.Body>
                                       <Row className="justify-content-md-center">
                                            <Col md={12} className="mt-1">
                                                <Form.Label>Matéria-Prima</Form.Label>
                                                <Form.Control value={material[selected].Nome + " - " + material[selected].insumos.Nome } disabled />
                                            </Col>
                                       </Row>
                                       <Row className="justify-content-md-center mt-3">
                                           <Col md={6}>
                                                <Form.Label className="mt-1">Fornecedores</Form.Label>
                                                <select className="form-control form-control-md mt-1" id="FORNECEDOR" onChange={(e) => setId_Fornecedor(e.target.value)}>
                                                    <option value={-1}>Selecione...</option>
                                                    {fornecedores.map((fornecedor, index) =>
                                                        <option value={index}>{fornecedor.Nome}</option>
                                                    )}
                                                </select>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Label className="mt-1">Data de Saída</Form.Label>
                                                <Form.Control className="mt-1" id="DATA" type="date" placeholder="Digite a data" onChange={(e) => setData_Entrada(e.target.value)}/>
                                            </Col>
                                      
                                           <Col md={6} className="mt-1">
                                                <Form.Label >Preço</Form.Label>
                                                

                                                <NumberFormat allowNegative={false} maxLength="11"  decimalScale={2} fixedDecimalScale={true}  decimalSeparator={','}  className="form-control form-control-md" inputMode="numeric"    id="VALOR" onChange={e => handlePreco(e.target.value)} onKeyDown={e => handlePreco(e.target.value)} onKeyUp={e => handlePreco(e.target.value)} />    
                                            
                                            </Col>

                                                    <Col md={3} className="mt-1">
                                                        <Form.Label >Quantidade</Form.Label>
                                                        

                                                        <NumberFormat allowNegative={false} maxLength="11"  decimalScale={2} fixedDecimalScale={true}  decimalSeparator={','}  className="form-control form-control-md" inputMode="numeric"   onChange={(e) => setQuantidade(e.target.value)} id="QUANTIDADE" onBlur={e => handleQuantidade(Quantidade)}  />
                                                        <Form.Text id="passwordHelpBlock" muted>
                                                                Max:{Maximo} 
                                                        </Form.Text>
                                                        </Col>
                                                         
                                                    <Col md={3} className="mt-1">  <Form.Label >Total</Form.Label>
                                                    <NumberFormat as={Form.Label}  prefix={'R$'} allowNegative={false} allowLeadingZeros={false} decimalSeparator={','} isNumericString={false} decimalScale={2} fixedDecimalScale={true} value={Valor_Total} className="form-control form-control-md" inputmode="decimal" readOnly={true} />
                                                </Col> 
                                            
                                       </Row>
                                      
                                    </Card.Body>
                                   
                                    
                                </Card>
                                <Row className="justify-content-center mb-2 mt-3">
                                    <Button variant="warning" id="BOTAOSAIDA" className="text-center" onClick={()=> handlerCreate()}>Salvar Saída</Button>
                                </Row>
                            </Col>
                        </Row>
            </Container>
        );
    }

}else{
    return (
        <Row className="justify-content-center">
    <Spinner animation="border" className="central" role="status">
        <span className="sr-only">Loading...</span>
    </Spinner>
    </Row>
    );
}
}

