import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Card, Row , Col, Form, Modal, Accordion, Spinner, ListGroup, Alert} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import api from '../../../services/api';

import Plus from '../../../images/add.svg';
import Trash from '../../../images/trash.svg';
import Eye from '../../../images/olho.svg';
import Delete from '../../../images/close.svg';
import Check from '../../../images/check.png';

import './styles.css';
import { Collapse } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { toast, ToastContainer } from 'react-toastify';


export default function Material(){
    const [loading, setLoading] = useState(true);
    var [produtos_n_acabados, setProdutos_n_acabados] = useState([]);
    const [idDelete, setIdDelete] = useState('');
    const [alert1, setAlert1] = useState(false);
    const [alert2, setAlert2] = useState(false);

    const [filtred, setFiltred] = useState([]);
    const [search, setSearch] = useState("");   

    const history = useHistory();
    var [custoTotal, setCustoTotal] = useState('');

    const notify = (txt) => toast.success(txt, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

    async function handlerDelete(id){
        var Id_ProdutoFinal = id;
        var data = {
            Id_ProdutoFinal,
        };
        api.post("api/deleteProdutos_Acabados",data).then(function(response){
            document.getElementById('modalDel').style.width = '0px';
            document.getElementById('modalDel').style.display = 'none';
            var respostas = (response.data);
            respostas.forEach(element => {
                element.CustoTotal = 0;
                element.processo.forEach(pcusto=> {
                    element.CustoTotal += (pcusto.Custo/1);
                });
                element.CustoTotal += parseInt(element.produto_inacabado.Custo/1);
            });
            setProdutos_n_acabados(respostas);
            notify("Excluido com sucesso.");
            
        });
    }


    useEffect(() => {
        api.post("api/getProdutos_Acabados")
        .then(function(response) {
            var respostas = (response.data);
            respostas.forEach(element => {
                element.CustoTotal = 0;
                element.processo.forEach(pcusto=> {
                    element.CustoTotal += (pcusto.Custo/1);
                });
                element.CustoTotal += parseInt(element.produto_inacabado.Custo/1);

                element.CustoTotal = parseFloat(element.CustoTotal).toFixed(2).toString().replace('.',',');

                element.processo.forEach(fcusto =>{
                    fcusto.Custo = parseFloat(fcusto.Custo).toFixed(2).toString().replace('.',',');
                })
            });
            setProdutos_n_acabados(respostas);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setFiltred(
          produtos_n_acabados.filter((prod) =>
          prod.Nome.toLowerCase().includes(search.toLowerCase())
          )
        );
      }, [search, produtos_n_acabados]);

    console.log(produtos_n_acabados);

    function handlerOpenDelete(id){
        setIdDelete(id);
        document.getElementById('modalDel').style.width = '17rem';
        document.getElementById('modalDel').style.display = 'inline-block';

    }

    function handlerCancelDelete(id){
        setIdDelete('');
        document.getElementById('modalDel').style.width = '0px';
        document.getElementById('modalDel').style.display = 'none';
    }

    /*produtos_n_acabados.forEach(element => {
        element['CustoTotal'] = 0;
        element.processo.forEach(pcusto=> {
            element['CustoTotal'] += pcusto.Custo;
        });
        element['CustoTotal'] += element.produto_inacabado.Custo;
    });*/

if(loading == false){
    return (
       
        <Container>
            <h1 className="il">Q</h1>
            <Link to="/quansquie/">
                <Button className="ir">
                        Voltar
                </Button>
            </Link>
            <ToastContainer autoClose={4000} />
            <div id="modalDel" className="confirmarModal">
                    <h3>Confirmar Exclusão</h3>
                    <Row className="justify-content-md-center mt-4">
                        <img className="checks mr-2" src={Check} onClick={() => handlerDelete(idDelete)}/>
                        <img className="checks neg mr-2" src={Delete} onClick={() => handlerCancelDelete()}/>
                    </Row>
            </div>
            
            <Row className="justify-content-md-center">
            {alert1  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert1(false)}>Excluído com sucesso.</Alert>}
            {alert2  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert2(false)}>Produzido com sucesso.</Alert>}
            
                <Col xs={12} md={10}>
                <Row>
                        <Col xs={12} md={6}><h2 className="ml-2">Peças</h2></Col> 
                        <h2>Filtro:</h2><Col className="mb-2" xs={12} md={4}><Form.Control value={search} onChange={(e) => setSearch(e.target.value)}></Form.Control></Col>
                    </Row>
                    <Card className="card_items">
                        <Card.Body>
                            <Row><Col md={4}><h2>Nome</h2></Col></Row>
                        <Accordion defaultActiveKey="0">
                            {filtred.map((produto_n_acabado,indexM)=> 
                                <Row className="item_tipo  mt-2 mb-2">
                                    <Col xs={12} md={12}>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey={produto_n_acabado.Id_Produto_n_acabado}> <Row><Col xs={5} md={6}><Form.Label>{produto_n_acabado.Nome}</Form.Label></Col><Col xs={5} md={5}><Form.Label>{"R$ "+produto_n_acabado.CustoTotal}</Form.Label></Col>
                            
                                <Col xs={2} md={1}>
                                        {(produto_n_acabado.Usado == 1)? '' :
                                        <div className="mg-top">
                                            <button className="ml-2" onClick={() => handlerOpenDelete(produto_n_acabado.Id_ProdutoFinal)}><img className="img" src={Trash}></img></button>
                                        </div>
                                        }
                                    </Col>
                              </Row></Accordion.Toggle>
                                        <Accordion.Collapse eventKey={produto_n_acabado.Id_Produto_n_acabado}>
                                            <Col>
                                                <Row className="justify-content-md-center mt-2">
                                                    <Col>
                                                        <Form.Label>Nome</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={produto_n_acabado.Nome} disabled/>
                                                    </Col>
                                                </Row>
                                               
                                                <Row className="justify-content-md-center mt-2 mb-2">
                                                    <Col>
                                                        <Form.Label>Quantidade</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={produto_n_acabado.quantidade_produto_acabado} disabled/>
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>Custo total</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={"R$ "+produto_n_acabado.CustoTotal} disabled/>
                                                    </Col>
                                                </Row>
                                                <Row className="justify-content-md-center mt-2 mb-2">
                                            </Row>
                                            <Row className="justify-content-md-center  mt-4 item_tipo">
                                                <Form.Label> <h4 className="ml-4 mt-2">Matérias-Primas</h4></Form.Label>
                                                <Container className="justify-content-sm-center">
                                                    <Row className=""><Col md={6} xs={6}><Form.Label><h4 className="ml-4"> Nome</h4></Form.Label></Col><Col  md={6} xs={6}><Form.Label><h4>Descrição</h4></Form.Label></Col></Row>
                                                    {produto_n_acabado.materia_prima.map((selected,index) => (
                                                        <Row className="ml-2 mr-2">
                                                            <Col>
                                                                <Form.Label className="form-control form-control-gb">{selected.Nome + " - "+ selected.insumos.Nome}</Form.Label>
                                                            </Col>
                                                            <Col >
                                                                <Form.Label className="form-control form-control-gb">{selected.unidades.Descricao}</Form.Label>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </Container>
                                            </Row>  
                                            <Row className="justify-content-md-center mt-4 item_tipo">
                                                <Form.Label> <h4 className="ml-4 mt-2">Processos</h4></Form.Label>
                                                <Container className="justify-content-sm-center">
                                                    <Row className=""><Col md={6} xs={6} ><Form.Label><h4 className="ml-4"> Nome</h4></Form.Label></Col><Col md={6} xs={6}><Form.Label><h4>Custo</h4></Form.Label></Col></Row>
                                                    {produto_n_acabado.processo.map((selected,index) => (
                                                        <Row className="ml-2 mr-2">
                                                            <Col>
                                                                <Form.Label className="form-control form-control-gb">{selected.Nome}</Form.Label>
                                                            </Col>
                                                            <Col>
                                                                <Form.Label className="form-control form-control-gb">{"R$ "+selected.Custo}</Form.Label>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </Container>
                                            </Row>  
                                            </Col>
                                        </Accordion.Collapse>
                                    </Col>

                                </Row>
                            )}
                            </Accordion>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
    }else{
        return (
            <Row className="justify-content-center">
        <Spinner animation="border" className="central" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
        </Row>
        );
    }
}