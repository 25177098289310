import api from '../../services/api';
import React, { useEffect, useState } from 'react';
import { Button, Form , Col, Row, Container, Image,Alert, Modal} from 'react-bootstrap';
import ImageUploader from 'react-images-upload';
import { Link, useHistory } from 'react-router-dom';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import axios from 'axios';
import Settings from '../../images/settings.svg';
import Home from '../../images/casa.svg';
import Delete from '../../images/delete.svg';
import Lista from '../../images/lista.svg';
import Renda from '../../images/renda.svg';
import QR from '../../images/qr-code.svg';
import Insumo from '../../images/Insumo.svg'
import Materia from '../../images/materia-prima.svg';
import Moedas from '../../images/moedas.svg';
import Fornecedor from '../../images/fornecedor.svg';
import Product from '../../images/box.svg';
import Gear from '../../images/gear.svg';
import { toast, ToastContainer } from 'react-toastify';

import ReactInputMask from 'react-input-mask';


export default  function EditUser(){
        //console.log(localStorage.getItem('token'));
        const [loading, setLoading] = useState(true);
        const [empresa, setEmpresa] = useState({});
        var [Razao, setRazao] = useState('');
        var [RazaoCurto, setRazaoCurto] = useState('');
        var [Bairro, setBairro] = useState('');
        var [UF, setUF] = useState('');
        var [CEP, setCEP] = useState('');
        var [Cidade, setCidade] = useState('');
        var [Telefone, setTelefone] = useState('');
        var [Complemento, setComplemento] = useState('');
        var [Numero, setNumero] = useState('');
        var [Rua, setRua] = useState('');
        var [Email, setEmail] = useState('');
        var [CNPJ, setCNPJ] = useState('');
        var [Logo, setLogo] = useState('');
        var [IE, setIE] = useState('');
        var [IM, setIM] = useState('');
        var [success, setSuccess] = useState('');
        const [empresa1, setEmpresa1] = useState('');
        const [show, setShow] = useState(false);
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
        const [senhaConfirma, setSenhaConfirma] = useState('');
        const [senhaConfirma2, setSenhaConfirma2] = useState('');

        const [mask, setMask] = useState("");
        const [logoEmpresa, setLogoEmpresa] = useState('');
        const [empresa2, setEmpresa2] = useState('');
        const [empresa3, setEmpresa3] = useState('');
        const [empresa4, setEmpresa4] = useState('');
        const [empresa5, setEmpresa5] = useState('');
        const [alert4,setAlert4] = useState(false);
        const [alert1,setAlert1] = useState(false);
        const [alert3,setAlert3] = useState(false);
        const [alert6,setAlert6] = useState(false);

        const history = useHistory();


        const notify = (txt) => toast.success(txt, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        
        
            const erro = (txt) => toast.error(txt, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        

        function verifica_cpf_cnpj ( valor ) {

            // Garante que o valor é uma string
            valor = valor.toString();
            
            // Remove caracteres inválidos do valor
            valor = valor.replace(/[^0-9]/g, '');
        
            // Verifica CPF
            if ( valor.length === 11 ) {
                return 'CPF';
            } 
            
            // Verifica CNPJ
            else if ( valor.length === 14 ) {
                return 'CNPJ';
            } 
            
            // Não retorna nada
            else {
                return false;
            }
            
        }
        function calc_digitos_posicoes( digitos, posicoes = 10, soma_digitos = 0 ) {
        
            // Garante que o valor é uma string
            digitos = digitos.toString();
        
            // Faz a soma dos dígitos com a posição
            // Ex. para 10 posições:
            //   0    2    5    4    6    2    8    8   4
            // x10   x9   x8   x7   x6   x5   x4   x3  x2
            //   0 + 18 + 40 + 28 + 36 + 10 + 32 + 24 + 8 = 196
            for ( var i = 0; i < digitos.length; i++  ) {
                // Preenche a soma com o dígito vezes a posição
                soma_digitos = soma_digitos + ( digitos[i] * posicoes );
        
                // Subtrai 1 da posição
                posicoes--;
        
                // Parte específica para CNPJ
                // Ex.: 5-4-3-2-9-8-7-6-5-4-3-2
                if ( posicoes < 2 ) {
                    // Retorno a posição para 9
                    posicoes = 9;
                }
            }
        
            // Captura o resto da divisão entre soma_digitos dividido por 11
            // Ex.: 196 % 11 = 9
            soma_digitos = soma_digitos % 11;
        
            // Verifica se soma_digitos é menor que 2
            if ( soma_digitos < 2 ) {
                // soma_digitos agora será zero
                soma_digitos = 0;
            } else {
                // Se for maior que 2, o resultado é 11 menos soma_digitos
                // Ex.: 11 - 9 = 2
                // Nosso dígito procurado é 2
                soma_digitos = 11 - soma_digitos;
            }
        
            // Concatena mais um dígito aos primeiro nove dígitos
            // Ex.: 025462884 + 2 = 0254628842
            var cpf = digitos + soma_digitos;
        
            // Retorna
            return cpf;
            
        } 
        function valida_cpf( valor ) {
        
            // Garante que o valor é uma string
            valor = valor.toString();
            
            // Remove caracteres inválidos do valor
            valor = valor.replace(/[^0-9]/g, '');
        
        
            // Captura os 9 primeiros dígitos do CPF
            // Ex.: 02546288423 = 025462884
            var digitos = valor.substr(0, 9);
        
            // Faz o cálculo dos 9 primeiros dígitos do CPF para obter o primeiro dígito
            var novo_cpf = calc_digitos_posicoes( digitos );
        
            // Faz o cálculo dos 10 dígitos do CPF para obter o último dígito
            var novo_cpf = calc_digitos_posicoes( novo_cpf, 11 );
        
            // Verifica se o novo CPF gerado é idêntico ao CPF enviado
            if ( novo_cpf === valor ) {
                // CPF válido
                return true;
            } else {
                // CPF inválido
                return false;
            }
            
        } 
        function valida_cnpj ( valor ) {
        
            // Garante que o valor é uma string
            valor = valor.toString();
            
            // Remove caracteres inválidos do valor
            valor = valor.replace(/[^0-9]/g, '');
        
            
            // O valor original
            var cnpj_original = valor;
        
            // Captura os primeiros 12 números do CNPJ
            var primeiros_numeros_cnpj = valor.substr( 0, 12 );
        
            // Faz o primeiro cálculo
            var primeiro_calculo = calc_digitos_posicoes( primeiros_numeros_cnpj, 5 );
        
            // O segundo cálculo é a mesma coisa do primeiro, porém, começa na posição 6
            var segundo_calculo = calc_digitos_posicoes( primeiro_calculo, 6 );
        
            // Concatena o segundo dígito ao CNPJ
            var cnpj = segundo_calculo;
        
            // Verifica se o CNPJ gerado é idêntico ao enviado
            if ( cnpj === cnpj_original ) {
                return true;
            }
            
            // Retorna falso por padrão
            return false;
            
        } 
        function valida_cpf_cnpj ( valor ) {
        
            // Verifica se é CPF ou CNPJ
            var valida = verifica_cpf_cnpj( valor );
        
            // Garante que o valor é uma string
            valor = valor.toString();
            
            // Remove caracteres inválidos do valor
            valor = valor.replace(/[^0-9]/g, '');
        
        
            // Valida CPF
            if ( valida === 'CPF' ) {
                // Retorna true para cpf válido
                return valida_cpf( valor );
            } 
            
            // Valida CNPJ
            else if ( valida === 'CNPJ' ) {
                // Retorna true para CNPJ válido
                return valida_cnpj( valor );
            } 
            
            // Não retorna nada
            else {
                return false;
            }
            
        } 
        function formata_cpf_cnpj( valor ) {
        
            // O valor formatado
            var formatado = false;
            
            // Verifica se é CPF ou CNPJ
            var valida = verifica_cpf_cnpj( valor );
        
            // Garante que o valor é uma string
            valor = valor.toString();
            
            // Remove caracteres inválidos do valor
            valor = valor.replace(/[^0-9]/g, '');
        
        
            // Valida CPF
            if ( valida === 'CPF' ) {
            
                // Verifica se o CPF é válido
                if ( valida_cpf( valor ) ) {
                
                    // Formata o CPF ###.###.###-##
                    formatado  = valor.substr( 0, 3 ) + '.';
                    formatado += valor.substr( 3, 3 ) + '.';
                    formatado += valor.substr( 6, 3 ) + '-';
                    formatado += valor.substr( 9, 2 ) + '';
                    
                }
                
            }
            
            // Valida CNPJ
            else if ( valida === 'CNPJ' ) {
            
                // Verifica se o CNPJ é válido
                if ( valida_cnpj( valor ) ) {
                
                    // Formata o CNPJ ##.###.###/####-##
                    formatado  = valor.substr( 0,  2 ) + '.';
                    formatado += valor.substr( 2,  3 ) + '.';
                    formatado += valor.substr( 5,  3 ) + '/';
                    formatado += valor.substr( 8,  4 ) + '-';
                    formatado += valor.substr( 12, 14 ) + '';
                    
                }
            } 
            // Retorna o valor 
            return formatado;
        }
        
        async function handleCEP(){
            let one = "http://viacep.com.br/ws/"+CEP+"/json";
            axios.get(one).then(response =>{
              //console.log(response.data);
              setSuccess('true');
              setRua(response.data.logradouro);
              setComplemento(response.data.complemento);
              setCidade(response.data.localidade);
              setBairro(response.data.bairro);
              setUF(response.data.uf);
            }).catch(function (error){
                setRua('');
                setComplemento('');
                setCidade('');
                setBairro('');
                setUF('');
            });
        
          }

        useEffect(() => {
                api.post("api/getEmpresa")
                .then(function(response) {
                    setCNPJ(formata_cpf_cnpj(response.data.CNPJ));
                    setUF(response.data.UF);
                    setEmpresa(response.data);
                    setCidade(response.data.Cidade);
                    setBairro(response.data.Bairro);
                    setRua(response.data.Rua);
                    setCEP(response.data.CEP);
                    setComplemento(response.data.Complemento);
                    setNumero(response.data.Numero);
                    setLogoEmpresa(<Image src={"http://api.quans.projetoscomputacao.com.br/myfiles/avatars/"+response.data.Logo} className="logo"></Image>);

                    if(localStorage.getItem("Editar")== 1){
                        setAlert4(true);
                    }
                    localStorage.setItem("Editar",0);
                    setEmpresa1(<button className="img_bot"> <Link to="/dashboard" ><Image  src={Home}/></Link></button>);
                    if(response.data.hasQuansquie == 's'){
                        setEmpresa2(<button className="img_bot"> <Link to="/Quansquie" ><Image src={Moedas} /></Link></button>);
                    }else{
                        setEmpresa2(<button className="img_bot filter" disabled><Image src={Moedas} /></button>);
                    }
            
                    if(response.data.hasQuansquisobra == 's'){
                        //setEmpresa3(<button className="img_bot"><Image src={Delete} /></button>);
                    }else{
                        //setEmpresa3(<button className="img_bot filter" disabled><Image src={Delete} /></button>);
                    }
            
                    if(response.data.hasQuansquirende == 's'){
                        setEmpresa4(<button className="img_bot"><Link to='/quansquirende'><Image src={Renda} /></Link></button>);
                    }else{
                        setEmpresa4(<button className="img_bot filter" disabled><Image src={Renda} /></button>);
                    }
            
                    if(response.data.hasQROV == 's'){
                        setEmpresa5(<button className="img_bot" ><Image src={QR} /></button>);
                        setLoading(false);
                        
                    }else{
                        setEmpresa5(<button className="img_bot filter" disabled><Image src={QR} /></button>);
                        setLoading(false); 
                    }
                });
                
            }, []);

                               
            //console.log(empresa);
        
    async function handlerEdit(){

        if(CEP != ''){
            success = 'true';
        }

        if(Razao == ''){
            Razao = empresa.Razaosocial;
        }

        if(RazaoCurto == ''){
            RazaoCurto = empresa.Razaosocialcurto;
        }

         if(CNPJ == ''){
             CNPJ = empresa.CNPJ;
         }

        if(IE == ''){
            IE = empresa.IE;
        }
        if(IM == ''){
            IM = empresa.IM;
        }

        if(Complemento == ''){
            Complemento = empresa.Complemento;
        }
        if(Numero == ''){
            Numero = empresa.Numero;
        }

        if(Telefone == ''){
            Telefone = empresa.Telefone;
        }


        if (Email == '') {
            Email = empresa.Email
        }

        if(success === "true"){
            
            var data ={
             
                    Email,
                    Razao,
                    RazaoCurto,
                    CEP,
                    CNPJ,
                    Cidade,
                     Complemento,
                     IE,
                     Numero,
                     Bairro,
                     Rua,
                     Telefone,
                     UF,
                     IM
                }
                
                var sucesso = verificaCampos(data);

                var cpfcnpj = data.CNPJ;

                cpfcnpj = cpfcnpj.replace('/','');
                cpfcnpj = cpfcnpj.replace('.','');
                cpfcnpj = cpfcnpj.replace('.','');
        
                cpfcnpj = cpfcnpj.replace('-','');
        
                data.CNPJ = cpfcnpj;

                if(sucesso == true){
                    document.getElementById("Telefone").style.borderColor = "";
                    document.getElementById("CPFCNPJ").style.borderColor = "";


                    const data1 = new FormData();
                    data1.append('logo',Logo);
                    data1.append('id',1);
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
                    //console.log(data1);
                    api.post("api/editEmpresa",data).then(function(response){
                        console.log(response.data);
                        setEmpresa(response.data);
                        

                        if(Logo != ''){
                            
                            var validaExt = Logo.name.split(".");
                            console.log(validaExt);
                                if(validaExt[1] == 'png' || validaExt[1] == 'jpg' || validaExt[1] == 'svg'){

                                
                                    api.post("api/uploadLogo",data1,config).then(function(response){
                                        setLogoEmpresa(<Image src="" className="logo"></Image>);
                                        console.log(response.data);
                                        setLogoEmpresa(<Image src={"http://api.quans.projetoscomputacao.com.br/myfiles/"+response.data} className="logo"></Image>);
                    
                                    });
                                }else{
                                    erro("Imagem deve ser .JPEG, .SVG ou .PNG");
                                    
                                    return false;
                                }

                        }
                        localStorage.setItem("Editar",1);
                        history.push("/dashboard");
                        });
                }else{
                    erro("Há campos inválidos.");
                }

        }else{
            erro("Digite um CEP válido.");
        }

    }


    function verificaCampos(data){
        console.log(valida_cnpj(data.CNPJ)+data.CNPJ);
        var flag = true;
        if(data.Telefone.search('_') != -1){
            document.getElementById("Telefone").style.borderColor = "red";
            flag = false;
        }else{
            document.getElementById("Telefone").style.borderColor = "";
        }

        if(data.Email.search('@') == -1){
            document.getElementById("Email").style.borderColor = "red";
            flag = false;
        }else{
            document.getElementById("Email").style.borderColor = "";
        }


        if(!(data.CNPJ.length == 18) || valida_cnpj(data.CNPJ) == false){
            document.getElementById("CPFCNPJ").style.borderColor = "red";
            flag = false;
        }else{
            document.getElementById("CPFCNPJ").style.borderColor = "";
        }

        return flag;
    }

    
    async function handlerBack(){
        history.push('/dashboard');
    }

    async function onDrop(e) {
        setLogo(e);
    }


    return (
        !loading &&
        
        <Container>
            <ToastContainer autoClose={4000} />

            <Row className="justify-content-md-center mt-5 mb-5">
                <Col xs={3} lg={4} className=""><h1 className="title">Q</h1></Col>
                
                <Col xs={6} lg={3}> <Row className="justify-content-md-center"><Col xs={12} lg={12} className="text-center"></Col><Col xs={12} lg={12} className="text-center"><h1 className="">{empresa.Razaosocialcurto}</h1></Col></Row>
                </Col>
                <Col xs={3} lg={4} className="text-center">
                <Link to="/dashboard">
                <Button className="ir">
                        Voltar
                </Button>
            </Link>
                </Col>
            </Row>
            
            {alert1  && <Alert variant="danger" className="mt-2" size="sm" dismissible onClose={() =>setAlert1(false)}>Há campos inválidos.</Alert>}
            
            {alert6  && <Alert variant="danger" className="mt-2" size="sm" dismissible onClose={() =>setAlert6(false)}>Imagem deve ser .JPEG, .SVG ou .PNG .</Alert>}
            
            {alert3  && <Alert variant="danger" className="mt-2" size="sm" dismissible onClose={() =>setAlert3(false)}>Digite um CEP válido.</Alert>}
                <Row className="justify-content-center ">
                    <h1 className="mt-2">Atualizar Empresa</h1>
                </Row>
            <Col xs={12} md={12} className=" justify-content-md-center mr-5">
            <Form className="mt-2 mr-3 ml-3 ">
                <Form.Row>
                    <Col md={6} xs={12}>
                        <Form.Label size="md">Email*</Form.Label>
                        <Form.Control size="md" id="Email" type="email" placeholder={empresa.Email} onChange={e => setEmail(e.target.value)} />
                    </Col>
                    <Col md={6} xs={12}>
                        <Form.Label size="md">Sigla*</Form.Label>
                        <Form.Control size="md" type="text" placeholder={empresa.Razaosocialcurto} onChange={e => setRazaoCurto(e.target.value)} />
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col md={6} xs={12}>
                        <Form.Label size="md">Telefone*</Form.Label>
                        <ReactInputMask className="form-control" id="Telefone" mask="99 99999-9999"  onChange={e => setTelefone(e.target.value)} placeholder={empresa.Telefone}/>
                    
                    </Col>
                    <Col md={6} xs={12}>
                        <Form.Label size="md">Razão Social*</Form.Label>
                        <Form.Control size="md" type="text" placeholder={empresa.Razaosocial} onChange={e => setRazao(e.target.value)}/>
                    </Col>
                    
                </Form.Row>
                <Form.Row>
                        <Col md={4} xs={12}>
                            <Form.Label size="md"> Inscrição Estadual*</Form.Label>
                            <Form.Control type="text" placeholder={empresa.IE} onChange={e => setIE(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                        </Col>

                        <Col md={4} xs={12}>
                            <Form.Label size="md"> Inscrição Municipal*</Form.Label>
                            <Form.Control type="text" placeholder={empresa.IM} onChange={e => setIM(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                        </Col>
                        
                        

                        <Col md={4} xs={12}>
                            <Form.Label>CNPJ*</Form.Label>
                            <CpfCnpj
                            id="CPFCNPJ"
                            type="text"
                            value={CNPJ}
                            className="form-control"
                            onChange={(event, type) => {
                            setCNPJ(event.target.value);
                            setMask(type === "CNPJ");
                          }}
                        />
                        </Col>
                </Form.Row>

                
                <Form.Row>

                </Form.Row>
                <Form.Row>
                    <Col md={4} xs={12}>
                        <Form.Label>CEP*</Form.Label>
                        <Form.Control placeholder={CEP} maxLength="8" onChange={(e) => setCEP(e.target.value)} onBlur={()=> handleCEP()} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                    </Col>

                    <Col md={4} xs={12}>
                    <Form.Label>Cidade</Form.Label>
                        <Form.Control value={Cidade} disabled/>
                        
                    </Col>

                    <Col md={4} xs={12}>
                    <Form.Label>Estado</Form.Label>
                        <Form.Control value={UF} disabled/>
                        
                    </Col>
                    <Col md={10} xs={8}>
                        <Form.Label>Rua</Form.Label>
                        <Form.Control placeholder={Rua}  onChange={e => setRua(e.target.value)}/>
                    </Col>
                    <Col md={2} xs={4}>
                        <Form.Label>Número</Form.Label>
                        <Form.Control  maxLength="5" placeholder={empresa.Numero} onChange={e => setNumero(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} />
                    </Col>    
                    
                    <Col md={4} xs={12}>
                        <Form.Label>Bairro</Form.Label>
                        <Form.Control placeholder={Bairro} onChange={e => setBairro(e.target.value)}/>
                    </Col>
                    <Col md={4} xs={12}>
                        <Form.Label>Complemento</Form.Label>
                        <Form.Control  maxLength="10" placeholder={Complemento} onChange={e => setComplemento(e.target.value)}/>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Label size="bg">Upload de logo</Form.Label>
                        <input type="file" name="file" style={{ height: 40+'px'}} onChange={(e)=> onDrop(e.target.files[0])} accept="image/*"></input>
                    </Col>
                </Form.Row>

                <Col xs={12} >
                    <Row>
                    <Button className="mb-2" variant="warning" onClick={() => handlerEdit()}>
                        Confirmar
                    </Button>
                    <h5 className="ml-3 mt-1" >Itens marcados em (*) são obrigatórios</h5>
                    </Row>
                </Col>
               
            </Form>
            </Col>
        </Container>
    );
}

