import api from '../../../services/api';
import React, { useEffect, useState } from 'react';
import { Button, Form , Col, Row, Container, Card, Spinner,Alert} from 'react-bootstrap';
import './styles.css';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import ReactInputMask from 'react-input-mask';
import { toast, ToastContainer } from 'react-toastify';
import NumberFormat from 'react-number-format';


export default  function Entrada(){
        //console.log(localStorage.getItem('token'));
        const [loading, setLoading] = useState(true);
        const [material, setMaterial] = useState('');
        const [fornecedores, setFornecedores] = useState('');
        const [selected, setSelected] = useState('');
        const [Chave_Acesso, setChave_Acesso] = useState('');
        const [Valor_Total, setValor_Total] = useState(0);
        const [alert1, setAlert1] = useState(false);
        const [Data_Entrada, setData_Entrada] = useState('');
        const [Preco, setPreco] = useState('');
        const [Quantidade, setQuantidade] = useState('');
        const [CodProduto, setCodProduto] = useState('');
        const [Id_Fornecedor, setId_Fornecedor] = useState('');
        const [screen, setScreen] = useState(false);

        const notify = (txt) => toast.success(txt, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    
            
        const erro = (txt) => toast.error(txt, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });

        async function handlerCreate(){
            
            const Id_Usuario = localStorage.getItem('Id_Usuario');
            if(Chave_Acesso != ''){
                document.getElementById("CHAVE").style.borderColor = "";
                if(Valor_Total != ''){
                    document.getElementById("QUANTIDADE").style.borderColor = "";
                    document.getElementById("VALOR").style.borderColor = "";
                    if(Id_Fornecedor != '' && Id_Fornecedor != -1){
                        document.getElementById("FORNECEDOR").style.borderColor = "";
                        if(Data_Entrada != ''){
                            document.getElementById("DATA").style.borderColor = "";
                            document.getElementById("FORNECEDOR").style.borderColor = "";
                            document.getElementById("QUANTIDADE").style.borderColor = "";
                            document.getElementById("VALOR").style.borderColor = "";
                            document.getElementById("CHAVE").style.borderColor = "";
                            setQuantidade(Quantidade.replace(",","."));
                            setPreco(Preco.replace(",","."));

                            setPreco(Preco*1.00);
                            var data ={
                                Id_Usuario,Chave_Acesso, Valor_Total, Data_Entrada, Preco, Quantidade, CodProduto,Id_Fornecedor
                            }
                            console.log(data);
                            api.post('api/entradaMateria', data).then(function(response) {
                                localStorage.setItem('Entrada',1);
                                history.push('/');
                                history.replace('/quansquie/material');
                                
                            });
                        }else{
                            erro("Preencha os campos corretamente.");
                            document.getElementById("DATA").style.borderColor = "red";
                        }
                    }else{
                        document.getElementById("FORNECEDOR").style.borderColor = "red";
                        erro("Preencha os campos corretamente.");
                    }
                }else{
                    document.getElementById("QUANTIDADE").style.borderColor = "red";
                    document.getElementById("VALOR").style.borderColor = "red";
                    erro("Preencha os campos corretamente.");
                }
            }else{
                document.getElementById("CHAVE").style.borderColor = "red";
                erro("Preencha os campos corretamente.");
            }

        }

        function MascaraMoeda(objTextBox, SeparadorMilesimo, SeparadorDecimal, e){  
            var sep = 0;  
            var key = ''; 
            var j = 0;
            var len2=0; 
            var i  = 0;  
            var len = len2 = 0;  
            var strCheck = '0123456789';  
            var aux = '';  
            var aux2 = '';
            var whichCode = (window.Event) ? e.which : e.keyCode;  
            if (whichCode == 13 || whichCode == 8) return true;  
            key = String.fromCharCode(whichCode); // Valor para o código da Chave  
            if (strCheck.indexOf(key) == -1) return false; // Chave inválida  
            len = objTextBox.value.length;  
            for(i = 0; i < len; i++)  
                if ((objTextBox.value.charAt(i) != '0') && (objTextBox.value.charAt(i) != SeparadorDecimal)) break;  
            aux = '';  
            for(; i < len; i++)  
                if (strCheck.indexOf(objTextBox.value.charAt(i))!=-1) aux += objTextBox.value.charAt(i);  
            aux += key;  
            len = aux.length;  
            if (len == 0) objTextBox.value = '';  
            if (len == 1) objTextBox.value = '0'+ SeparadorDecimal + '0' + aux;  
            if (len == 2) objTextBox.value = '0'+ SeparadorDecimal + aux;  
            if (len > 2) {  
                aux2 = '';  
                for (j = 0, i = len - 3; i >= 0; i--) {  
                    if (j == 3) {  
                        aux2 += SeparadorMilesimo;  
                        j = 0;  
                    }  
                    aux2 += aux.charAt(i);  
                    j++;  
                }  
                objTextBox.value = '';  
                len2 = aux2.length;  
                for (i = len2 - 1; i >= 0; i--)  
                objTextBox.value += aux2.charAt(i);  
                objTextBox.value += SeparadorDecimal + aux.substr(len - 2, len);  
            }  
            return false;  
        }  

        function handlePreco(e){
            e = e.replace(',','.');
            setPreco(e);
            setValor_Total(Quantidade*Preco);
        }

        function handleQuantidade(e){
            e = e.replace(',','.');
            setQuantidade(e);
            setValor_Total(Quantidade*Preco);
        }

        async function handlerTela(e){
            

            if(e != -1){
                setSelected(e);
            setCodProduto(material[e].CodProduto);
            setScreen(true);
            }else{
                setScreen(false);
            }
        }

        const history = useHistory();

        useEffect(() => {
            api.post("api/getMateria")
            .then(function(response) {
                setMaterial(response.data);
                api.post("api/allFornecedor")
                .then(function(response) {
                    setFornecedores(response.data);
                    setLoading(false);
                });
            });

        }, []);

if(loading == false){
    if(screen == false){
        return (
            
            
            <Container>
                <h1 className="il">Q</h1>
                <Link to="/quansquie/">
                    <Button className="ir">
                            Voltar
                    </Button>
                </Link>
            <ToastContainer autoClose={4000} />

                <Row className="justify-content-md-center mt-5 mb-1">
                            <Col md={6}>
                            <h2>Entrada</h2>
                                <Card className="card_items mt-5 mb-5">
                                    <Card.Body>
                                        <h2>Selecione a Matéria-Prima</h2>
                                        <select className="form-control form-control-md" onChange={(e) => handlerTela(e.target.value)}>
                                            <option value={-1}>Selecione...</option>
                                            {material.map((materiaPrima, index) =>
                                                <option value={index}>{materiaPrima.Nome +' - '+materiaPrima.insumos.Nome}</option>
                                            )}
                                        </select>
                                        
                                    </Card.Body>
                                    
                                </Card>
                            </Col>
                        </Row>
            </Container>
        );
    }else{
        return (
            !loading &&
            
            <Container>
                <h1 className="il">Q</h1>
                <Link to="/quansquie/">
                    <Button className="ir">
                            Voltar
                    </Button>
                </Link>
            <ToastContainer autoClose={4000} />

                {alert1  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert1(false)}>Preencha todos os campos corretamente.</Alert>}

                <Row className="justify-content-md-center mt-5 mb-1">
                            <Col xs={12} md={7}>
                            <h2 className="ml-2">Entrada</h2>
                                <Card className="card_items  mb-1">
                                    <Card.Body>
                                       <Row className="justify-content-md-center">
                                           <Col xs={12} md={6} className="mt-1">
                                                <Form.Label>Chave de Acesso NF-e</Form.Label>
                                                <Form.Control placeholder="Digite a chave de acesso" id="CHAVE" onChange={(e) => setChave_Acesso(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                                            </Col>
                                            <Col xs={12}  md={6} className="mt-1">
                                                <Form.Label>Matéria-Prima</Form.Label>
                                                <Form.Control value={material[selected].Nome + " - "+material[selected].insumos.Nome  } disabled />
                                            </Col>
                                       </Row>
                                       <Row className="justify-content-md-center mt-1">
                                           <Col xs={12}  md={6}>
                                                <Form.Label className="mt-1">Fornecedores</Form.Label>
                                                <select className="form-control form-control-md mt-1" id="FORNECEDOR" onChange={(e) => setId_Fornecedor(e.target.value)}>
                                                    <option value={-1}>Selecione...</option>
                                                    {fornecedores.map((fornecedor, index) =>
                                                        <option value={index}>{fornecedor.Nome}</option>
                                                    )}
                                                </select>
                                            </Col>
                                            <Col xs={12}  md={6}>
                                                <Form.Label className="mt-1">Data de Entrada</Form.Label>
                                                <Form.Control className="mt-1" id="DATA" type="date" placeholder="Digite a data" onChange={(e) => setData_Entrada(e.target.value)}/>
                                            </Col>
                                       </Row>
                                       <Row className="mt-1">
                                           <Col xs={12}  md={6} className="mt-1">
                                                <Form.Label >Preço</Form.Label>
                                                <NumberFormat allowNegative={false} maxLength="11"  decimalScale={2} fixedDecimalScale={true}  decimalSeparator={','}  className="form-control form-control-md" inputMode="numeric"    id="VALOR" onChange={e => handlePreco(e.target.value)} onKeyDown={e => handlePreco(e.target.value)} onKeyUp={e => handlePreco(e.target.value)} />    

                                            
                                            </Col>
                                        
                                                  
                                                <Col xs={12}  md={3} className="mt-1">
                                                    <Form.Label >Quantidade</Form.Label>
                                                    <NumberFormat allowNegative={false} maxLength="11"  decimalScale={2} fixedDecimalScale={true}  decimalSeparator={','}  className="form-control form-control-md" inputMode="numeric"   id="QUANTIDADE" onChange={e => handleQuantidade(e.target.value)} onKeyDown={e => handleQuantidade(e.target.value)} onKeyUp={e => handleQuantidade(e.target.value)} />
                                                    
                                                    </Col>

                                                <Col xs={12}  md={3} className="mt-1">
                                                    <Form.Label >Total</Form.Label> 
                                                    <NumberFormat as={Form.Label}  prefix={'R$'} allowNegative={false} allowLeadingZeros={false} decimalSeparator={','} isNumericString={false} decimalScale={2} fixedDecimalScale={true} value={Valor_Total} className="form-control form-control-md" inputmode="decimal" readOnly={true} />
                                                </Col> 
                                           
                                            
                                       </Row>
                                      
                                    </Card.Body>
                                   
                                    
                                </Card>
                                <Row className="justify-content-center mb-2 mt-3">
                                    <Button variant="warning" className="text-center" onClick={()=> handlerCreate()}>Salvar Entrada</Button>
                                </Row>
                            </Col>
                        </Row>
            </Container>
        );
    }

}else{
    return (
        <Row className="justify-content-center">
    <Spinner animation="border" className="central" role="status">
        <span className="sr-only">Loading...</span>
    </Spinner>
    </Row>
    );
}
}

