import api from '../../../services/api';
import React, { useEffect, useState } from 'react';
import { Button, Form , Col, Row, Container, Card, Spinner, Alert} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Trash from '../../../images/trash.svg';
import axios from 'axios';


export default  function Entrada(){
        //console.log(localStorage.getItem('token'));
        const [loading, setLoading] = useState(true);
        const [residuos, setResiduos] = useState('');
        const [processos, setProcessos] = useState('');
        const [selected, setSelected] = useState('');
        const [Chave_Acesso, setChave_Acesso] = useState('');
        const [Valor_Total, setValor_Total] = useState(0);
        const [Data_Entrada, setData_Entrada] = useState('');
        const [Preco, setPreco] = useState('');
        const [Quantidade, setQuantidade] = useState(0.00);
        const [CodProduto, setCodProduto] = useState('');
        const [Id_Fornecedor, setId_Fornecedor] = useState('');
        const [screen, setScreen] = useState(false);
        const [allProcessos, setAllProcessos] = useState([]);
        var [selectedsIndex, setSelectedsIndex] = useState([]);
        const [alert1, setAlert1] = useState(false);
        const [alert2, setAlert2] = useState(false);
        const [alert3, setAlert3] = useState(false);
        const [alert4, setAlert4] = useState(false);
        const [alert5, setAlert5] = useState(false);
        const [alert6, setAlert6] = useState(false);
        const [alert7, setAlert7    ] = useState(false);
        const [qtd, setQtd] = useState('');
        const [nomeMateria, setNomeMateria] = useState('');
        const [unit, setUnit] = useState('');


        async function handlerCreate(){
            const Id_Usuario = localStorage.getItem('Id_Usuario');
            var Id_residuo_n_acabado = residuos[selected].Id_Produto_n_acabado;
            console.log(Id_residuo_n_acabado);
            var CodProduto = residuos[selected].materia__prima.CodProduto;            

                        if(Quantidade != ''){
                            document.getElementById("TOTAL").style.borderColor = "";
                            var selecteds = allProcessos;
                            var data ={
                                Id_Usuario, Quantidade, CodProduto
                            }
                            //console.log(data);
                            api.post('api/reaproveitar', data)
                            .then(async res => {
                                history.push('/quansquisobra/');
                                
                            }).catch(function() {alert("Erro na inclusão.");});
                        }else{  
                            document.getElementById("TOTAL").style.borderColor = "red";
                            setAlert3(true);
                        }
                    
        }

        function handleRange(e){
            setQuantidade(e);
        }

        async function handlerTela(e){
            
            if(e != -1){
                setSelected(e);
                setCodProduto(residuos[e].CodProduto);
                setScreen(true);
            }else{
                setScreen(false);
            }
        }


        const history = useHistory();

        useEffect(() => {
            api.post("api/getResiduos")
            .then(function(response) {
                setQuantidade(0);
                setResiduos(response.data);
                setLoading(false);
            });

        }, []);
        console.log(residuos);

if(loading == false){
    if(screen == false){
        return (
            
            
            <Container>
                <h1 className="il">Q</h1>
                <Link to="/quansquisobra/">
                    <Button className="ir">
                            Voltar
                    </Button>
                </Link>
                <Row className="justify-content-md-center mt-5 mb-1">
                            <Col md={6}>
                            <h2>Reaproveitamento</h2>
                                <Card className="card_items mt-5 mb-5">
                                    <Card.Body>
                                        <h2>Selecione os resíduos</h2>
                                        <select className="form-control form-control-md" onChange={(e) => handlerTela(e.target.value)}>
                                            <option value="-1">Selecione...</option>
                                            {residuos.map((residuo, index) =>       
                                                <option value={index}>{residuo.materia__prima.Nome}</option>
                                            )}
                                        </select>
                                        
                                    </Card.Body>
                                    
                                </Card>
                            </Col>
                        </Row>
            </Container>
        );
    }else{
        return (
            !loading &&
            
            <Container>
                <h1 className="il">Q</h1>
                <Link to="/quansquisobra/">
                    <Button className="ir">
                            Voltar
                    </Button>
                </Link>
                {alert1  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert1(false)}>Excluído com sucesso.</Alert>}
                {alert2  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert2(false)}>Adicionado com sucesso.</Alert>}
                {alert3  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert3(false)}>Preencha corretamente os campos.</Alert>}
                {alert4  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert4(false)}>Atualizado com sucesso.</Alert>}
                {alert5  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert5(false)}>Saída cadastrada com sucesso.</Alert>}
                {alert6  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert6(false)}>Faltam {qtd} {unit} de(a) {nomeMateria}, favor dar entrada na matéria-prima.</Alert>}
                {alert7  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert7(false)}>A quantidade a ser debitada de matéria-prima fará o estoque ir abaixo do estoque mínimo. Favor conferir ou dar entrada.</Alert>}
                <Row className="justify-content-md-center mt-5 mb-1">
                            <Col xs={12} md={8}>
                            <h2>Reaproveitamento</h2>
                                <Card className="card_items  mb-1">
                                    <Card.Body>
                                       <Row className="justify-content-md-center">
                                            <Col xs={12} md={6} className="mt-2">
                                                <Form.Label>Matéria-Prima</Form.Label>
                                                <Form.Control value={residuos[selected].materia__prima.Nome} disabled />
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <Form.Label className="mt-2">Reserva    </Form.Label>
                                                <Form.Control id="QUANTIDADE"  value={residuos[selected].Quantidade} disabled/>
                                            </Col>
                                            <br></br>
                                            <br></br>

                                            <Col xs={12} md={7} className="mt-3">
                                                    <Form.Label className="mt-2">Reaproveitar</Form.Label>
                                                    <Form.Control type="range" step="0.01" className="form-control-range" id="RANGE"  value={Quantidade} min="0" max={residuos[selected].Quantidade} onChange={(e) => handleRange(e.target.value)}/>
                                                </Col>
                                                <Col xs={12} md={2} className="mt-3">
                                                    <Form.Label>Quantidade</Form.Label>
                                                    <Form.Control type="text" id="TOTAL"  value={Quantidade} onChange={(e) => (document.getElementById("RANGE").value = e.target.value,setQuantidade(e.target.value))}/>
                                                </Col>


                                       </Row>

                                      
                                    </Card.Body>
                                   
                                    
                                </Card>
                                <Row className="justify-content-center mt-3">
                                    <Button variant="warning" className="text-center" onClick={()=> handlerCreate()}>Reaproveitar</Button>
                                </Row>
                            </Col>
                        </Row>
            </Container>
        );
    }

}else{
    return (
        <Row className="justify-content-center">
    <Spinner animation="border" className="central" role="status">
        <span className="sr-only">Loading...</span>
    </Spinner>
    </Row>
    );
}
}

