import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Card, Row , Col, Form, Modal, Accordion, Spinner, Alert} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';

import Plus from '../../images/add.svg';
import Trash from '../../images/trash.svg';
import Eye from '../../images/olho.svg';
import Check from '../../images/check.png';
import Delete from '../../images/close.svg';
import NumberFormat from 'react-number-format';
import { toast, ToastContainer } from 'react-toastify';
import './styles.css';
import { Collapse } from '@material-ui/core';

export default function Material(){
    const [loading, setLoading] = useState(true);
    var [produtos_n_acabados, setProdutos_n_acabados] = useState([]);
    const [insumos, setInsumos] = useState([]);
    const [tipos_produtos, setTipos_produtos] = useState([]);
    const [material, setMaterial] = useState([]);
    const [caracteristicas, setCaracteristicas] = useState([]);
    const [filtred, setFiltred] = useState([]);
    const [search, setSearch] = useState("");
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [alert1, setAlert1] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [alert3, setAlert3] = useState(false);
    const [alert4, setAlert4] = useState(false);
    const [idDelete, setIdDelete] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const history = useHistory();
    const [selecteds, setSelecteds] = useState([]);
    var [selectedIndex, setSelectedIndex] = useState([]);
    var [selectedIndexEdit, setSelectedIndexEdit] = useState([]);
    var [editNome, setEditNome] = useState('');
    var [quansquirende, setQuansquirende] = useState('disabled');
    var [editPercent, setEditPercent] = useState('');
    var [editCodigo, setEditCodigo] = useState('');
    var [editInsumoNome, setEditInsumoNome] = useState('');
    var [editId_Insumo, setEditId_Insumo] = useState('');
    var [editUnidade, setEditUnidade] = useState('');
    var [selectedValue, setSelectedValue] = useState('');
    var [createNome, setCreateNome] = useState('');
    var [createUnidade, setCreateUnidade] = useState('');
    var [createQuantidade, setCreateQuantidade] = useState('');
    var [editQuantidade, setEditQuantidade] = useState('');
    var [createCodigo, setCreateCodigo] = useState('');
    var [selectedIndexTipoEdit,setSelectedIndexTipoEdit] = useState('');
    var Id_Tipo_Produto = '';

    const notify = (txt) => toast.success(txt, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });


        const erro = (txt) => toast.error(txt, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });

    async function handleCreate(){
        var Id_Usuario = localStorage.getItem('Id_Usuario');
        if(createNome !== '' ){
                var createId_Tipo_Produto = 0;
                var data = {
                    Id_Usuario,
                    createId_Tipo_Produto,
                    createNome,
                    selecteds
                };

                console.log(data);

                api.post("api/createProdutos_Inacabados",data)
                .then(function(response){
                    setProdutos_n_acabados(response.data);
                    handleClose();
                    notify("Adicionado com sucesso.");

                });
                //console.log(data);

        }else{
            erro("Preencha os dados corretamente.");

        }
    }

    async function todo(){
        //console.log(caracteristicas[selectedIndex]);
        if(!material[selectedIndex]){
            material[0]["pivot"] = {
                Quantidade : 0,
                Porcentagem : 100
            };
            setSelecteds([...selecteds,material[0]]);
        }else{
            material[selectedIndex]["pivot"] = {
                Quantidade : 0,
                Porcentagem : 100
            };
            setSelecteds([...selecteds,material[selectedIndex]]);
        }
    }
    
    function todoEdit(indexM){
        const items = [...produtos_n_acabados];
        const item = {...items[indexM]};
        if(!material[selectedIndexEdit]){
            material[0]["pivot"] = {
                Quantidade : 0,
                Porcentagem : 100
            };
            item.materia_prima.push(material[0]);
            items[indexM] = item;
        }else{
            material[selectedIndexEdit]["pivot"] = {
                Quantidade : 0,
                Porcentagem : 100
            };
            item.materia_prima.push(material[selectedIndexEdit]);
            items[indexM] = item;
        }
        //console.log(item);
        setProdutos_n_acabados(items);
    }    

    function removeTodoAllEdit(indexM){
        var items = [...produtos_n_acabados];
        var item = items[indexM];
        console.log(item);
        item.materia_prima = null
        console.log(item);
        items[indexM] = item;
        console.log(items);
        setProdutos_n_acabados(items);
    }

    function handlerEdit(produto, indexM){
        var Id_Usuario = localStorage.getItem('Id_Usuario');
        var selectedsEdit = produto.materia_prima;
        var Id_Produto_n_acabado = produto.Id_Produto_n_acabado;
        console.log(selectedsEdit);
        if(selectedIndexTipoEdit === '' || selectedIndexTipoEdit === '-1') {
            Id_Tipo_Produto = produto.Id_Tipo_Produto;
        }else{
            Id_Tipo_Produto = tipos_produtos[selectedIndexTipoEdit].Id_Tipo_Produto;
        }


        if(produto.materia_prima.length > 0){
            
            produto.materia_prima.forEach(element => {
                element.pivot.Quantidade = element.pivot.Quantidade.replace(',','.');
            });
        }
        if(editNome === ''){
            editNome = produto.Nome;
        }
        
        var data = {
            editNome,
            Id_Usuario,
            selectedsEdit,
            Id_Produto_n_acabado,
            Id_Tipo_Produto,
            editQuantidade
        };
        console.log(data);
        api.post("api/updateProdutos_Inacabados",data).then(function(response){
            console.log(response.data);
            notify("Atualizado com sucesso.");

            setProdutos_n_acabados(response.data);

            //history.push('/');
            //history.replace('/produtos_n_acabados');
        });
        
    }

    function reset(){
        api.post("api/getProdutos_Inacabados")
        .then(function(response) {
            setProdutos_n_acabados(response.data);
        });
    }

    function removeTodo(index){
        const list = selecteds;
        list.splice(index,1);
        setSelecteds([...list]);
    }

    function removeTodoEdit(indexM,index){
        var items = [...produtos_n_acabados];
        var item = items[indexM];
        const mat = item.materia_prima;
        mat.splice(index,1);
        item.materia_prima = mat;
        items[indexM] = item;
        setProdutos_n_acabados(items);
    }

    async function removeTodoAll(){
        setSelecteds([]);
    }

    function handlerOpenDelete(id){
        setIdDelete(id);
        document.getElementById('modalDel').style.width = '17rem';
        document.getElementById('modalDel').style.display = 'inline-block';

    }

    function handlerCancelDelete(id){
        setIdDelete('');
        document.getElementById('modalDel').style.width = '0px';
        document.getElementById('modalDel').style.display = 'none';
    }

    async function handlerDelete(id){
        document.getElementById('modalDel').style.display = 'none';
        document.getElementById('modalDel').style.width = '0px';
        var Id_Produto_n_acabado = id;
        var data = {
            Id_Produto_n_acabado,
        };
        api.post("api/deleteProdutos_Inacabados",data).then(function(response){
            notify("Excluido com sucesso.");
            setProdutos_n_acabados(response.data);
            setSearch('');
            setFiltred(response.data);
        });
    }


    useEffect(() => {
        api.post("api/getProdutos_Inacabados")
        .then(function(response) {
            setProdutos_n_acabados(response.data);
            console.log(response.data);

            api.post("api/getTipoProduto").then(function (response){
                setTipos_produtos(response.data);
                api.post("api/getMateria").then(function(response){
                    setMaterial(response.data);
                    api.post("api/getEmpresa")
                    .then(function(response) {
                        if(response.data.hasQuansquirende == 's'){
                            setQuansquirende(false);
                            setLoading(false);

                        }else{
                            setQuansquirende(true);
                            setLoading(false);

                        }
                        
                    });
                });
            });
        });
    }, []);

    
    useEffect(() => {
        setFiltred(
            produtos_n_acabados.filter((produtos) =>
            produtos.Nome.toLowerCase().includes(search.toLowerCase())
          )
        );
      }, [search, produtos_n_acabados]);

    produtos_n_acabados.forEach(produtosN => {
            if(produtosN.materia_prima === null){
                produtosN.materia_prima = [];
            }

        if(produtosN.tipo__produto.length === 0){
            produtosN.tipo__produto = [{
                "Nome":" ",
                "Tamanho":" "
            }];
        }
    });

if(loading == false){
    return (
       
        <Container>
            <ToastContainer autoClose={4000} />
            <div id="modalDel" className="confirmarModal">
                    <h3>Confirmar Exclusão</h3>
                    <Row className="justify-content-md-center mt-4">
                        <img className="checks mr-2" src={Check} onClick={() => handlerDelete(idDelete)}/>
                        <img className="checks neg mr-2" src={Delete} onClick={() => handlerCancelDelete()}/>
                    </Row>
            </div>

            <h1 className="il">Q</h1>
            <Link to="/dashboard">
                <Button className="ir">
                        Voltar
                </Button>
            </Link>
            {alert1  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert1(false)}>Excluído com sucesso.</Alert>}
            {alert2  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert2(false)}>Criado com sucesso.</Alert>}
            {alert4  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert4(false)}>Atualizado com sucesso.</Alert>}
            <Row className="justify-content-center">


                
            
                <Col xs={12} md={10}>
                <Row>
                        <Col xs={12} md={6}><h2 className="ml-2">Peças</h2></Col> 
                        <h2>Filtro:</h2><Col className="mb-2" xs={12} md={4}><Form.Control value={search} onChange={(e) => setSearch(e.target.value)}></Form.Control></Col>
                    </Row>
                    <Card className="card_items">
                        <Card.Body>
                            <Row><Col xs={12} md={4}><h2>Nome</h2></Col></Row>
                        <Accordion defaultActiveKey="0">
                            {filtred.map((produto_n_acabado,indexM)=> 
                                <Row className="item_tipo mt-2 mb-2">
                                    <Col xs={12} md={12}>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey={produto_n_acabado.Id_Produto_n_acabado} onClick={()=> reset()}> <Row><Col xs={10} md={10}><Form.Label>{produto_n_acabado.Nome}</Form.Label></Col>
                            {(produto_n_acabado.Usado == 1)? '' :

                            <Col xs={2} md={2}><div className="mg-top"><button className="ml-2" onClick={() => handlerOpenDelete(produto_n_acabado.Id_Produto_n_acabado,indexM)}><img className="img" src={Trash}></img></button></div></Col>
                            }
                              </Row></Accordion.Toggle>
                                        <Accordion.Collapse eventKey={produto_n_acabado.Id_Produto_n_acabado}>
                                            <Col xs={12} md={12} >
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <Form.Label>Nome</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={produto_n_acabado.Nome} onChange={(e) => setEditNome(e.target.value)}  />
                                                    </Col>
                                               
                                                    
                                                    </Row>
                                                <Row >

                                                <Col  xs={12} md={9}>
                                                    <Form.Label>Matéria-Prima</Form.Label>
                                                    <select className="form-control form-control-md" onChange={(e) => setSelectedIndexEdit(e.target.value)}>
                                                        {material.map((materia, index) =>
                                                            <option value={index}>{materia.Nome +' - '+materia.unidades.Descricao+" - "+materia.insumos.Nome }</option>
                                                        )}
                                                    </select>
                                                </Col>
                                                <Col xs={12} md={3} className="mt-3">
                                                    {(produto_n_acabado.Usado == 1)? '' :
                                                    <Button variant="secondary" className="mt-4" onClick={()=> todoEdit(indexM)}>Adicionar</Button>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="justify-content-center mt-4 item_tipo">
                                                <Form.Label className="ml-2 mt-2"> <h4>Matérias-Primas</h4></Form.Label>
                                                <Container className="justify-content-center">
                                                    <Row className="mb-2"><Col xs={12} md={5}><Form.Label><h4 className="ml-4">Nome</h4></Form.Label></Col><Col xs={12} md={2}><Form.Label><h4>Unidade</h4></Form.Label></Col><Col xs={12} md={2}><Form.Label><h4>Quantidade</h4></Form.Label></Col><Col xs={12} md={2}><Form.Label className="text-center"><h4>%</h4></Form.Label></Col>
                                                    {(produto_n_acabado.Usado == 1)? '' :
                                                    <Col xs={12} md={1}><img className="img_mini mr-2" src={Trash} onClick={() => removeTodoAllEdit(indexM)}/></Col>
                                                    }
                                                    </Row>
                                                    {produto_n_acabado.materia_prima.map((selected,index) => (   
                                                        <Row className="mb-2 mr-2 ml-2" >
                                                            <Col xs={12} md={5}>
                                                                <Form.Control className="form-control form-control-gb" value={selected.Nome+" - "+selected.insumos.Nome}  disabled></Form.Control>
                                                            </Col>
                                                            <Col xs={12} md={2}>
                                                                <Form.Control className="form-control form-control-gb" value={selected.unidades.Descricao} disabled></Form.Control>
                                                            </Col>
                                                            <Col xs={12} md={2}>
                                                                <NumberFormat allowNegative={false} maxLength="11"  decimalScale={2} fixedDecimalScale={true}  decimalSeparator={'.'} value={selected.pivot.Quantidade} className="form-control form-control-md" inputMode="numeric" onChange={(e)=> selected.pivot.Quantidade = e.target.value} />
                                                            </Col>
                                                            <Col xs={12} md={2}>
                                                                <Form.Control maxLength={3} className="col col-md-6" type="text" placeholder={selected.pivot.Porcentagem} onChange={(e)=> selected.pivot.Porcentagem = e.target.value} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} disabled={quansquirende}></Form.Control>
                                                            </Col>
                                                            <Col xs={12} md={1}> 
                                                                <Row className="text-center mb-3 mr-5"> 
                                                                {(produto_n_acabado.Usado == 1)? '' :
                                                                    <Col xs={{ span: 1, offset: 4 }} md={1}><img className="img_mini ml-3" src={Trash} onClick={() =>  removeTodoEdit(indexM,index)}/></Col>
                                                                }
                                                                    </Row>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </Container>
                                            </Row>  
                                                {(produto_n_acabado.Usado == 1)? '' :
                                                <Button className=" ml-4 mb-2" variant="primary" onClick={() => handlerEdit(produto_n_acabado)}>
                                                    Confirmar
                                                </Button>
                                                }
                                            </Col>
                                        </Accordion.Collapse>
                                    </Col>
                         
                                </Row>
                            )}
                            </Accordion>
                            <Col>
                                <div className="item_tipo text-center">
                                   <p>Adicionar <button className="btn btn-link" onClick={handleShow}> <img src={Plus} className="img"/></button></p>
                                        <Modal size="lg" show={show} onHide={handleClose}>
                                        {alert3  && <Alert variant="danger" size="sm" className="mt-2" dismissible onClose={() =>setAlert3(false)}>Dados incompletos.</Alert>}
                                            <Modal.Header closeButton>
                                            <Modal.Title>Criando Peças</Modal.Title>
                                            </Modal.Header>
                                                <Row className=" ml-2 mr-2 mt-2">
                                                    <Col xs={12} md={12}>
                                                        <Form.Label>Nome</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={createNome} onChange={e => setCreateNome(e.target.value)} />
                                                    </Col>


                                                </Row>
                                            
                                            <Row className="justify-content-md-center ml-2 mr-2 mt-2">
                                                <Col xs={12} md={9}>
                                                    <Form.Label>Matérias-Primas</Form.Label>
                                                    <select className="form-control form-control-md" onChange={(e) => setSelectedIndex(e.target.value)}>
                                                        {material.map((materia, index) =>
                                                            <option value={index}>{materia.Nome+' - '+materia.unidades.Descricao+" - "+materia.insumos.Nome}</option>
                                                        )}
                                                    </select>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Button className="mt-4" onClick={todo}>Adicionar</Button>
                                                </Col>
                                            </Row>
                                            
                                            <Row className="justify-content-center mr-4 mt-2">
                                            <Form.Label> <h4>Lista de matérias-primas</h4></Form.Label>
                                                <Container className="justify-content-center">
                                                    <Row className="mt-2 ml-3"><Col xs={2} md={4}><Form.Label><h4>Nome</h4></Form.Label></Col><Col xs={2} md={2}><Form.Label><h4> Unidade</h4></Form.Label></Col><Col xs={3} md={2}><Form.Label><h4>Quantidade</h4></Form.Label></Col><Col xs={3} md={2}><Form.Label><h4>Porcentagem</h4></Form.Label></Col><Col xs={{ span: 1, offset: 1 }} md={1}><img className="ml-2 img_mini" src={Trash} onClick={removeTodoAll}/></Col></Row>
                                                    {selecteds.map((selected,index) => (
                                                        <Row className="justify-content-center ml-3">
                                                            <Col xs={12} md={4}>
                                                                <Form.Label className="form-control">{selected.Nome + " - "+ selected.insumos.Nome}</Form.Label>
                                                            </Col>
                                                            <Col  xs={12}  md={2}>
                                                                <Form.Label className="form-control">{selected.unidades.Descricao}</Form.Label>
                                                            </Col>
                                                            <Col xs={12} md={2}>
                                                                <NumberFormat allowNegative={false} maxLength="11"  decimalScale={2} fixedDecimalScale={true}  decimalSeparator={'.'} value={selected.pivot.Quantidade} className="form-control form-control-md" inputMode="numeric" onChange={(e)=> selected.pivot.Quantidade = e.target.value} />
                                                             
                                                            </Col>
                                                            <Col xs={12} md={2}>
                                                                <Form.Control type="text" placeholder={selected.pivot.Porcentagem} onChange={(e)=> selected.pivot.Porcentagem = e.target.value} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} disabled={quansquirende}></Form.Control>
                                                            </Col>

                                                            <Col  xs={{ span: 1, offset: 1 }} md={1} className=" mt-2"><img className="img_mini ml-2" src={Trash} onClick={() =>  removeTodo(index)}/></Col>
                                                        </Row>
                                                    ))}
                                                </Container>
                                            </Row>
                                                        
                                            <Modal.Footer style={{padding: 0+'px!important',display: 'inline'}}>
                                                <Row>
                                                    <Col md={9}>
                                                        <h6 className="mr-5">*Porcentagem - Aproveitamento de matéria-prima por peça utilizada</h6>
                                                        <h6 className="mr-5">*Quantidade - Quantidade de matéria-prima por peça utilizada</h6>
                                                    </Col>

                                                    <Col md={3}>
                                                        <Row>
                                                             <Button variant="secondary" className="mr-2" onClick={handleClose}>
                                                              Fechar
                                                            </Button>
                                                            <Button variant="primary" onClick={handleCreate}>
                                                                Adicionar
                                                            </Button>
                                                        </Row>
                                                    
                                                    </Col>
                                                </Row>
                                            </Modal.Footer>
                                    </Modal>
                                </div>
                            </Col>
                        </Card.Body>
                        
                    </Card>
                </Col>
            </Row>
        </Container>
    );
    }else{
        return (
            <Row className="justify-content-center">
        <Spinner animation="border" className="central" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
        </Row>
        );
    }
}