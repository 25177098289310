
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Register from './pages/Register';
import Login from './pages/Login';
import PrimeiroAcesso from './pages/PrimeiroAcesso';
import Dashboard from './pages/Dashboard';
import EditUser from './pages/EditUser';
import EditEmpresa from './pages/EditEmpresa';
import EsteiraUsuarios from './pages/EsteiraUsuarios';
import Tipos from './pages/Tipos';
import Caracteristica from './pages/Caracteristicas';
import Categorias from './pages/Categorias';
import Processos from './pages/Processos';
import Fornecedores from './pages/Fornecedor';
import Insumos from './pages/Insumos';
import Material from './pages/Material';
import Produtos_n_acabados from './pages/Produtos_n_acabados';
import Quansquie from './pages/Quansquie/Dashboard';
import QuansquieProcessos from './pages/Quansquie/Processos';
import QuansquieMaterial from './pages/Quansquie/Material';
import QuansquieEntrada from './pages/Quansquie/Entrada';
import QuansquieSaida from './pages/Quansquie/Saida';
import QuansquieProduzir from './pages/Quansquie/Produzir';
import QuansquieProduzirFinal from './pages/Quansquie/ProducaoFinal';
import QuansquieEstoque from './pages/Quansquie/Produtos_acabados';
import QuansquieProdutosResultados from './pages/Quansquie/produtoResultado';
import Quansquirende from './pages/Quansquirende/Dashboard';
import Quansquisobra from './pages/Quansquisobra/Dashboard';
import QuansquiesobraResiduos from './pages/Quansquisobra/Residuos';
import QuansquiesobraReaproveitamento from './pages/Quansquisobra/Reaproveitamento';
import QuansquiesobraDescarte from './pages/Quansquisobra/Descarte';
import QuansquiesobraClassificado from './pages/Quansquisobra/Classificado';
import SideMenu from './pages/Sidemenu';
import { Col, Row } from 'react-bootstrap';

export default function Routes() {
  const WithContainer = () => (
        <Row style={{marginRight: 15+"px"}}>
          <Col md={2}>
             <Route path='/' component={SideMenu} />

          </Col>
          <Col md={9} >

            <Route path='/dashboard' exact component={Dashboard} />
            <Route path='/edituser' exact component={EditUser} />
            <Route path='/esteirausuarios' exact component={EsteiraUsuarios} />
            <Route path='/editempresa' exact component={EditEmpresa} />
            <Route path='/tipos' exact component={Tipos} />
            <Route path='/categorias' exact component={Categorias} />
            <Route path='/caracteristicas' exact component={Caracteristica} />
            <Route path='/processos' exact component={Processos} />
            <Route path='/fornecedores' exact component={Fornecedores} />
            <Route path='/insumos' exact component={Insumos} />
            <Route path='/material' exact component={Material} />
            <Route path='/produtos_n_acabados' exact component={Produtos_n_acabados} />
            <Route path='/quansquie' exact component={Quansquie} />
            <Route path='/quansquie/processos' exact component={QuansquieProcessos} />
            <Route path='/quansquie/material' exact component={QuansquieMaterial} />
            <Route path='/quansquie/entrada' exact component={QuansquieEntrada} />
            <Route path='/quansquie/saida' exact component={QuansquieSaida} />
            <Route path='/quansquie/producao' exact component={QuansquieProduzir} />
            <Route path='/quansquie/producaoFinal' exact component={QuansquieProduzirFinal} />
            <Route path='/quansquie/produtosResultados' exact component={QuansquieProdutosResultados} />
            <Route path='/quansquie/estoque' exact component={QuansquieEstoque} />
            <Route path='/quansquirende' exact component={Quansquirende} />
            <Route path='/quansquisobra' exact component={Quansquisobra} />
            <Route path='/quansquisobra/residuos' exact component={QuansquiesobraResiduos} />
            <Route path='/quansquisobra/reaproveitamento' exact component={QuansquiesobraReaproveitamento} />
            <Route path='/quansquisobra/descarte' exact component={QuansquiesobraDescarte} />
            <Route path='/quansquisobra/classificado' exact component={QuansquiesobraClassificado} />
        </Col>
        </Row>
  )

  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' exact component={Login} />
        <Route path='/login' exact component={Login} />
          <Route path='/primeiroAcesso' exact component={PrimeiroAcesso} />
          <Route path='/register' exact component={Register} />
        <Route component={ WithContainer } />
        
        
      </Switch>
    </BrowserRouter>
  );
}