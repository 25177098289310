import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Card, Row , Col, Form, Modal, Spinner, Accordion, Alert} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import api from '../../../services/api';

import Plus from '../../../images/add.svg';
import Trash from '../../../images/trash.svg';

import './styles.css';

export default function Processos(){
    const [loading, setLoading] = useState(true);
    const [processos, setProcessos] = useState([]);
    const [show, setShow] = useState(false);
    var [nomeProcesso, setNomeProcesso]= useState('');
    var [custo, setCusto]= useState('nulo');
    const [alert1, setAlert1] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [alert3, setAlert3] = useState(false);
    const [alert4, setAlert4] = useState(false);
    const [alert5, setAlert5] = useState(false);
    const [createNome, setCreateNome]= useState('');
    const [createCusto, setCreateCusto]= useState('');

    const history = useHistory();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    async function handlerDelete(id){
        api.post("api/deleteProcesso",{Id_Processo_Produto: id}).then(function(response) {
            api.post("api/indexProcesso")
                .then(function(response) {
                    setProcessos(response.data);

                    setAlert1(true);
                });
        });
        
    }

    async function handlerEdit(processo){
        if(nomeProcesso == ''){nomeProcesso = processo.Nome;}
        if(custo == 'nulo'){custo = processo.Custo;}
        var id = processo.Id_Processo_Produto;
        
        var data = {
            id,nomeProcesso, custo
        }
        
            api.post('api/editProcessos', data)
            .then(async res => {
                api.post("api/indexProcesso")
                .then(function(response) {
                    setProcessos(response.data);
                    setAlert4(true);
                });
            });
       
        
    }

    async function handleCreate(e){
        document.getElementById("NOME").style.borderColor = "";
        const Id_Usuario = localStorage.getItem('Id_Usuario');
        const Nome = createNome;
        const Custo = createCusto;
        if(!createNome == ''){
            const data = {
                Nome,Custo
                
            };
            try {
                api.post('api/createProcesso', data)
                .then(async res => {
                    api.post("api/indexProcesso")
                    .then(function(response) {
                        setProcessos(response.data);
                        setAlert2(true);
                        handleClose();
                    });
                });
            } catch (err) {
            alert('Erro no cadastro, tente novamente.');
            }
        }else{
            document.getElementById("NOME").style.borderColor = "red";
            setAlert3(true);
        }
    }

        useEffect(() => {
            try{
                api.post("api/indexProcesso")
                .then(function(response) {
                setProcessos(response.data);
                setLoading(false);
                });
                
                }catch(error){
                    console.log(error);
                }
            }, []);
            console.log(processos);

        
        if(loading == false){
            return (
                
                <Container>
            <h1 className="il">Q</h1>
            <Link to="/quansquie/">
                <Button className="ir">
                        Voltar
                </Button>
            </Link>
            {alert1  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert1(false)}>Excluído com sucesso.</Alert>}
            {alert2  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert2(false)}>Adicionado com sucesso.</Alert>}
            {alert4  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert4(false)}>Atualizado com sucesso.</Alert>}
            {alert5  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert5(false)}>Saída cadastrada com sucesso.</Alert>}
                    <Row className="justify-content-md-center">
                        <Col xs={12} md={6}>
                        <h2 className="ml-2">Processos</h2>
                            <Card className="card_items">
                                <Card.Body>
                                <Accordion defaultActiveKey="0">
                                {processos.map(processo=>
                                <Row className="item_tipo">
                                    <Col xs={12} md={10}>
                                        <Accordion.Toggle as={Card.Header} variant="link" eventKey={processo.Id_Processo_Produto}> <Row><Col xs={5} md={6}>{processo.Nome} </Col><Col xs={5} md={5}>R${processo.Custo}</Col> <Col xs={1} md={1}>
                                        <div className="mg-top">
                                            <button className="ml-2" onClick={() => handlerDelete(processo.Id_Processo_Produto)}><img className="img" src={Trash}></img></button>
                                        </div>
                                    </Col></Row></Accordion.Toggle>
                                        
                                        <Accordion.Collapse eventKey={processo.Id_Processo_Produto}>
                                            <Col xs={12}>
                                                <Row className="justify-content-md-center  mt-2">
                                                    <Col>
                                                        <Form.Label>Processo</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={processo.Nome} onChange={e => setNomeProcesso(e.target.value)} />
                                                    </Col>

                                                    <Col>
                                                        <Col>
                                                            <Form.Label>Custo</Form.Label>
                                                            <Form.Control size="md" id={"custo"+processo.Id_Processo_Produto} type="text" placeholder={processo.Custo} onChange={e => setCusto(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                                <Button className="mt-3 ml-3 mb-2" variant="primary" onClick={(e) => handlerEdit(processo)} >
                                                    Confirmar
                                                </Button>
                                            </Col>
                                        </Accordion.Collapse>

                                    </Col>
                                   
                                </Row>
                            )}
                            </Accordion>
                                    <Col>
                                        <div className="item_tipo text-center">
                                        <p>Adicionar <button className="btn btn-link" onClick={handleShow}> <img src={Plus} className="img"/></button></p>
                                                <Modal show={show} onHide={handleClose}>
                                                    {alert3  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert3(false)}>Preencha o nome corretamente.</Alert>}
                                                    <Modal.Header closeButton>
                                                    <Modal.Title>Novo processo</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Row className="justify-content-md-center  mt-2">
                                                            <Col xs={6}>
                                                                <Form.Control id="NOME" size="sm" type="text" placeholder="Digite o nome" onChange={e => setCreateNome(e.target.value)} />
                                                            </Col>
                                                            <Col xs={6}>
                                                                <Form.Control size="sm" type="text" placeholder="Digite o custo" onChange={e => setCreateCusto(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} />
                                                            </Col>
                                                        </Row>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                    <Button variant="secondary" onClick={handleClose}>
                                                        Fechar
                                                    </Button>
                                                    <Button variant="primary" onClick={handleCreate}>
                                                        Adicionar
                                                    </Button>
                                                    </Modal.Footer>
                                            </Modal>
                                        </div>
                                    </Col>
                                </Card.Body>
                                
                            </Card>
                        </Col>
                    </Row>
                    
                </Container>
            );
        }else{
            return (
            <Row className="justify-content-center">
                <Spinner animation="border" className="central" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </Row>
            );
        } 
}