import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { FiLogIn } from 'react-icons/fi';
import {Navbar,Form,Card, CardGroup, Button, Container,Row, Col, Spinner, Image, Dropdown, DropdownButton} from 'react-bootstrap';
import './styles.css';
import Delete from '../../../images/delete.svg';
import Settings from '../../../images/settings.svg';
import Home from '../../../images/casa.svg';
import Lista from '../../../images/lista.svg';
import Renda from '../../../images/renda.svg';
import QR from '../../../images/qr-code.svg';
import Insumo from '../../../images/Insumo.svg'
import Lixeira from '../../../images/trash.svg';
import Materia from '../../../images/materia-prima.svg';
import Moedas from '../../../images/moedas.svg';
import Fornecedor from '../../../images/fornecedor.svg';
import Product from '../../../images/box.svg';
import Estoque from '../../../images/estoque.svg';
import Gear from '../../../images/gear.svg';
import Arrow2 from '../../../images/arrow2.svg';

export default function Dashboard(){
    const [loading, setLoading] = useState(true);
    const [empresa, setEmpresa] = useState('');
    const [entrada, setEntrada] = useState('');
    const [saida, setSaida] = useState('');
    const [producao1, setProducao1] = useState('');
    const [producao2, setProducao2] = useState('');
    const [empresa1, setEmpresa1] = useState('');
    const [empresa2, setEmpresa2] = useState('');
    const [empresa3, setEmpresa3] = useState('');
    const [empresa4, setEmpresa4] = useState('');
    const [empresa5, setEmpresa5] = useState('');
    const [logoEmpresa, setLogoEmpresa] = useState('');
    const [item2,setItem2] = useState('');
    const [item3,setItem3] = useState('');
    const [Nome, setNome] = useState('')
    const [img, setImg] = useState('');
    const [saidaM,setSaidaM] = useState('');
    const [entradaM,setEntradaM] = useState('');
    const [producaoPeça,setProduçaoPeça] = useState('');
    const [producaoFinal,setProducaoFinal] = useState('');
    const [reaproveitamento,setReaproveitamento] = useState('');
    const [descarte,setDescarte] = useState('');

    const [filter, setFilter] = useState('');

    const history = useHistory();
    if(localStorage.getItem('token') === 'null'){
        history.push('/login');
    }

    function openNav() {
        document.getElementById("mySidenav").style.width = "250px";
        document.getElementById("ARROW_SIDE").style.zIndex  = "0";
      }
      
      /* Set the width of the side navigation to 0 */
      function closeNav() {
        document.getElementById("ARROW_SIDE").style.zIndex  = "2";
        document.getElementById("mySidenav").style.width = "0";
      }
    

    useEffect(() => {
        if(localStorage.getItem('token') === 'null'){
            history.push('/login');
        }
        console.log(localStorage.getItem('token'));
        console.log(localStorage.getItem('Id_Usuario'));
        var Id_Usuario = localStorage.getItem('Id_Usuario');
        setNome(localStorage.getItem('Nome'));

        var data = {
            Id_Usuario
        }

        api.post("api/getAutenticado",data)
        .then(function(response) {
            console.log(response.data);
            if(response.data.perfil.CodPerfil == 1 || response.data.perfil.CodPerfil == 2){
                setEntrada(<Link to='/quansquie/entrada'><Button variant="secondary" className="mr-2" size="lg">Entrada</Button></Link>);
                setSaida(<Link to='/quansquie/saida'><Button variant="danger" size="lg">Saída</Button></Link>);
                setProducao1(<Link to='/quansquie/producao'><Button variant="secondary" className="mr-2" size="lg">Produzir Peças</Button></Link>);
                setProducao2(<Link to='/quansquie/producaoFinal'><Button variant="secondary" className="mr-2" size="lg">Produzir Produtos</Button></Link>);
            }else{
                setEntrada('');
                setSaida('');
                setProducao1('');
                setProducao2('');   
            }

            if(response.data.perfil.CodPerfil == 1){
                setItem2(<Dropdown.Item><Link variant="none" to="/EditEmpresa">Editar Empresa</Link></Dropdown.Item>);
                setItem3(<Dropdown.Item><Link variant="none" to="/esteirausuarios">Esteira de Usuários</Link></Dropdown.Item>);
                setEntradaM(<Link to='/quansquie/entrada'>Entrada de Matéria-Prima</Link>);
                setSaidaM(<Link to='/quansquie/saida'>Saída de Matéria-Prima</Link>);
                setProduçaoPeça(<Link to='/quansquie/producao'>Produção de Peças</Link>);
                setProducaoFinal(<Link to="/quansquie/producaoFinal">Produção de Produtos</Link>);
                setDescarte(<Link to="/quansquisobra/reaproveitamento">Reaproveitamento</Link>);
                setReaproveitamento(<Link to="/quansquisobra/descarte">Descarte</Link>);
            }else if( response.data.perfil.CodPerfil == 2){
                setItem2('');
                setItem3('');
                setEntradaM(<Link to='/quansquie/entrada'>Entrada de Matéria-Prima</Link>);
                setSaidaM(<Link to='/quansquie/saida'>Saída de Matéria-Prima</Link>);
                setProduçaoPeça(<Link to='/quansquie/producao'>Produção de Peças</Link>);
                setProducaoFinal(<Link to="/quansquie/producaoFinal">Produção de Produtos</Link>);
                setDescarte(<Link to="/quansquisobra/reaproveitamento">Reaproveitamento</Link>);
                setReaproveitamento(<Link to="/quansquisobra/descarte">Descarte</Link>);
            }else{
                setEntradaM();
                setSaidaM();
                setProduçaoPeça();
                setProducaoFinal();
                setDescarte();
                setReaproveitamento();
            }

            api.post("api/getEmpresa").then(function(response){
                setEmpresa(response.data);
                setImg(<Image className="img_bot" src={Gear}/>)

                var src = "http://api.quans.projetoscomputacao.com.br/myfiles/avatars/"+response.data.Logo;
                setLogoEmpresa(<Image src={src} className="logo"></Image>);

                setEmpresa1(<button data-toggle="tooltip" data-placement="top" title="Quans"  className="img_bot"> <Link to="/dashboard" ><Image  src={Home}/></Link></button>);
                console.log(empresa);
                if(response.data.hasQuansquie == 's'){
                    setEmpresa2(<button data-toggle="tooltip" data-placement="top" title="Quansquie"  className="img_bot filter"> <Link to="/Quansquie" ><Image src={Moedas} /></Link></button>);
                }else{
                    setEmpresa2(<button data-toggle="tooltip" data-placement="top" title="Quansquie"  className="img_bot filter" disabled><Image src={Moedas} /></button>);
                }
                if(response.data.hasQuansquisobra == 's'){
                    setEmpresa3(<button  data-toggle="tooltip" data-placement="top" title="Quansquisobra" style={{delay:{'show':0}}}> <Link to="/quansquisobra" ><Image  className="img_bot" src={Lixeira} /></Link></button>);
                }else{
                    setEmpresa3(<span data-toggle="tooltip" data-placement="top" title="Quansquisobra"><button  disabled><Image className="img_bot filter" src={Lixeira} /></button></span>);
                }

                if(response.data.hasQuansquirende == 's'){
                    setEmpresa4(<button data-toggle="tooltip" data-placement="top" title="Quansquirende" className="img_bot"><Link to='/quansquirende'><Image src={Renda} /></Link></button>);
                }else{
                    setEmpresa4(<button data-toggle="tooltip" data-placement="top" title="Quansquirende" className="img_bot filter" disabled><Image src={Renda} /></button>);
                }
        
                if(response.data.hasQROV == 's'){
                    setEmpresa5(<button data-toggle="tooltip" data-placement="top" title="Qro-V" className="img_bot" ><Image src={QR} onClick={() => handleQRV()} /></button>);
                    setLoading(false);
                    
                }else{
                    setEmpresa5(<button data-toggle="tooltip" data-placement="top" title="Qro-V" className="img_bot filter" disabled><Image src={QR} /></button>);
                    setLoading(false); 
                }
                
            }).catch(function(){
                alert("Não foi possível acessar o sistema!");
                history.push('/login');
            });

        });
       
            
  
        }, []);
        //console.log(localStorage.getItem('token'));
        
        function handleQRV()
        {
            window.open('http://qrov.projetoscomputacao.com.br','_blank')
        }

    async function handleLogout(e) {
        try {
        localStorage.setItem('token',null)
        history.push('/login');
        } catch (err) {
        
          alert('Nao foi possível sair.');
          
        }
      }

    async function handleConfig(e){
        history.push('/edituser');
    }

    if(loading == false){
        return (

       <Container fluid>
             <Row className="justify-content-md-center mt-5">
                <Col xs={3} lg={4} className=""><h1 className="title">Q</h1></Col>
                
                <Col xs={6} lg={3}> <Row className="justify-content-md-center"><Col xs={12} lg={12} className="text-center"></Col><Col xs={12} lg={12} className="text-center"><h1 className="">{empresa.Razaosocialcurto}</h1></Col></Row>
                </Col>
                <Col xs={3} md={4} className="text-center">
                    <Row className="justify-content-md-center">
               <h6 className="mt-2">Bem vindo, </h6>
                    <DropdownButton variant="none" id="dropdown-basic-button" title={Nome}>
                        <Dropdown.Item><Link variant="none" to="./EditUser">Editar Usuário</Link></Dropdown.Item>

                            
                            {item2}
                            {item3}
                            

                            <Dropdown.Item><Link onClick={(e) => handleLogout(e)} className="">
                                    Sair
                            </Link></Dropdown.Item>
                </DropdownButton>
                </Row>
                
                </Col>
            </Row>

         
            <Row className="mt-5 justify-content-center">
                    <Col md={3} className="mb-2">
                        <Link to='/quansquie/material'>
                            <Card>
                                <Card.Body>
                                    <div className="cars_cont" >
                                        <Card.Img variant="top" src={Materia} className="Img" />
                                        <Card.Title className="card_title">Material</Card.Title>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Link>
                        <Row className="mt-2 justify-content-center"> 
                        {entrada}
                        {saida}
                        </Row>
                    </Col>
                    <Col md={3} className="mb-5">
                        <Link to="/quansquie/estoque">
                                <Card>
                                    <Card.Body>
                                        <div className="cars_cont" >
                                            <Card.Img variant="top" src={Product} className="Img" />
                                            <Card.Title className="card_title">Peças</Card.Title>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Link>
                        <Row className="mt-2 justify-content-center"> 
                        {producao1}
                        </Row>
                    </Col>
                    <Col md={3} className="mb-5">
                    <Link to="/quansquie/produtosResultados">
                            <Card>
                                <Card.Body>
                                    <div className="cars_cont" >
                                        <Card.Img variant="top" src={Estoque} className="Img" />
                                        <Card.Title className="card_title">Estoque</Card.Title>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Link>
                        <Row className="mt-2 justify-content-center"> 
                            {producao2}
                        </Row>
                </Col>
                   
            </Row>
            <Row className="justify-content-sm-center footer mt-5">
                    <Row className="justify-content-sm-center nave">
                       
                         {empresa1}
                         {empresa2}
                         {empresa3}
                         {empresa4}
                         {empresa5}

                    </Row>
                    
            </Row>
       </Container>

);
}else{
    return (
        <Row className="justify-content-center">
    <Spinner animation="border" className="central" role="status">
        <span className="sr-only">Loading...</span>
    </Spinner>
    </Row>
    );
}
}
