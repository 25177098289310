import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Card, Row , Col, Alert, Modal, Spinner, Form} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';

import Plus from '../../images/add.svg';
import Trash from '../../images/trash.svg';
import { toast, ToastContainer } from 'react-toastify';
import Check from '../../images/check.png';
import Delete from '../../images/close.svg';

import './styles.css';

export default function Tipos(){
    const [loading, setLoading] = useState(true);
    const [tipos, setTipos] = useState([]);
    const [show, setShow] = useState(false);
    const [nomeTipo, setNomeTipo]= useState('');
    const [idTipo, setIdTipo] = useState('');
    const [alert1, setAlert1] = useState('');
    const [alert2, setAlert2] = useState('');
    const [alert3, setAlert3] = useState('');
    const [filtredTipos, setFiltredTipos] = useState([]);
    const [search, setSearch] = useState("");
    const [idDelete, setIdDelete] = useState('');

    const history = useHistory();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    async function handlerDelete(id,index){
        document.getElementById('modalDel').style.width = '0px';
        document.getElementById('modalDel').style.display = 'none';
        api.post("api/deleteTipos",{Id_Tipo: id}).then(function(response) {
            notify("Excluído com sucesso.");
            setTipos(response.data);
        });
        
    }

    
    function handlerOpenDelete(id){
        setIdDelete(id);
        document.getElementById('modalDel').style.width = '17rem';
        document.getElementById('modalDel').style.display = 'inline-block';

    }

    function handlerCancelDelete(id){
        setIdDelete('');
        document.getElementById('modalDel').style.width = '0px';
        document.getElementById('modalDel').style.display = 'none';
    }

    const notify = (txt) => toast.success(txt, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    
    
        const erro = (txt) => toast.error(txt, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });

    async function handleCreate(e){
        const Id_Usuario = localStorage.getItem('Id_Usuario');
        const Nome = nomeTipo
        if(!nomeTipo == ''){
            const data = {
                Nome,
                Id_Usuario
            };
            try {
                api.post('api/createTipos', data)
                .then(async res => {
                    handleClose();
                    notify("Adicionado com sucesso.");
                    setTipos(res.data);
                });
            } catch (err) {
               erro("Preencha todos os campos.");
            }
        }else{
            erro("Preencha todos os campos.");
        }
    }

        useEffect(() => {
            try{
                api.post("api/getTipos")
                .then(function(response) {
                setTipos(response.data);
                setLoading(false);
                });
                
                }catch(error){
                    console.log(error);
                }
            }, []);

            useEffect(() => {
                setFiltredTipos(
                  tipos.filter((tipo) =>
                  tipo.Nome.toLowerCase().includes(search.toLowerCase())
                  )
                );
              }, [search, tipos]);
            console.log(tipos);

            if(loading == false){
                return (
        
        <Container>
            <h1 className="il">Q</h1>
            <Link to="/dashboard">
                <Button className="ir">
                        Voltar
                </Button>
            </Link>
            <ToastContainer autoClose={4000} />

            <div id="modalDel" className="confirmarModal">
                    <h3>Confirmar Exclusão</h3>
                    <Row className="justify-content-md-center mt-4">
                        <img className="checks mr-2" src={Check} onClick={() => handlerDelete(idDelete)}/>
                        <img className="checks neg mr-2" src={Delete} onClick={() => handlerCancelDelete()}/>
                    </Row>
            </div>

            {alert1  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert1(false)}>Excluído com sucesso.</Alert>}
            {alert2  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert2(false)}>Criado com sucesso.</Alert>}
            <Row className="justify-content-md-center">
                <Col md={6}>
                <Row>
                            <Col xs={12} md={6}><h2 className="ml-2">Tipos</h2></Col> 
                            <h2>Filtro:</h2><Col className="mb-2" xs={12} md={4}><Form.Control onChange={(e) => setSearch(e.target.value)}></Form.Control></Col>
                        
                        </Row>
                    <Card className="card_items">
                        <Card.Body>
                            {filtredTipos.map((tipo,index)=>
                                <Row className="item_tipo">
                                    <Col xs={8} md={9}>
                                        <p>{tipo.Nome}</p>
                                    </Col>
                                    {(tipo.Usado == 1)? '' :
                                    <Col xs={4} md={2}>
                                        <div className="mg-top"><button onClick={() => handlerOpenDelete(tipo.Id_Tipo,index)}><img className="img" src={Trash}></img></button></div>
                                    </Col>
                                    }
                                </Row>
                            )}
                            <Col>
                                <div className="item_tipo text-center">
                                   <p>Adicionar <button className="btn btn-link" onClick={handleShow}> <img src={Plus} className="img"/></button></p>
                                        <Modal show={show} onHide={handleClose}>
                                            {alert3  && <Alert variant="danger" size="sm" className="mt-2" dismissible onClose={() =>setAlert3(false)}>Dados incompletos.</Alert>}
                                            <Modal.Header closeButton>
                                            <Modal.Title>Digite o seu tipo</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body><Form.Control 
                                                    type="text" 
                                                    placeholder="Digite o tipo"
                                                    value={nomeTipo}
                                                    onChange={e => setNomeTipo(e.target.value)}/>
                                            </Modal.Body>
                                            <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Fechar
                                            </Button>
                                            <Button variant="primary" onClick={handleCreate}>
                                                Adicionar
                                            </Button>
                                            </Modal.Footer>
                                    </Modal>
                                </div>
                            </Col>
                        </Card.Body>
                        
                    </Card>
                </Col>
            </Row>
            
        </Container>
    );
}else{
    return (
        <Row className="justify-content-center">
    <Spinner animation="border" className="central" role="status">
        <span className="sr-only">Loading...</span>
    </Spinner>
    </Row>
    );
}
}