import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Card, Row , Col, Form, Modal, Spinner, Accordion,Alert} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';
import Check from '../../images/check.png';
import Delete from '../../images/close.svg';
import { toast, ToastContainer } from 'react-toastify';

import Plus from '../../images/add.svg';
import Trash from '../../images/trash.svg';
import './styles.css';

export default function Tipos(){
    const [loading, setLoading] = useState(true);
    const [categorias, setCategorias] = useState([]);
    const [show, setShow] = useState(false);
    const [nome, setNome]= useState('');
    const [tamanho, setTamanho]= useState('');
    const history = useHistory();
    const [alert1, setAlert1] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [alert3, setAlert3] = useState(false);
    const [filtred, setFiltred] = useState([]);
    const [search, setSearch] = useState("");
    const [idDelete, setIdDelete] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    async function handlerDelete(id,index){
        document.getElementById('modalDel').style.width = '0px';
        document.getElementById('modalDel').style.display = 'none';
        api.post("api/deleteTipoProduto",{Id_Tipo_Produto: id}).then(function(response) {
            notify("Excluído com sucesso.");
            setCategorias(response.data);
            setSearch('');
            setFiltred(response.data);
        });
        
    }

    
    const notify = (txt) => toast.success(txt, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

        
    const erro = (txt) => toast.error(txt, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

    function handlerOpenDelete(id){
        setIdDelete(id);
        document.getElementById('modalDel').style.width = '17rem';
        document.getElementById('modalDel').style.display = 'inline-block';

    }

    function handlerCancelDelete(id){
        setIdDelete('');
        document.getElementById('modalDel').style.width = '0px';
        document.getElementById('modalDel').style.display = 'none';
    }


    async function handlerEdit(caracteristica){

    }

    async function handleCreate(e){
        const Id_Usuario = localStorage.getItem('Id_Usuario');
        var createNome = nome;
        var createTamanho = tamanho;
        if(!createTamanho == '' && !createNome == ''){
                const data = {
                    createNome,
                    createTamanho,
                    Id_Usuario
                }
                try {
                    api.post('api/createTipoProduto', data)
                    .then(async res => {
                        handleClose();
                        notify("Criado com sucesso.");

                        setCategorias(res.data);
                    });
                } catch (err) {
                    erro("Preencha os campos corretamente.");
                }
            }else{
                erro("Preencha os campos corretamente.");
            }
    }

        useEffect(() => {
            try{
                api.post("api/getTipoProduto")
                .then(function(response) {
                setCategorias(response.data);
                setLoading(false);
                });
                
                }catch(error){
                    console.log(error);
                }
            }, []);


            useEffect(() => {
                setFiltred(
                  categorias.filter((carac) =>
                  carac.Nome.toLowerCase().includes(search.toLowerCase())
                  )
                );
              }, [search, categorias]);

            console.log(categorias);

            if(loading == false){
                return (
        
        <Container>
            <h1 className="il">Q</h1>
            <Link to="/dashboard">
                <Button className="ir">
                        Voltar
                </Button>
            </Link>
            <ToastContainer autoClose={4000} />
            <div id="modalDel" className="confirmarModal">
                    <h3>Confirmar Exclusão</h3>
                    <Row className="justify-content-md-center mt-4">
                        <img className="checks mr-2" src={Check} onClick={() => handlerDelete(idDelete)}/>
                        <img className="checks neg mr-2" src={Delete} onClick={() => handlerCancelDelete()}/>
                    </Row>
            </div>
            {alert1  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert1(false)}>Excluído com sucesso.</Alert>}
            {alert2  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert2(false)}>Criado com sucesso.</Alert>}
            <Row className="justify-content-md-center">
                <Col md={8}>
                <Row>
                    
                        <Col xs={12} md={6}><h2 className="ml-2">Categorias</h2></Col> 
                        <h2>Filtro:</h2><Col className="mb-2" xs={12} md={4}><Form.Control value={search} onChange={(e) => setSearch(e.target.value)}></Form.Control></Col>
                    </Row>
                    <Card className="card_items">
                        <Card.Body>
                        <Accordion defaultActiveKey="0">
                            {filtred.map((categoria,index)=> 
                                <Row className="item_tipo">
                                    <Col md={10}>
                                        <Accordion.Toggle as={Card.Header} variant="link" eventKey={categoria.Id_Tipo_Produto}> <Row><Col md={11} xs={8}>{categoria.Nome} </Col>
                                        {(categoria.Usado == 1)? '' :
                                        <Col xs={2} md={1}><div className="mg-top"><button className="ml-2" onClick={() => handlerOpenDelete(categoria.Id_Tipo_Produto,index)}><img className="img" src={Trash}></img></button></div></Col>
                                        }
                                        </Row></Accordion.Toggle>
                                        <Accordion.Collapse eventKey={categoria.Id_Tipo_Produto}>
                                            <Col>
                                                <Row className="justify-content-md-center mb-3 ml-2 mr-2 mt-2">
                                                    <Col md={6}>
                                                        <Form.Label>Nome</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={categoria.Nome} disabled />
                                                    </Col>


                                                    <Col md={6}>
                                                        <Form.Label>Tamanho</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={categoria.Tamanho} disabled/>
                                                    </Col>
                                                  
                                                </Row>
                                                
                                            </Col>
                                        </Accordion.Collapse>

                                    </Col>
                                    
                                </Row>
                            )}
                            </Accordion>
                            <Col>
                                <div className="item_tipo text-center">
                                   <p>Adicionar <button className="btn btn-link" onClick={handleShow}> <img src={Plus} className="img"/></button></p>
                                        <Modal show={show} onHide={handleClose}>
                                        {alert3  && <Alert variant="danger" size="sm" className="mt-2" dismissible onClose={() =>setAlert3(false)}>Dados incompletos.</Alert>}
                                            
                                            <Modal.Header closeButton>
                                            <Modal.Title>Criando Categorias</Modal.Title>
                                            </Modal.Header>
                                            <Row className="justify-content-md-center mb-3 ml-2 mr-2 mt-2">
                                                <Col>
                                                    <Form.Control size="sm" type="text" placeholder="Digite o nome" onChange={e => setNome(e.target.value)} />
                                                </Col>
                                                <Col>
                                                    <Form.Control size="sm" type="text" placeholder="Digite o tamanho" onChange={e => setTamanho(e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Fechar
                                            </Button>
                                            <Button variant="primary" onClick={handleCreate}>
                                                Adicionar
                                            </Button>
                                            </Modal.Footer>
                                    </Modal>
                                </div>
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            
        </Container>
    );
}else{
    return (
        <Row className="justify-content-center">
    <Spinner animation="border" className="central" role="status">
        <span className="sr-only">Loading...</span>
    </Spinner>
    </Row>
    );
}
}