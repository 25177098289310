import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { FiLogIn } from 'react-icons/fi';
import {Navbar,Form,Card, CardGroup, Button, Container,Row, Col, Spinner, Image, Dropdown, DropdownButton} from 'react-bootstrap';
import '../../Dashboard/styles.css';
import Delete from '../../../images/delete.svg';
import Settings from '../../../images/settings.svg';
import Home from '../../../images/casa.svg';
import Lista from '../../../images/lista.svg';
import Renda from '../../../images/renda.svg';
import Lixeira from '../../../images/trash.svg';
import QR from '../../../images/qr-code.svg';
import Insumo from '../../../images/Insumo.svg'
import Materia from '../../../images/materia-prima.svg';
import Moedas from '../../../images/moedas.svg';
import Fornecedor from '../../../images/fornecedor.svg';
import Product from '../../../images/box.svg';
import Gear from '../../../images/gear.svg';
import Arrow2 from '../../../images/arrow2.svg';



export default function Dashboard(){
    const [loading, setLoading] = useState(true);
    const [empresa,setEmpresa] = useState('');
    const [empresa1, setEmpresa1] = useState('');
    const [empresa2, setEmpresa2] = useState('');
    const [empresa3, setEmpresa3] = useState('');
    const [empresa4, setEmpresa4] = useState('');
    const [empresa5, setEmpresa5] = useState('');
    const [logoEmpresa, setLogoEmpresa] = useState('');
    const [img, setImg] = useState('');
    const [item2,setItem2] = useState('');
    const [item3,setItem3] = useState('');
    const [saida,setSaida] = useState('');
    const [entrada,setEntrada] = useState('');
    const [producaoPeça,setProduçaoPeça] = useState('');
    const [producaoFinal,setProducaoFinal] = useState('');

    const [esteiraQuisobra,setEsteiraQuansquisobra] = useState('');
    const [Nome, setNome] = useState('');
    const url = "http://api.quans.projetoscomputacao.com.br/";
    //const url = "http://127.0.0.1:8000/";
    

    const [filter, setFilter] = useState('');

    const history = useHistory();
    if(localStorage.getItem('token') === 'null'){
        history.push('/login');
    }

    
    function openNav() {
        document.getElementById("mySidenav").style.width = "250px";
        document.getElementById("ARROW_SIDE").style.zIndex  = "0";
      }
      
      /* Set the width of the side navigation to 0 */
      function closeNav() {
        document.getElementById("ARROW_SIDE").style.zIndex  = "2";
        document.getElementById("mySidenav").style.width = "0";
      }
    

    async function handleClientes(){
        var Id_Usuario = localStorage.getItem('Id_Usuario');
        var data = {
            Id_Usuario : Id_Usuario
        }
        api.post("api/storePDFCliente",data).then(function(response){
            
            window.open(url+'pdfStore/Usuarios.pdf','_blank');
        });
    }

    async function handleEntrada(){
        var Id_Usuario = localStorage.getItem('Id_Usuario');
        var data = {
            Id_Usuario : Id_Usuario
        }
        api.post("api/storePDFEntrada",data).then(function(response){
            
            window.open(url+'pdfStore/Entrada.pdf','_blank');
        });
    }

    async function handleSaida(){
        var Id_Usuario = localStorage.getItem('Id_Usuario');
        var data = {
            Id_Usuario : Id_Usuario
        }
        api.post("api/storePDFSaida",data).then(function(response){
            
            window.open(url+'pdfStore/Saida.pdf','_blank');
        });
    }

    async function handleEstoque(){
        var Id_Usuario = localStorage.getItem('Id_Usuario');
        var data = {
            Id_Usuario : Id_Usuario
        }
        api.post("api/storePDFEstoque",data).then(function(response){
            
            window.open(url+'pdfStore/Estoque.pdf','_blank');
        });
    }

    useEffect(() => {

        if(localStorage.getItem('token') === 'null'){
            history.push('/login');
        }
        console.log(localStorage.getItem('token'));
        console.log(localStorage.getItem('Id_Usuario'));
        var Id_Usuario = localStorage.getItem('Id_Usuario');
        setNome(localStorage.getItem('Nome'));

        var data = {
            Id_Usuario
        }

        api.post("api/getAutenticado",data)
        .then(function(response) {

            if(response.data.perfil.CodPerfil == 1){
                setItem2(<Dropdown.Item><Link variant="none" to="/EditEmpresa">Editar Empresa</Link></Dropdown.Item>);
                setItem3(<Dropdown.Item><Link variant="none" to="/esteirausuarios">Esteira de Usuários</Link></Dropdown.Item>);
                setEntrada(<Link to='/quansquie/entrada'>Entrada de Matéria-Prima</Link>);
                setSaida(<Link to='/quansquie/saida'>Saída de Matéria-Prima</Link>);
                setProduçaoPeça(<Link to='/quansquie/producao'>Produção de Peças</Link>);
                setProducaoFinal(<Link to="/quansquie/producaoFinal">Produção de Produtos</Link>);
            }else{
                setItem2('');
                setItem3('');
            }

                api.post("api/getEmpresa").then(function(response){
                    setImg(<Image className="img_bot" src={Gear}/>)
                    setEmpresa(response.data);

                    var src = "http://api.quans.projetoscomputacao.com.br/myfiles/avatars/"+response.data.Logo;
                    setLogoEmpresa(<Image src={src} className="logo"></Image>);

                    setEmpresa1(<button data-toggle="tooltip" data-placement="top" title="Quans" className="img_bot"> <Link to="/dashboard" ><Image  src={Home}/></Link></button>);
                    console.log(empresa);
                    if(response.data.hasQuansquie == 's'){
                        setEmpresa2(<button  data-toggle="tooltip" data-placement="top" title="Quansquie" className="img_bot"> <Link to="/Quansquie" ><Image src={Moedas} /></Link></button>);
                    }else{
                        setEmpresa2(<button  data-toggle="tooltip" data-placement="top" title="Quansquie" className="img_bot filter" disabled><Image src={Moedas} /></button>);
                    }
            
                    if(response.data.hasQuansquisobra == 's'){
                        setEmpresa3(<button  data-toggle="tooltip" data-placement="top" title="Quansquisobra" style={{delay:{'show':0}}}> <Link to="/quansquisobra" ><Image  className="img_bot" src={Lixeira} /></Link></button>);
                    }else{
                        setEmpresa3(<span data-toggle="tooltip" data-placement="top" title="Quansquisobra"><button  disabled><Image className="img_bot filter" src={Lixeira} /></button></span>);
                    }
                    
                    if(response.data.hasQuansquirende == 's'){
                        setEmpresa4(<button  data-toggle="tooltip" data-placement="top" title="Quansquirende" className="img_bot filter"><Link to='/quansquirende'><Image src={Renda} /></Link></button>);
                    }else{
                        setEmpresa4(<button data-toggle="tooltip" data-placement="top" title="Quansquirende" className="img_bot filter" disabled><Image src={Renda} /></button>);
                    }
            
                    if(response.data.hasQROV == 's'){
                        setEmpresa5(<button data-toggle="tooltip" data-placement="top" title="Qro-V" className="img_bot" ><Image src={QR} onClick={() => handleQRV()} /></button>);
                        setLoading(false);
                        
                    }else{
                        setEmpresa5(<button data-toggle="tooltip" data-placement="top" title="Qro-V" className="img_bot filter" disabled><Image src={QR} /></button>);
                        setLoading(false); 
                    }
                    
                });
                
        });

        }, []);
        //console.log(localStorage.getItem('token'));
        
    function handleQRV()
    {
        window.open('http://qrov.projetoscomputacao.com.br','_blank')


    }
    async function handleLogout(e) {
        try {
        localStorage.setItem('token',null)
        history.push('/login');
        } catch (err) {
        
          alert('Nao foi possível sair.');
          
        }
      }

    async function handleConfig(e){
        history.push('/edituser');
    }

    if(loading == false){
        return (

       <Container fluid>
             <Row className="justify-content-md-center mt-5">
                <Col xs={3} lg={4} className=""><h1 className="title">Q</h1></Col>
                
                <Col xs={6} lg={3}> <Row className="justify-content-md-center"><Col xs={12} lg={12} className="text-center"> </Col><Col xs={12} lg={12} className="text-center"><h1 className="">{empresa.Razaosocialcurto}</h1></Col></Row>
                </Col>
                <Col xs={3} md={4} className="text-center">
                    <Row className="justify-content-md-center">
               <h6 className="mt-2">Bem vindo, </h6>
                    <DropdownButton variant="none" id="dropdown-basic-button" title={Nome}>
                        <Dropdown.Item><Link variant="none" to="./EditUser">Editar Usuário</Link></Dropdown.Item>

                            {item2}
                            {item3}

                            <Dropdown.Item><Link onClick={(e) => handleLogout(e)} className="">
                                    Sair
                            </Link></Dropdown.Item>
                </DropdownButton>
                </Row>
                
                </Col>
            </Row>


            
            <Row className="mt-5 ">
                    <Col md={3} className="mb-5  text-center">
                        <Card.Title>Clientes</Card.Title>
                            <Card>
                                <Card.Body>
                                    <Button onClick={()=> handleClientes()}>Baixar relatório</Button>
                                </Card.Body>
                            </Card>
                    </Col>
                    <Col md={3} className="mb-5  text-center">
                        <Card.Title>Entradas</Card.Title>
                            <Card>
                                <Card.Body>
                                    <Button onClick={()=> handleEntrada()}>Baixar relatório</Button>
                                </Card.Body>
                            </Card>
                    </Col>
                    <Col md={3} className="mb-5  text-center">
                        <Card.Title>Saidas</Card.Title>
                            <Card>
                                <Card.Body>
                                    <Button onClick={()=> handleSaida()}>Baixar relatório</Button>
                                </Card.Body>
                            </Card>
                    </Col>
                    <Col md={3} className="mb-5  text-center">
                        <Card.Title>Estoque</Card.Title>
                            <Card>
                                <Card.Body>
                                    <Button onClick={()=> handleEstoque()}>Baixar relatório</Button>
                                </Card.Body>
                            </Card>
                    </Col>
            </Row>
            <Row className="justify-content-sm-center footer mt-5">
                    <Row className="justify-content-sm-center nave">
                       
                         {empresa1}
                         {empresa2}
                         {empresa3}
                         {empresa4}
                         {empresa5}
                         
                    </Row>
                    
            </Row>
       </Container>

);
}else{
    return (
        <Row className="justify-content-center">
    <Spinner animation="border" className="central" role="status">
        <span className="sr-only">Loading...</span>
    </Spinner>
    </Row>
    );
}
}
