import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Card, Row , Col, Form, Modal, Accordion, Spinner, Alert} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';
import { cpf } from 'cpf-cnpj-validator'; 
import Plus from '../../images/add.svg';
import Trash from '../../images/trash.svg';
import Eye from '../../images/olho.svg';

import { Collapse } from '@material-ui/core';
import { toast, ToastContainer } from 'react-toastify';

import './style.css';

export default function Processos(){
    const [loading, setLoading] = useState(true);
    const [usuarios, setUsuarios] = useState([]);
    const [perfis, setPerfis] = useState([]);
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedIndexEdit, setSelectedIndexEdit] = useState('');
    const [alert1, setAlert1] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [alert3, setAlert3] = useState(false);
    const [alert4, setAlert4] = useState(false);
    var [usuariosId_Usuario, setUserId_Fornecedor] = useState('');
    var [usuariosNome, setUserNome] = useState('');
    var [usuariosEmail, setUserEmail] = useState('');
    var [usuariosIE, setUserIE] = useState('');
    var [usuariosIM, setUserIM] = useState('');
    var [usuariosCEP, setUserCEP] = useState('');
    var [usuariosUF, setUserUF] = useState('');
    var [usuariosNum, setUserNum] = useState('');
    var [usuariosTelefone1, setUserTelefone1] = useState('');
    var [usuariosTelefone2, setUserTelefone2] = useState('');
    var [usuariosBairro, setUserBairro] = useState('');
    var [usuariosCidade, setUserCidade] = useState('');
    var [usuariosRua, setUserRua] = useState('');
    var [usuariosComplemento, setUserComplemento] = useState('');
    var [usuariosCNPJ, setUserCNPJ] = useState('');
    var [usuariosRS, setUserRS] = useState('');
    var [usuariosPassword, setUserPassword] = useState('');
    var [usuariosPasswordConf, setUserPasswordConf] = useState('');
    var [usuariosData, setUserData] = useState('');
    var [usuariosId_Usuario, setUserId_Usuario] = useState('');

    const [filtred, setFiltred] = useState([]);
    const [search, setSearch] = useState("");

    const notify = (txt) => toast.success(txt, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

        const erro = (txt) => toast.error(txt, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });


    
    const history = useHistory();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    async function handlerDelete(id){
        api.post("api/deleteUsuario",{Id_Usuario: id}).then(function(response) {
            //setAlert1(true);
            setUsuarios(response.data);
            setFiltred(response.data);
            setSearch('');
            notify("Desativado com sucesso!");

        });
        
    }

    function handlerAtivar(id){
        api.post("api/reativarUser",{Id_Usuario: id}).then(function(response) {
            //setAlert1(true);
            setUsuarios(response.data);
            setFiltred(response.data);
            setSearch('');
            notify("Ativado com sucesso!");

        });
        
    }


    async function handlerEdit(usuario,index){
        document.getElementById("SENHA"+usuario.Id_Usuario).style.borderColor='';
        document.getElementById("CONF"+usuario.Id_Usuario).style.borderColor='';
        if(usuariosPassword != ''){
            if(usuariosPassword != usuariosPasswordConf){
                document.getElementById("SENHA"+usuario.Id_Usuario).style.borderColor='red';
                document.getElementById("CONF"+usuario.Id_Usuario).style.borderColor='red';
                erro("Senhas não conferem.");
                

                return false;
            }

        }

        if(usuariosPassword != '' && usuariosPassword.length < 6){
            document.getElementById("SENHA"+usuario.Id_Usuario).style.borderColor='red';
            erro("Senha deve ser igual ou superior a 6 dígitos.");
            return false;
        }

        var Id_Usuario = usuario.Id_Usuario;
        if(usuariosNome==''){
            usuariosNome = usuario.Nome;
        }
        if(usuariosEmail==''){
            usuariosEmail = usuario.email;
        }
        if(usuariosIE==''){
            usuariosIE = usuario.IE;
        }
        if(usuariosIM==''){
            usuariosIM = usuario.IM;
        }
        if(usuariosTelefone1==''){
            usuariosTelefone1 = usuario.Telefone1;
        }
        if(usuariosTelefone2==''){
            usuariosTelefone2 = usuario.Telefone2;
        }
        if(usuariosCEP==''){
            usuariosCEP = usuario.CEP;
        }
        if(usuariosUF===''){
            usuariosUF = usuario.UF;
        }
        if(usuariosCidade===''){
            usuariosCidade = usuario.Cidade;
        }
        if(usuariosBairro===''){
            usuariosBairro = usuario.Bairro;
        }
        if(usuariosRua===''){
            usuariosRua = usuario.Rua;
        }
        if(usuariosNum===''){
            usuariosNum = usuario.Num;
        }
        if(usuariosComplemento===''){
            usuariosComplemento = usuario.Complemento;
        }
        if(usuariosCNPJ===''){
            usuariosCNPJ = usuario.CNPJ;
        }
        if(usuariosRS===''){
            usuariosRS = usuario.RS;
        }
        try{
            const data = {
                codPerfil : usuario.CodPerfil,
                userName : usuariosNome,
                userEmail: usuariosEmail,
                userRS : usuariosRS,
                userIE : usuariosIE,
                userIM : usuariosIM,
                userCEP : usuariosCEP,
                userUF : usuariosUF,
                userNum : usuariosNum,
                userPassword : usuariosPassword,
                userTelefone1 : usuariosTelefone1,
                userTelefone2 : usuariosTelefone2,
                userBairro : usuariosBairro,
                userCidade : usuariosCidade,
                userRua : usuariosRua,
                userComplemento : usuariosComplemento,
                userCNPJ : usuariosCNPJ,
                userId_Usuario : Id_Usuario
            };
            console.log(data);
                api.post("api/editarUsuarios",data).then(function(response){
                    setUsuarios(response.data);
                    setFiltred(response.data);
                    setSearch('');  
                //setAlert4(true);
                notify("Editado com sucesso!");
                 });


        }catch(e){
            console.log(e);
        }
    }

        useEffect(() => {
            try{
                api.post("api/allUsuarios")
                .then(function(response) {
                setUsuarios(response.data);
                    api.post("api/indexPerfil")
                    .then(function(response) {
                        setPerfis(response.data);
                        
                        setLoading(false);
                    });
                });
                
                }catch(error){
                    console.log(error);
                }

            }, []);

            useEffect(() => {
                setFiltred(
                  usuarios.filter((user) =>
                  user.Nome.toLowerCase().includes(search.toLowerCase())
                  )
                );
              }, [search, usuarios]);
            console.log(usuarios);

            if(loading == false){
    return (
       
        <Container>
            <h1 className="il">Q</h1>
            <Link to="/dashboard">
                <Button className="ir">
                        Voltar
                </Button>
            </Link>
            {alert1  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert1(false)}>Excluído com sucesso.</Alert>}
            {alert2  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert2(false)}>Criado com sucesso.</Alert>}
            {alert4  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert4(false)}>Atualizado com sucesso.</Alert>}
            <ToastContainer autoClose={4000} />
        
            <Row className="justify-content-md-center">
                <Col xs={12} md={8}>
                <Row>
                            <Col xs={12} md={6}><h2 className="ml-2">Usuários</h2></Col> 
                            <h2>Filtro:</h2><Col className="mb-2" xs={12} md={4}><Form.Control onChange={(e) => setSearch(e.target.value)}></Form.Control></Col>
                        
                        </Row>
                    <Card className="card_items" >
                        <Card.Body  >
                        <Accordion defaultActiveKey="0">
                            {filtred.map((usuario,index)=>

                            (usuario.Id_Usuario == localStorage.getItem('Id_Usuario')) ? '' :  

                                <Row className={(usuario.Ativado == 1) ? 'itemAtivo' : 'itemDesativo'}>
                                    <Col xs={12} md={12}>
                                        <Accordion.Toggle  as={Card.Header} className="mt-2" variant="link" eventKey={usuario.Id_Usuario}><Row><Col xs={8} md={10}>{usuario.Nome}</Col><Col xs={2} md={2}><div className="mg-top"></div></Col></Row></Accordion.Toggle>

                                        <Accordion.Collapse eventKey={usuario.Id_Usuario}>
                                            <Col xs={12} md={12}>
                                                <Row className="justify-content-md-center ml-2 mr-2 mt-2">
                                                    <Col  xs={12} md={6}>
                                                        <Form.Label>Nome</Form.Label>
                                                        <Form.Control size="sm" type="text" placeholder={usuario.Nome} onChange={e => setUserNome(e.target.value)} />
                                                    </Col>
                                                    <Col  xs={12} md={6}>
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control size="sm" type="email" placeholder={usuario.email} onChange={e => setUserEmail(e.target.value)} />
                                                    </Col>
                                                    

                                                </Row>
                                                <Row className="justify-content-md-center ml-2 mr-2 mt-2">

                                                    <Col  xs={12} md={4}>
                                                            <Form.Label>Razão Social</Form.Label>
                                                            <Form.Control size="sm" type="text" placeholder={usuario.RS} onChange={e => setUserRS(e.target.value)} />
                                                        </Col>

                                                        <Col  xs={12} md={4}>
                                                            <Form.Label>Senha</Form.Label>
                                                            <Form.Control type="password" id={"SENHA"+usuario.Id_Usuario} placeholder="Insira Nova Senha"  onChange={(e) => (setUserPassword(e.target.value),usuario.password = e.target.value)} />
                                                        </Col>

                                                        <Col  xs={12} md={4}>
                                                            <Form.Label>Confirmar Senha</Form.Label>
                                                            <Form.Control type="password" id={"CONF"+usuario.Id_Usuario}  placeholder="Confirma Nova Senha" onChange={(e) => (setUserPasswordConf(e.target.value))} />
                                                        </Col>
                                                </Row>

                                                <Row className="justify-content-md-center ml-2 mr-2 mt-2">
                                                    <Col  xs={12} md={4}>
                                                        <Form.Label>IE</Form.Label>
                                                        <Form.Control size="sm" type="text" placeholder={usuario.IE} onChange={e => setUserIE(e.target.value)} />
                                                    </Col>
                                                    <Col  xs={12} md={4}>
                                                        <Form.Label>IM</Form.Label>
                                                        <Form.Control size="sm" type="text" placeholder={usuario.IM} onChange={e => setUserIM(e.target.value)} />
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Label>Perfis</Form.Label>
                                                        <select className="form-control form-control-md" value={usuario.perfil.CodPerfil} onChange={(e) => usuario.CodPerfil = e.target.value}>
                                                            
                                                            {perfis.map((perfil, index) =>
                                                                <option value={perfil.CodPerfil}>{perfil.Descricao}</option>
                                                            )}
                                                        </select>
                                                    </Col>
                                                </Row >
                                                <Row className="justify-content-md-center ml-2 mr-2 mt-2">
                                                    <Col  xs={12} md={10}>
                                                        <Form.Label>Rua</Form.Label>
                                                        <Form.Control size="sm" type="text" placeholder={usuario.Rua} onChange={e => setUserRua(e.target.value)} />
                                                    </Col>
                                                    <Col  xs={12} md={2}>
                                                        <Form.Label>Número</Form.Label>
                                                        <Form.Control size="sm" type="text" placeholder={usuario.Num} onChange={e => setUserNum(e.target.value)} />
                                                    </Col>
                                                </Row>
                                                <Row className="justify-content-md-center ml-2 mr-2 mt-2 mb-2">
                                                    <Col  xs={12} md={6}>
                                                        <Form.Label>Telefone 1</Form.Label>
                                                        <Form.Control size="sm" type="text" placeholder={usuario.Telefone1} onChange={e => setUserTelefone1(e.target.value)} />
                                                    </Col>
                                                    <Col  xs={12} md={6}>
                                                        <Form.Label>Telefone 2</Form.Label>
                                                        <Form.Control size="sm" type="text" placeholder={usuario.Telefone2} onChange={e => setUserTelefone2(e.target.value)} />
                                                    </Col>
                                                </Row>
                                                <Row className="justify-content-md-center ml-2 mr-2 mt-2 mb-2">
                                                    <Col  xs={12} md={6}>
                                                        <Form.Label>Estado</Form.Label>
                                                        <Form.Control size="sm" type="text" placeholder={usuario.UF} onChange={e => setUserUF(e.target.value)} />
                                                    </Col>
                                                    <Col  xs={12} md={6}>
                                                        <Form.Label>Bairro</Form.Label>
                                                        <Form.Control size="sm" type="text" placeholder={usuario.Bairro} onChange={e => setUserBairro(e.target.value)} />
                                                    </Col>
                                                </Row>
                                                <Row className="justify-content-md-center ml-2 mr-2 mt-2 mb-2">
                                                    <Col  xs={12} md={6}>
                                                        <Form.Label>Complemento</Form.Label>
                                                        <Form.Control size="sm" type="text" placeholder={usuario.Complemento} onChange={e => setUserComplemento(e.target.value)} />
                                                    </Col>
                                                    <Col  xs={12} md={6}>
                                                        <Form.Label>CPF/CNPJ</Form.Label>
                                                        <Form.Control size="sm" type="text" placeholder={usuario.CNPJ} onChange={e => setUserCNPJ(e.target.value)} />
                                                    </Col>
                                                </Row>
                                                <Row className="justify-content-md-center ml-2 mr-2 mt-2 mb-2">
                                                    <Col  xs={12} md={6}>
                                                        <Form.Label>Cidade</Form.Label>
                                                        <Form.Control size="sm" type="text" placeholder={usuario.Cidade} onChange={e => setUserCidade(e.target.value)} />
                                                    </Col>
                                                    <Col  xs={12} md={6}>
                                                        <Form.Label>CEP</Form.Label>
                                                        <Form.Control size="sm" type="text" placeholder={usuario.CEP} onChange={e => setUserCEP(e.target.value)} />
                                                    </Col>
                                                </Row>
                                                <Button className="ml-3 mb-2" variant="primary"  onClick={() => handlerEdit(usuario)}>
                                                    Confirmar
                                                </Button>
                                                {(usuario.Ativado == 1) ?
                                                  <Button className="ml-3 mb-2" variant="danger"  onClick={() => handlerDelete(usuario.Id_Usuario)}>
                                                Desativar
                                                </Button>
                                                 :          
                                                <Button className="ml-3 mb-2" variant="success"  onClick={() => handlerAtivar(usuario.Id_Usuario)}>
                                                Ativar
                                                </Button>
                                                }
                                            </Col>
                                        </Accordion.Collapse>

                                    </Col>
                                    
                                </Row>
                            )}
                            </Accordion>
                           
                        </Card.Body>
                        
                    </Card>
                </Col>
            </Row>
            
        </Container>
    
    );
    }else{
        return (
            <Row className="justify-content-center">
        <Spinner animation="border" className="central" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
        </Row>
        );
    }
}