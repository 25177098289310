import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Card, Row , Col, Form, Modal, Accordion, Spinner, ListGroup, Alert} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import api from '../../../services/api';

import Plus from '../../../images/add.svg';
import Trash from '../../../images/trash.svg';
import Eye from '../../../images/olho.svg';
import Delete from '../../../images/close.svg';

import { Collapse } from '@material-ui/core';

export default function Material(){
    const [loading, setLoading] = useState(true);
    const [residuos, setResiduos] = useState([]);
    const [alert1, setAlert1] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [quantidadePDF, setQuantidadePDF] = useState(false);

    const [filtred, setFiltred] = useState([]);
    const [search, setSearch] = useState("");   

    const history = useHistory();
    var [custoTotal, setCustoTotal] = useState('');

    async function handlerGerar(id){
        var url = "http://api.quans.projetoscomputacao.com.br/";
        var Id_Usuario = localStorage.getItem('Id_Usuario');
        var Quantidade = quantidadePDF;
        var Id_Material_Classificado = filtred[id].Id_Material_Classificado;
        var data = {
            Quantidade,Id_Material_Classificado,Id_Usuario
        };
        console.log(data);
        api.post("api/storePDFEtiqueta",data).then(function(response){
            window.open(url+'pdfStore/etiqueta.pdf','_blank');
        });
    }


    useEffect(() => {
        api.post("api/classificado")
        .then(function(response) {
            setResiduos(response.data);
            console.log(response.data);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setFiltred(
          residuos.filter((residuo) =>
          residuo.Nome.toLowerCase().includes(search.toLowerCase())
          )
        );
      }, [search, residuos]);


    /*produtos_n_acabados.forEach(element => {
        element['CustoTotal'] = 0;
        element.processo.forEach(pcusto=> {
            element['CustoTotal'] += pcusto.Custo;
        });
        element['CustoTotal'] += element.produto_inacabado.Custo;
    });*/

if(loading == false){
    return (
       
        <Container>
            <h1 className="il">Q</h1>
            <Link to="/quansquisobra/">
                <Button className="ir">
                        Voltar
                </Button>
            </Link>
            
            <Row className="justify-content-md-center">
            {alert1  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert1(false)}>Excluído com sucesso.</Alert>}
            {alert2  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert2(false)}>Produzido com sucesso.</Alert>}
            
                <Col xs={12} md={9}>
                    <Row>
                        <Col xs={12} md={6}><h2 className="ml-2">Classificado</h2></Col> 
                        <h2>Filtro:</h2><Col className="mb-2" xs={12} md={4}><Form.Control value={search} onChange={(e) => setSearch(e.target.value)}></Form.Control></Col>
                    </Row>
                    <Card className="card_items">
                        <Card.Body>
                            <Row><Col md={4}><h2>Nome</h2></Col></Row>
                        <Accordion defaultActiveKey="0">
                            {filtred.map((resultados,indexM)=> 
                                <Row className="item_tipo  mt-2 mb-2">
                                    <Col xs={12} md={12}>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey={resultados}> 
                            <Row>
                                <Col xs={4} md={7}><Form.Label>{resultados.Nome}</Form.Label></Col>
                                <Col xs={4} md={2}><Form.Control size="md" placeholder="Qtd" type="text" onChange={(e) => setQuantidadePDF(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/></Col>
                                <Col xs={2} md={3}><Button variant="primary" className="text-center" onClick={()=> handlerGerar(indexM)}>Gerar Etiqueta</Button></Col>
                              </Row></Accordion.Toggle>
                                        <Accordion.Collapse eventKey={resultados}>
                                            <Col>
                                                <Row className="justify-content-md-center mt-2">
                                                    <Col>
                                                        <Form.Label>Nome</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={resultados.Nome} disabled/>
                                                    </Col>
                                                </Row>
                                               
                                   
                                                <Row className="justify-content-md-center mt-2 mb-2">
                                            </Row>
                
                                            </Col>
                                        </Accordion.Collapse>
                                    </Col>

                                </Row>
                            )}
                            </Accordion>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
    }else{
        return (
            <Row className="justify-content-center">
        <Spinner animation="border" className="central" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
        </Row>
        );
    }
}