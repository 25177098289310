import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';
import { FiLogIn } from 'react-icons/fi';
import {Form, Button, Image,Container,Row, Col, Alert, Modal, FormLabel, Spinner} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import Key from '../../images/key.svg';
import User from '../../images/user.svg';

import './styles.css';
import { useEffect } from 'react';

export default  function Login(){

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState(false);
    const [emailRecovery, setEmailRecovery] = useState('');
    const [alert1, setAlert1] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [alert3, setAlert3] = useState(false);
    const [alert4, setAlert4] = useState(false);
    const [show, setShow] = useState(false);
    const history = useHistory();

    console.log(localStorage.getItem('token'));

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const notify = (txt) => toast.success(txt, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

        const erro = (txt) => toast.error(txt, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });


    async function handleLogin(e) {
      e.preventDefault();
      try {
        const response = await api.post('api/auth/login', { email, password });
        console.log(response.data);
        localStorage.setItem('Id_Usuario',response.data[1].Id_Usuario);
        localStorage.setItem('Nome',response.data[1].Nome);
        localStorage.setItem('Email',response.data[1].email);
        localStorage.setItem('UserJaLogado',response.data[1].Id_Usuario);
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem('LoginInicial', 1);

        if(response.data[1].PrimeiroAcesso == 'N'){
            history.push('/dashboard');
        }else if(response.data[1].PrimeiroAcesso == 'S'){
            history.push('/primeiroAcesso');  
        }else{
            erro("Verifique o email e a senha");
        }
        
      } catch (err) {
        erro("Verifique o email e a senha");
      }
    }

    function handleRecovery(){
        document.getElementById('RECOVERY').style.borderColor = "";
        if(emailRecovery == ''){
            document.getElementById('RECOVERY').style.borderColor = "red";
        }else{
            var data = {
                To : emailRecovery
            }
            console.log(data);
                api.post("api/recoverySenha",data).then(function(response) {
                    handleClose();
                    notify("Nova senha enviada!");
                }).catch(error =>{
                    erro("E-mail informado não registrado.");

                });
            
        }
        
    }

    useEffect((e) => {

        
        if(localStorage.getItem("deslogado")== 1){
            setAlert2(true);
            notify("Deslogado com sucesso!");
            localStorage.setItem("deslogado",0);
           
        }
        setLoading(true);


        }, []);

        if(loading == true){
            return (

       <Container className="text-center">
        <ToastContainer autoClose={4000} />
        {alert3  && <Alert variant="success" dismissible onClose={() => setAlert3(false)}>Nova senha enviada!</Alert>}
           <Row className="justify-content-md-center">
            <Col md="auto">
                    <Form  onSubmit={handleLogin} >
                        <div className="top-page">
                            <h1>Q</h1>
                            <h2>Quans</h2>
                        </div>
                            <Col xs={12} md={12}>{alert1 && <Alert variant="danger" dismissible onClose={() => setAlert1(false)}>Verifique o email e a senha.</Alert>}</Col>
                            <Row>
                                <Col xs={2} md={2}>
                                    <Image className="img-form" src={User}/>
                                </Col>
                                <Col xs={10} md={10}>
                                    <Form.Control type="email" placeholder="Seu e-mail"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={2} md={2}>
                                    <Image className="img-form" src={Key}/>
                                </Col>
                                <Col xs={10} md={10}>
                                
                                        <Form.Control type="password" placeholder=""
                                            onChange={e => setPassword(e.target.value)} />
                                </Col>
                            </Row>
                            <div className="bt">
                                <Button variant="primary" type="submit">
                                    Entrar
                                </Button>
                            </div>

                    </Form>
                </Col>
            </Row>
            <Row className="justify-content-md-center mt-5">
                <Form.Label onClick={()=>setShow(true)}><h6>Esqueci minha senha?</h6></Form.Label>
            </Row>
            <Row className="justify-content-md-center mt-3">
                <div>
                    <Link className="back-link" to="/register">
                        <FiLogIn size={16} color="#3498db" />
                            Não tenho cadastro
                    </Link>
                </div>
            </Row>
            <Modal show={show} onHide={handleClose}>
            {alert4  && <Alert variant="danger" dismissible onClose={() =>setAlert4(false)}>E-mail informado não registrado.</Alert>}
                <Modal.Header closeButton>
                    <Modal.Title>Recuperação de Senha</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control 
                        type="email"
                        id="RECOVERY" 
                        placeholder="Digite seu email"
                        onChange={e => setEmailRecovery(e.target.value)}/>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Fechar
                </Button>
                <Button variant="primary" onClick={handleRecovery}>
                    Enviar
                </Button>
                </Modal.Footer>
        </Modal>
        </Container>

        

    );
}else{
    return (
        
        <Row className="justify-content-center">
            
    <Spinner animation="border" className="central" role="status">
        <span className="sr-only">Loading...</span>
    </Spinner>
    </Row>
    );
}
}

