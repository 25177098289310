import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Card, Row , Col, Alert, Modal, Spinner, Form, Accordion} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';

import Plus from '../../images/add.svg';
import Trash from '../../images/trash.svg';
import Check from '../../images/check.png';
import Delete from '../../images/close.svg';
import './styles.css';
import NumberFormat from 'react-number-format';
import { toast, ToastContainer } from 'react-toastify';





export default function Processos(){

    const notify = (txt) => toast.success(txt, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

        
        const [idDelete, setIdDelete] = useState('');

    const [loading, setLoading] = useState(true);
    const [processos, setProcessos] = useState([]);
    const [show, setShow] = useState(false);
    var [nomeProcesso, setNomeProcesso]= useState('');
    var [custo, setCusto]= useState('nulo');
    const [alert1, setAlert1] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [alert3, setAlert3] = useState(false);
    const [alert4, setAlert4] = useState(false);
    const [alert5, setAlert5] = useState(false);
    const [createNome, setCreateNome]= useState('');
    const [createCusto, setCreateCusto]= useState('');
    const [filtredProcessos, setFiltredProcessos] = useState([]);

    const [search, setSearch] = useState("");
    const history = useHistory();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    async function handlerDelete(id){
        document.getElementById('modalDel').style.width = '0px';
        document.getElementById('modalDel').style.display = 'none';

        api.post("api/deleteProcesso",{Id_Processo_Produto: id}).then(function(response) {
            api.post("api/indexProcesso")
                .then(function(response) {
                    setProcessos(response.data);
                    notify("Excluido com sucesso.");

                });
        });
        
    }

    async function handlerEdit(processo){
        if(nomeProcesso == ''){nomeProcesso = processo.Nome;}
        if(custo == ''){custo = processo.Custo.substring(2).replace(',','.');}
        var id = processo.Id_Processo_Produto;
        
        var data = {
            id,nomeProcesso, custo
        }
        
            api.post('api/editProcessos', data)
            .then(async res => {
                api.post("api/indexProcesso")
                .then(function(response) {
                    setProcessos(response.data);
                    notify("Atualizado com sucesso.");

                });
            });
       
        
    }

    function handlerOpenDelete(id){
        setIdDelete(id);
        document.getElementById('modalDel').style.width = '17rem';
        document.getElementById('modalDel').style.display = 'inline-block';

    }

    function handlerCancelDelete(id){
        setIdDelete('');
        document.getElementById('modalDel').style.width = '0px';
        document.getElementById('modalDel').style.display = 'none';
    }

    function numberToReal(numero) {
        var numero = numero.toFixed(2).split('.');
        numero[0] = "R$ " + numero[0].split(/(?=(?:...)*$)/).join('.');
        return numero.join(',');
    }

    async function handleCreate(e){
        document.getElementById("NOME").style.borderColor = "";
        const Id_Usuario = localStorage.getItem('Id_Usuario');
        const Nome = createNome;
        const Custo = createCusto.substring(2).replace(',','.');
        if(!createNome == ''){
            const data = {
                Nome,Custo
                
            };
            try {
                api.post('api/createProcesso', data)
                .then(async res => {
                    api.post("api/indexProcesso")
                    .then(function(response) {
                        setProcessos(response.data);
                        notify("Adicionado com sucesso.");
                        handleClose();
                    });
                });
            } catch (err) {
            alert('Erro no cadastro, tente novamente.');
            }
        }else{
            document.getElementById("NOME").style.borderColor = "red";
            setAlert3(true);
        }
    }

    function reset(){
        api.post("api/indexProcesso")
        .then(function(response) {
            setProcessos(response.data);
        });
    }
    

     useEffect(() => {
                api.post("api/indexProcesso")
                .then(function(response) {
                    setFiltredProcessos(response.data);
                    setProcessos(response.data);
                    setLoading(false);
                });
               
            }, []);

            

            useEffect(() => {
                setFiltredProcessos(
                  processos.filter((processo) =>
                  processo.Nome.toLowerCase().includes(search.toLowerCase())
                  )
                );
              }, [search, processos]);


        
        if(loading == false){
            return (
                
                <Container>
            <h1 className="il">Q</h1>
            <Link to="/dashboard">
                <Button className="ir">
                        Voltar
                </Button>
            </Link>
            <div id="modalDel" className="confirmarModal">
                    <h3>Confirmar Exclusão</h3>
                    <Row className="justify-content-md-center mt-4">
                        <img className="checks mr-2" src={Check} onClick={() => handlerDelete(idDelete)}/>
                        <img className="checks neg mr-2" src={Delete} onClick={() => handlerCancelDelete()}/>
                    </Row>
            </div>
            <ToastContainer autoClose={4000} />
            {alert1  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert1(false)}>Excluído com sucesso.</Alert>}
            {alert2  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert2(false)}>Adicionado com sucesso.</Alert>}
            {alert4  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert4(false)}>Atualizado com sucesso.</Alert>}
            {alert5  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert5(false)}>Saída cadastrada com sucesso.</Alert>}
                    <Row className="justify-content-md-center">
                        <Col xs={12} md={6}>
                        <Row>
                            <Col xs={12} md={6}><h2 className="ml-2">Processos</h2></Col> 
                            <h2>Filtro:</h2><Col className="mb-2" xs={12} md={4}><Form.Control onChange={(e) => setSearch(e.target.value)}></Form.Control></Col>
                        
                        </Row>
                            <Card className="card_items">
                                <Card.Body>
                                <Accordion defaultActiveKey="0">
                                {filtredProcessos.map((processo)=>
                                <Row className="item_tipo">
                                    <Col xs={12} md={12}>
                                        <Accordion.Toggle as={Card.Header} variant="link" eventKey={processo.Id_Processo_Produto} onClick={()=> reset()}> <Row><Col xs={5} md={6}>{processo.Nome} </Col><Col xs={5} md={4}><NumberFormat decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={processo.Custo} displayType={'text'}  prefix={'R$'} /></Col> <Col xs={1} md={1}>
                                        {(processo.Usado == 1)? '' :
                                        <div className="mg-top">
                                            <button className="ml-2" onClick={() => handlerOpenDelete(processo.Id_Processo_Produto)}><img className="img" src={Trash}></img></button>
                                        </div>
                                        }
                                    </Col></Row></Accordion.Toggle>
                                        
                                        <Accordion.Collapse eventKey={processo.Id_Processo_Produto}>
                                            <Col xs={12}>
                                                <Row className="justify-content-md-center mb-3 mt-2">
                                                    <Col>
                                                        <Form.Label>Processo</Form.Label>
                                                        <Form.Control maxLength="50" size="md" type="text" placeholder={processo.Nome} onChange={e => setNomeProcesso(e.target.value)} />
                                                    </Col>

                                                    <Col>
                                                        <Col>
                                                            <Form.Label>Custo</Form.Label>
                                                            <NumberFormat allowLeadingZeros={true} prefix={'R$'}  allowNegative={false} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={processo.Custo} className="form-control form-control-md" inputmode="numeric" onChange={(e) => (setCusto(e.target.value),(processo.Custo = e.target.value))} />
                                                        </Col>
                                                    </Col>
                                                </Row>
                                                {(processo.Usado == 1) ? '' :
                                                <Button className="ml-3 mb-2" variant="primary" onClick={(e) => handlerEdit(processo)} >
                                                    Confirmar
                                                </Button>
                                                }
                                            </Col>
                                        </Accordion.Collapse>

                                    </Col>
                                   
                                </Row>
                            )}
                            </Accordion>
                                    <Col>
                                        <div className="item_tipo text-center">
                                        <p>Adicionar <button className="btn btn-link" onClick={handleShow}> <img src={Plus} className="img"/></button></p>
                                                <Modal show={show} onHide={handleClose}>
                                                    {alert3  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert3(false)}>Preencha o nome corretamente.</Alert>}
                                                    <Modal.Header closeButton>
                                                    <Modal.Title>Novo processo</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Row className="justify-content-md-center  mt-2">
                                                            <Col xs={6}>
                                                                <Form.Control id="NOME" maxLength="50" size="md" type="text" placeholder="Digite o nome" onChange={e => setCreateNome(e.target.value)} />
                                                            </Col>
                                                            <Col xs={6}>
                                                                <NumberFormat prefix={'R$'} allowNegative={false} allowLeadingZeros={true} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} placeholder="Digite o custo" className="form-control form-control-md" inputmode="numeric" onChange={e => setCreateCusto(e.target.value)} />
                                                            </Col>
                                                        </Row>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                    <Button variant="secondary" onClick={handleClose}>
                                                        Fechar
                                                    </Button>
                                                    <Button variant="primary" onClick={handleCreate}>
                                                        Adicionar
                                                    </Button>
                                                    </Modal.Footer>
                                            </Modal>
                                        </div>
                                    </Col>
                                </Card.Body>
                                
                            </Card>
                        </Col>
                    </Row>
                    
                </Container>
            );
        }else{
            return (
            <Row className="justify-content-center">
                <Spinner animation="border" className="central" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </Row>
            );
        } 
}