import api from '../../../services/api';
import React, { useEffect, useState } from 'react';
import { Button, Form , Col, Row, Container, Card, Spinner, Alert} from 'react-bootstrap';
import './styles.css';
import { Link, useHistory } from 'react-router-dom';
import Trash from '../../../images/trash.svg';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import { toast, ToastContainer } from 'react-toastify';

export default  function Entrada(){
        //console.log(localStorage.getItem('token'));
        const [loading, setLoading] = useState(true);
        const [produtos, setProdutos] = useState('');
        const [processos, setProcessos] = useState('');
        const [selected, setSelected] = useState('');
        const [Chave_Acesso, setChave_Acesso] = useState('');
        const [Valor_Total, setValor_Total] = useState(0);
        const [Data_Entrada, setData_Entrada] = useState('');
        const [Preco, setPreco] = useState('');
        const [Quantidade, setQuantidade] = useState('');
        const [CodProduto, setCodProduto] = useState('');
        const [Id_Fornecedor, setId_Fornecedor] = useState('');
        const [screen, setScreen] = useState(false);
        const [allProcessos, setAllProcessos] = useState([]);
        var [selectedsIndex, setSelectedsIndex] = useState([]);
        const [alert1, setAlert1] = useState(false);
        const [alert2, setAlert2] = useState(false);
        const [alert3, setAlert3] = useState(false);
        const [alert4, setAlert4] = useState(false);
        const [alert5, setAlert5] = useState(false);
        const [alert6, setAlert6] = useState(false);
        const [alert7, setAlert7    ] = useState(false);
        const [qtd, setQtd] = useState('');
        const [nomeMateria, setNomeMateria] = useState('');
        const [unit, setUnit] = useState('');

        const notify = (txt) => toast.success(txt, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });

            const erro = (txt) => toast.error(txt, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });



        async function handlerCreate(){
            const Id_Usuario = localStorage.getItem('Id_Usuario');
            var Id_produto_n_acabado = produtos[selected].Id_Produto_n_acabado;
            console.log(Id_produto_n_acabado);
                        

                        if(Quantidade != ''){
                            document.getElementById("QUANTIDADE").style.borderColor = "";
                            var selecteds = allProcessos;
                            var data ={
                                Id_Usuario, Quantidade, Id_produto_n_acabado,selecteds
                            }
                            //console.log(data);
                            api.post('api/createProdutos_Acabados', data)
                            .then(async res => {
                                
                               // console.log(res.data);
                                if(res.data[1] == "Error"){
                                    erro("Faltam unidades em estoque de "+res.data[0].Nome);
                                    setQtd(res.data[2]);
                                    setNomeMateria(res.data[0].Nome);
                                    setUnit(res.data[0].Unidade);
                                }else{
                                    setAlert6(false);
                                    if(res.data[1] == "ErroEstoque"){
                                        erro("A quantidade a ser debitada de matéria-prima fará o estoque ir abaixo do estoque mínimo. Favor conferir ou dar entrada.");
                                    }else{
                                        var totalResiduo = (produtos[selected].materia_prima[0].pivot.Quantidade * Quantidade) - ((produtos[selected].materia_prima[0].pivot.Quantidade * Quantidade) * ((produtos[selected].materia_prima[0].pivot.Porcentagem)/100));
                                        console.log(totalResiduo);
                                        if(totalResiduo > 0){
                                            
                                            var data2 = {
                                                Quantidade: totalResiduo,
                                                CodProduto: produtos[selected].materia_prima[0].CodProduto

                                            }

                                            api.post('api/gerarResiduos', data2)
                                            .then(async res => {
                                                localStorage.setItem('Producao',1);
                                                history.push('/quansquie/estoque');
                                            }).catch(function() {alert("Erro na inclusão.");});
                                        }else{
                                            localStorage.setItem('Producao',1);
                                            history.push('/quansquie/estoque');
                                        }
                                    }
                                }

                            }).catch(function() {alert("Erro na inclusão.");});
                        }else{
                            document.getElementById("QUANTIDADE").style.borderColor = "red";
                            
                            erro("Preencha corretamente os campos");
                        }
                    
        }

        function todoEdit(){
            if(!processos[selectedsIndex]){
                setAllProcessos([...allProcessos,processos[0]]);
            }else{
                setAllProcessos([...allProcessos,processos[selectedsIndex]])
            }
        }  

        async function handlerTela(e){
            
            if(e != -1){
                setSelected(e);
                setCodProduto(produtos[e].CodProduto);
                setScreen(true);
            }else{
                setScreen(false);
            }
        }

        async function removeTodoAll(){
            setAllProcessos([]);
        }

        function removeTodo(index){
            const list = allProcessos;
            list.splice(index,1);
            setAllProcessos([...list]);
        }

        const history = useHistory();

        useEffect(() => {
            api.post("api/getProdutos_Inacabados")
            .then(function(response) {
                setProdutos(response.data);
                api.post("api/indexProcesso")
                .then(function(response) {

                    response.data.forEach(fcusto =>{
                        fcusto.Custo = parseFloat(fcusto.Custo).toFixed(2).toString().replace('.',',');
                    })

                    setProcessos(response.data);
                    setLoading(false);
                });
            });

        }, []);
        console.log(produtos);

if(loading == false){
    if(screen == false){
        return (
            
            
            <Container>
                <h1 className="il">Q</h1>
                <Link to="/quansquie/">
                    <Button className="ir">
                            Voltar
                    </Button>
                </Link>
                <Row className="justify-content-md-center mt-5 mb-1">
                            <Col md={6}>
                            <h2>Entrada</h2>
                                <Card className="card_items mt-5 mb-5">
                                    <Card.Body>
                                        <h2>Selecione a peça</h2>
                                        <select className="form-control form-control-md" onChange={(e) => handlerTela(e.target.value)}>
                                            <option value="-1">Selecione...</option>
                                            {produtos.map((produto, index) =>
                                                <option value={index}>{produto.Nome}</option>
                                            )}
                                        </select>
                                        
                                    </Card.Body>
                                    
                                </Card>
                            </Col>
                        </Row>
            </Container>
        );
    }else{
        return (
            !loading &&
            
            <Container>
                <h1 className="il">Q</h1>
                <Link to="/quansquie/">
                    <Button className="ir">
                            Voltar
                    </Button>
                </Link>
                {alert1  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert1(false)}>Excluído com sucesso.</Alert>}
                {alert2  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert2(false)}>Adicionado com sucesso.</Alert>}
                {alert3  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert3(false)}>Preencha corretamente os campos.</Alert>}
                {alert4  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert4(false)}>Atualizado com sucesso.</Alert>}
                {alert5  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert5(false)}>Saída cadastrada com sucesso.</Alert>}
                {alert6  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert6(false)}>Faltam {qtd} {unit} de(a) {nomeMateria}, favor dar entrada na matéria-prima.</Alert>}
                {alert7  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert7(false)}>A quantidade a ser debitada de matéria-prima fará o estoque ir abaixo do estoque mínimo. Favor conferir ou dar entrada.</Alert>}
            <ToastContainer autoClose={4000} />
                
                <Row className="justify-content-md-center mt-5 mb-1">
                            <Col xs={12} md={8}>
                            <h2>Produção</h2>
                                <Card className="card_items  mb-1">
                                    <Card.Body>
                                       <Row className="justify-content-md-center">
                                            <Col xs={12} md={12} className="mt-2">
                                                <Form.Label>Peça</Form.Label>
                                                <Form.Control value={produtos[selected].Nome} disabled />
                                            </Col>
                    

                                           <Col xs={12} md={6}>
                                                <Form.Label className="mt-2">Quantidade</Form.Label>
                                                <Form.Control id="QUANTIDADE"  placeholder="Digite a quantidade a ser produzida" onChange={(e) => setQuantidade(e.target.value)}/>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                    <Form.Label className="mt-2">Produzir</Form.Label>
                                                    <select className="form-control form-control-md" onChange={(e) => setSelectedsIndex(e.target.value)}>
                                                        {processos.map((processo, index) =>
                                                        
                                                            <option value={index}>{processo.Nome +' - '+'R$ '+processo.Custo}</option>
                                                        )}
                                                    </select>
                                            </Col>
                                            <Col xs={12} md={3} className="mt-2">
                                                    <Button variant="secondary" className="mt-4" onClick={()=> todoEdit(selected)}>Adicionar</Button>
                                            </Col>
                                       </Row>
                                       <Row className="justify-content-md-center mt-2 item_tipo">
                                            <Form.Label> <h4 className="ml-4 mt-2">Lista de processos</h4></Form.Label>
                                                <Container className="justify-content-sm-center">
                                                    <Row className="ml-3"><Col xs={4} md={6}><Form.Label><h4 className="">Nome</h4></Form.Label></Col><Col xs={4} md={4}><Form.Label><h4>Custo</h4></Form.Label></Col><Col xs={2} md={2}><img className="img_mini ml-2" src={Trash} onClick={()=> removeTodoAll()}/></Col></Row>
                                                    {allProcessos.map((selecteds) => (
                                                        <Row className="ml-2">
                                                            <Col xs={4} md={6}>
                                                                <Form.Label className="form-control form-control-gb">{selecteds.Nome}</Form.Label>
                                                            </Col>
                                                            <Col xs={4} md={4}>
                                                                <NumberFormat as={Form.Label}  prefix={'R$'} allowNegative={false} allowLeadingZeros={false} decimalSeparator={','} isNumericString={false} decimalScale={2} fixedDecimalScale={true} value={selecteds.Custo} className="form-control form-control-md" inputmode="decimal" readOnly={true} />
                                                            </Col>
                                                            <Row>
                                                                <Col xs={2} md={2}><img className="img_mini ml-4" src={Trash} onClick={() =>  removeTodo(selecteds)}/></Col>
                                                            </Row>

                                                        </Row>
                                                    ))}
                                                </Container>
                                            </Row>  
                                      
                                    </Card.Body>
                                   
                                    
                                </Card>
                                <Row className="justify-content-center mt-3">
                                    <Button variant="warning" className="text-center" onClick={()=> handlerCreate()}>Produzir</Button>
                                </Row>
                            </Col>
                        </Row>
            </Container>
        );
    }

}else{
    return (
        <Row className="justify-content-center">
    <Spinner animation="border" className="central" role="status">
        <span className="sr-only">Loading...</span>
    </Spinner>
    </Row>
    );
}
}

