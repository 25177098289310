import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';
import { FiLogIn } from 'react-icons/fi';
import {Navbar,Form,Card, CardGroup, Button, Container,Row, Col, Spinner, Image, Dropdown, DropdownButton,Alert,Accordion,AccordionCollapse} from 'react-bootstrap';
import ImageUploader from 'react-images-upload';
import Delete from '../../images/trash.svg';
import Lixeira from '../../images/trash.svg';
import Settings from '../../images/settings.svg';
import Home from '../../images/casa.svg';
import Lista from '../../images/lista.svg';
import Renda from '../../images/renda.svg';
import QR from '../../images/qr-code.svg';
import Insumo from '../../images/Insumo.svg'
import Materia from '../../images/materia-prima.svg';
import Moedas from '../../images/moedas.svg';
import Fornecedor from '../../images/fornecedor.svg';
import Product from '../../images/box.svg';
import Gear from '../../images/gear.svg';
import Feature from '../../images/feature.svg';
import Category from '../../images/category.svg';
import { ToastContainer, toast } from 'react-toastify';
import Arrow from '../../images/arrow.svg';
import Arrow2 from '../../images/arrow2.svg';
import 'react-toastify/dist/ReactToastify.css';

export default function Dashboard(){
    const [loading, setLoading] = useState(true);
    const [logoEmpresa, setLogoEmpresa] = useState('');
    const [empresa,setEmpresa] = useState('');
    const [item2,setItem2] = useState('');
    const [item3,setItem3] = useState('');
    const [Nome, setNome] = useState('')

    const [empresa1, setEmpresa1] = useState('');
    const [empresa2, setEmpresa2] = useState('');
    const [empresa3, setEmpresa3] = useState('');
    const [empresa4, setEmpresa4] = useState('');
    const [empresa5, setEmpresa5] = useState('');
    const [alert4,setAlert4] = useState(false);
    const [img, setImg] = useState('');
    const [alert1, setAlert1] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [saida,setSaida] = useState('');
    const [entrada,setEntrada] = useState('');
    const [producaoPeça,setProduçaoPeça] = useState('');
    const [producaoFinal,setProducaoFinal] = useState('');
    const [reaproveitamento,setReaproveitamento] = useState('');
    const [descarte,setDescarte] = useState('');

    
    const notify = (txt) => toast.success(txt, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

        const error = (txt) => toast.error(txt, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    const [filter, setFilter] = useState('');

    const history = useHistory();
    if(localStorage.getItem('token') === 'null'){
        history.push('/login');
    }

    /* Set the width of the side navigation to 250px */
function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("ARROW_SIDE").style.zIndex  = "0";
  }
  
  /* Set the width of the side navigation to 0 */
  function closeNav() {
    document.getElementById("ARROW_SIDE").style.zIndex  = "2";
    document.getElementById("mySidenav").style.width = "0";
  }


    useEffect(() => {

                if(localStorage.getItem('token') === 'null'){
                    history.push('/login');
                }




                var Id_Usuario = localStorage.getItem('Id_Usuario');
                setNome(localStorage.getItem('Nome'));
                //setAlert1(true);



                if(localStorage.getItem("Editar")== 1){
                    setAlert4(true);
                    notify("Atualizado com sucesso");
                    localStorage.setItem("Editar",0);
                   
                }
                var data = {
                    Id_Usuario
                }

                api.post("api/getAutenticado",data)
                .then(function(response) {
                    console.log(response.data);
                    if(response.data.perfil.CodPerfil == 1){
                        setItem2(<Dropdown.Item><Link variant="none" to="/EditEmpresa">Editar Empresa</Link></Dropdown.Item>);
                        setItem3(<Dropdown.Item><Link variant="none" to="/esteirausuarios">Esteira de Usuários</Link></Dropdown.Item>);
                        setEntrada(<Link to='/quansquie/entrada'>Entrada de Matéria-Prima</Link>);
                        setSaida(<Link to='/quansquie/saida'>Saída de Matéria-Prima</Link>);
                        setProduçaoPeça(<Link to='/quansquie/producao'>Produção de Peças</Link>);
                        setProducaoFinal(<Link to="/quansquie/producaoFinal">Produção de Produtos</Link>);
                        setDescarte(<Link to="/quansquisobra/descarte">Descarte</Link>);
                        //setReaproveitamento(<Link to="/quansquisobra/reaproveitamento">Reaproveitamento</Link>);
                    }else if( response.data.perfil.CodPerfil == 2){
                        setItem2('');
                        setItem3('');
                        setEntrada(<Link to='/quansquie/entrada'>Entrada de Matéria-Prima</Link>);
                        setSaida(<Link to='/quansquie/saida'>Saída de Matéria-Prima</Link>);
                        setProduçaoPeça(<Link to='/quansquie/producao'>Produção de Peças</Link>);
                        setProducaoFinal(<Link to="/quansquie/producaoFinal">Produção de Produtos</Link>);
                        setDescarte(<Link to="/quansquisobra/descarte">Descarte</Link>);
                        //setReaproveitamento(<Link to="/quansquisobra/reaproveitamento">Reaproveitamento</Link>);
                    }else{
                        setEntrada();
                        setSaida();
                        setProduçaoPeça();
                        setProducaoFinal();
                        setDescarte();
                        setReaproveitamento();
                    }

                    api.post("api/getEmpresa").then(function(response){
                        console.log(localStorage.getItem("SenhaAlterada"));
                        if(localStorage.getItem("SenhaAlterada")== 1){
                            setAlert4(true);
                            notify("Senha Alterada");
                            localStorage.setItem('SenhaAlterada',0);
        
                        }

                        if(localStorage.getItem("LoginInicial") == 1){
                            setAlert4(true);
                            localStorage.setItem("LoginInicial",0);
                            notify("Bem vindo, "+localStorage.getItem('Nome'));
                        }
                        setImg(<Image className="img_bot" src={Gear}/>)
                        setEmpresa(response.data);

                        var src = "http://api.quans.projetoscomputacao.com.br/myfiles/avatars/"+response.data.Logo;
                        setLogoEmpresa(<Image src={src} className="logo"></Image>);

                        setEmpresa1(<button className="img_bot filter" data-toggle="tooltip" data-placement="top" title="Quans" style={{delay:{'show':0}}}> <Link to="/dashboard" ><Image  src={Home}/></Link></button>);
                        console.log(empresa);
                        if(response.data.hasQuansquie == 's'){
                            setEmpresa2(<button className="img_bot"  data-toggle="tooltip" data-placement="top" title="Quansquié" style={{delay:{'show':0}}}> <Link to="/Quansquie" ><Image src={Moedas} /></Link></button>);
                        }else{
                            setEmpresa2(<span data-toggle="tooltip" data-placement="top" title="Quansquié"><button className="img_bot filter" disabled><Image src={Moedas} /></button></span>);
                        }
                
                        if(response.data.hasQuansquisobra == 's'){
                            setEmpresa3(<button  data-toggle="tooltip" data-placement="top" title="Quansquisobra" style={{delay:{'show':0}}}> <Link to="/quansquisobra" ><Image  className="img_bot" src={Lixeira} /></Link></button>);
                        }else{
                            setEmpresa3(<span data-toggle="tooltip" data-placement="top" title="Quansquisobra"><button  disabled><Image className="img_bot filter" src={Lixeira} /></button></span>);
                        }
                
                        if(response.data.hasQuansquirende == 's'){
                            setEmpresa4(<button className="img_bot" data-toggle="tooltip" data-placement="top" title="Quansquirende" style={{delay:{show:0}}}><Link to='/quansquirende'><Image src={Renda} /></Link></button>);
                        }else{
                            setEmpresa4(<span data-toggle="tooltip" data-placement="top" title="Quansquirende" ><button className="img_bot filter" disabled><Image src={Renda} /></button></span>);
                        }
                
                        if(response.data.hasQROV == 's'){
                            setEmpresa5(<button className="img_bot"  data-toggle="tooltip" data-placement="top" title="Qro-V" style={{delay:{show:0}}}><Image src={QR} onClick={() => handleQRV()} /></button>);
                            setLoading(false);
                            
                        }else{
                            setEmpresa5(<span data-toggle="tooltip" data-placement="top" title="Qro-V"><button className="img_bot filter" disabled><Image src={QR} /></button></span>);
                            setLoading(false); 
                        }
                    });
                    
                });
        }, []);
        //console.log(localStorage.getItem('token'));
        
        function handleQRV()
        {
            window.open('http://qrov.projetoscomputacao.com.br','_blank')
        }
    async function handleLogout(e) {
        try {
        localStorage.setItem('token',null)
        localStorage.setItem('deslogado',1)
        history.push('/login');
        } catch (err) {
        
          alert('Nao foi possível sair.');
          
        }
      }

        return (

       <Container fluid>

           <Row>


<div id="mySidenav" className="sidenav">
    <Row className="justify-content-md-center">
    {logoEmpresa}

    </Row>

<Accordion defaultActiveKey="0">
    <Accordion.Toggle as={Card.Header,Link} variant="link" eventKey="0"><h5>Quans</h5></Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
        <Container>
            <Link to='/dashboard'>Quans</Link>
            <Link to="/caracteristicas">Características</Link>
            <Link to='/categorias'>Categorias</Link>
            <Link to='/fornecedores'>Fornecedores</Link>
            <Link to='/insumos'>Insumos</Link>

            <Link to='/material'>Matéria-Prima</Link>
            <Link to="/produtos_n_acabados">Peças</Link>

            <Link to='/processos'>Processos</Link>
            <Link to='/tipos'>Tipos</Link>
        </Container>
    </Accordion.Collapse>

    <Accordion.Toggle  as={Card.Header,Link} variant="link" eventKey="1"><h5>Quansquie</h5></Accordion.Toggle>
    <Accordion.Collapse eventKey="1">
        <Container>
            <Link to='/Quansquie'>Quansquie</Link>
            {entrada}
            <Link to="/quansquie/estoque">Estoque de peças</Link>
            <Link to="/quansquie/produtosResultados">Estoque de Produtos</Link>
            <Link to='/quansquie/material'>Estoque Matéria-Prima</Link>
            {producaoPeça}
            {producaoFinal}
            {saida}
        </Container>
    </Accordion.Collapse>

    <Accordion.Toggle as={Card.Header,Link}  variant="link" eventKey="2"><h5>Quansquisobra</h5></Accordion.Toggle>
    <Accordion.Collapse eventKey="2">
        <Container>
            <Link to='/quansquisobra'>Quansquisobra</Link>
            <Link to="/quansquisobra/classificado">Classificados</Link>
            {descarte}
            <Link to="/quansquisobra/residuos">Resíduos</Link>
        
        </Container>
    </Accordion.Collapse>

    <Accordion.Toggle as={Card.Header,Link}  variant="link" eventKey="3"><h5>Quansquirende</h5></Accordion.Toggle>
    <Accordion.Collapse eventKey="3">
        <Container>
        <Link as={Card.Header} to='/Quansquirende'>Quansquirende</Link>
        </Container>
    </Accordion.Collapse>

    <Accordion.Toggle as={Card.Header,Link}  variant="link" eventKey="4"><h5>QRo-V</h5></Accordion.Toggle>
    <Accordion.Collapse eventKey="4">
        <Container>
        <Link as={Card.Header} to='/QRo-V'>QRo-V</Link>
        </Container>
    </Accordion.Collapse>
    



</Accordion>



                            

                </div>

     </Row>
</Container>

);

}

