import api from '../../services/api';
import React, { useEffect, useState } from 'react';
import { Button, Form , Col, Row, Container,Alert, Modal} from 'react-bootstrap';
import './styles.css';
import { Link, useHistory } from 'react-router-dom';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import InputMask from "react-input-mask";
import axios from 'axios';
import Settings from '../../images/settings.svg';
import Home from '../../images/casa.svg';
import Delete from '../../images/delete.svg';
import Lista from '../../images/lista.svg';
import Renda from '../../images/renda.svg';
import QR from '../../images/qr-code.svg';
import Insumo from '../../images/Insumo.svg'
import Materia from '../../images/materia-prima.svg';
import Moedas from '../../images/moedas.svg';
import Fornecedor from '../../images/fornecedor.svg';
import Product from '../../images/box.svg';
import Gear from '../../images/gear.svg';
import { toast, ToastContainer } from 'react-toastify';


export default  function EditUser(){
        //console.log(localStorage.getItem('token'));
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});
    const [alert4,setAlert4] = useState(false);
    const [alert5,setAlert5] = useState(false);
    const [alert6,setAlert6] = useState(false);

    const [alert3,setAlert3] = useState(false);
    const [alert1,setAlert1] = useState(false);
    var [userName, setUserName] = useState('');
    var [userId_Usuario, setUserId_Usuario] = useState('');
    var [userEmail, setUserEmail] = useState('');
    var [userRS, setUserRS] = useState('');
    var [userIE, setUserIE] = useState('');
    var [codPerfil, setCodPerfil] = useState('');
    var [userIM, setUserIM] = useState('');
    var [userCEP, setUserCEP] = useState('');
    var [userUF, setUserUF] = useState('');
    var [userNum, setUserNum] = useState('');
    var [userTelefone1, setUserTelefone1] = useState('');
    var [userTelefone2, setUserTelefone2] = useState('');
    var [userPassword, setUserPassword] = useState('');
    var [userNovaSenha, setUserNovaPassword] = useState('');
    var [userNovaSenhaConf, setUserNovaPasswordConf] = useState('');
    var [userBairro, setUserBairro] = useState('');
    var [userCidade, setUserCidade] = useState('');
    var [userRua, setUserRua] = useState('');
    var [userComplemento, setUserComplemento] = useState('');
    var [userCNPJ, setUserCNPJ] = useState('');
    const [mask, setMask] = useState("");
    var [success, setSuccess] = useState('');
    var [successCEP, setSuccessCEP] = useState(true);
        
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const [confirmaSenha1, setConfirmaSenha1] = useState('');
    const [confirmaSenha2, setConfirmaSenha2] = useState('');

    const notify = (txt) => toast.success(txt, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

        const erro = (txt) => toast.error(txt, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });

        const history = useHistory();
        
        async function handleCEP(){
            console.log(userCEP);
            let one = "http://viacep.com.br/ws/"+userCEP+"/json";
            axios.get(one).then(response =>{
              //console.log(response.data);
              setSuccess('true');
              setUserRua(response.data.logradouro);
              setUserComplemento(response.data.complemento);
              setUserCidade(response.data.localidade);
              setUserBairro(response.data.bairro);
              setUserUF(response.data.uf);
              document.getElementById("CEP").style.borderColor = '';
              setSuccessCEP(true);
            }).catch(function (error){
              //console.log(error.message);
              setUserRua('');
              setUserComplemento('');
              setUserCidade('');
              setUserBairro('');
              setUserUF('');
              setSuccess('');
              setSuccessCEP(false);
              erro("CEP inválido.");
              document.getElementById("CEP").style.borderColor = 'red';
              
            });
        
          }

        useEffect(() => {
            console.log(localStorage.getItem('Id_Usuario'));
            var Id_Usuario = localStorage.getItem('Id_Usuario');
            var data ={
                Id_Usuario,
            }
                api.post("api/getAutenticado",data)
                .then(function(response) {
                    setUserCNPJ(response.data.CNPJ);
                    setUserEmail(response.data.email);
                    console.log(response.data);
                    setUser(response.data);
                    setUserCEP(response.data.CEP);
                    setUserId_Usuario(response.data.Id_Usuario);
                    setUserRua(response.data.Rua);
                    setUserComplemento(response.data.Complemento);
                    setUserCidade(response.data.Cidade);
                    setUserBairro(response.data.Bairro);
                    setUserUF(response.data.UF);
                    setLoading(false);
                });
                
            }, []);

            function validateEmail(email) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            }

    async function handlerEdit(){

        if(userName==''){
            userName = user.Nome;
        }
        if(userEmail==''){
            userEmail = user.email;
        }
        if(userIE==''){
            userIE = user.IE;
        }
        if(userRS==''){
            userRS = user.RS;
        }
        if(userIM==''){
            userIM = user.IM;
        }
        if(userTelefone1 == ''){
            userTelefone1 = user.Telefone1;
        }
        if(userTelefone2==''){
            userTelefone2 = user.Telefone2;
        }

        if(userUF===''){
            userUF = user.UF;
        }
        if(userCidade===''){
            userCidade = user.Cidade;
        }
        if(userBairro===''){
            userBairro = user.Bairro;
        }
        if(userRua===''){
            userRua = user.Rua;
        }
        if(userNum===''){
            userNum = user.Num;
        }
        if(userComplemento===''){
            userComplemento = user.Complemento;
        }
        if(userCNPJ===''){
            userCNPJ = user.CNPJ;
        }


        if(userCEP != ''){
            success = 'true';
        }

        if(success === "true" && successCEP == true){
            if(userNovaSenha != ''){
                userPassword = userNovaSenha;
            }

            var data ={
                userId_Usuario,
                    codPerfil : user.perfil.CodPerfil,
                    userName,
                    userEmail,
                    userRS,
                    userBairro,
                    userCEP,
                    userCNPJ,
                    userCidade,
                    userComplemento,
                    userIE,
                    userNum,
                    userPassword,
                    userRua,
                    userTelefone1,
                    userTelefone2,
                    userUF,
                    userIM
                }

                var sucesso = verificaCampos(data);

                var cpfcnpj = userCNPJ;

                cpfcnpj = cpfcnpj.replace('/','');
                cpfcnpj = cpfcnpj.replace('.','');
                cpfcnpj = cpfcnpj.replace('.','');
        
                cpfcnpj = cpfcnpj.replace('-','');
        
                data.userCNPJ = cpfcnpj;

                if(sucesso == true){
                    document.getElementById("Telefone2").style.borderColor = "";
                    document.getElementById("Telefone1").style.borderColor = "";
                    document.getElementById("CPFCNPJ").style.borderColor = "";
                    document.getElementById("Email").style.borderColor = "";
                    document.getElementById("Senha").style.borderColor = "";

                    const response = api.post("api/editUser",data).then(function(response){
                        console.log(response.data);
                        setUser(response.data);
                        notify("Atualizado com sucesso!");
                        //setAlert4(true);
                        handleClose();
                    });
                }else{
                    handleClose();
                    erro("Há campos inválidos.");
                }
        }else{
            erro("Digite um CEP válido.");
        }
        setShow(false); 

    }

    function verifica_cpf_cnpj ( valor ) {

        // Garante que o valor é uma string
        valor = valor.toString();
        
        // Remove caracteres inválidos do valor
        valor = valor.replace(/[^0-9]/g, '');
    
        // Verifica CPF
        if ( valor.length === 11 ) {
            return 'CPF';
        } 
        
        // Verifica CNPJ
        else if ( valor.length === 14 ) {
            return 'CNPJ';
        } 
        
        // Não retorna nada
        else {
            return false;
        }
        
    }
    function calc_digitos_posicoes( digitos, posicoes = 10, soma_digitos = 0 ) {
    
        // Garante que o valor é uma string
        digitos = digitos.toString();
    
        // Faz a soma dos dígitos com a posição
        // Ex. para 10 posições:
        //   0    2    5    4    6    2    8    8   4
        // x10   x9   x8   x7   x6   x5   x4   x3  x2
        //   0 + 18 + 40 + 28 + 36 + 10 + 32 + 24 + 8 = 196
        for ( var i = 0; i < digitos.length; i++  ) {
            // Preenche a soma com o dígito vezes a posição
            soma_digitos = soma_digitos + ( digitos[i] * posicoes );
    
            // Subtrai 1 da posição
            posicoes--;
    
            // Parte específica para CNPJ
            // Ex.: 5-4-3-2-9-8-7-6-5-4-3-2
            if ( posicoes < 2 ) {
                // Retorno a posição para 9
                posicoes = 9;
            }
        }
    
        // Captura o resto da divisão entre soma_digitos dividido por 11
        // Ex.: 196 % 11 = 9
        soma_digitos = soma_digitos % 11;
    
        // Verifica se soma_digitos é menor que 2
        if ( soma_digitos < 2 ) {
            // soma_digitos agora será zero
            soma_digitos = 0;
        } else {
            // Se for maior que 2, o resultado é 11 menos soma_digitos
            // Ex.: 11 - 9 = 2
            // Nosso dígito procurado é 2
            soma_digitos = 11 - soma_digitos;
        }
    
        // Concatena mais um dígito aos primeiro nove dígitos
        // Ex.: 025462884 + 2 = 0254628842
        var cpf = digitos + soma_digitos;
    
        // Retorna
        return cpf;
        
    } 
    function valida_cpf( valor ) {

        if(valor == '11111111111') return false;
        if(valor == '22222222222') return false;
        if(valor == '33333333333') return false;
        if(valor == '44444444444') return false;
        if(valor == '55555555555') return false;
        if(valor == '66666666666') return false;
        if(valor == '77777777777') return false;
        if(valor == '88888888888') return false;
        if(valor == '99999999999') return false;

    
        // Garante que o valor é uma string
        valor = valor.toString();
        
        // Remove caracteres inválidos do valor
        valor = valor.replace(/[^0-9]/g, '');
    
    
        // Captura os 9 primeiros dígitos do CPF
        // Ex.: 02546288423 = 025462884
        var digitos = valor.substr(0, 9);
    
        // Faz o cálculo dos 9 primeiros dígitos do CPF para obter o primeiro dígito
        var novo_cpf = calc_digitos_posicoes( digitos );
    
        // Faz o cálculo dos 10 dígitos do CPF para obter o último dígito
        var novo_cpf = calc_digitos_posicoes( novo_cpf, 11 );
    
        // Verifica se o novo CPF gerado é idêntico ao CPF enviado
        if ( novo_cpf === valor ) {
            // CPF válido
            return true;
        } else {
            // CPF inválido
            return false;
        }
        
    } 
    function valida_cnpj ( valor ) {
    
        // Garante que o valor é uma string
        valor = valor.toString();
        
        // Remove caracteres inválidos do valor
        valor = valor.replace(/[^0-9]/g, '');
    
        
        // O valor original
        var cnpj_original = valor;
    
        // Captura os primeiros 12 números do CNPJ
        var primeiros_numeros_cnpj = valor.substr( 0, 12 );
    
        // Faz o primeiro cálculo
        var primeiro_calculo = calc_digitos_posicoes( primeiros_numeros_cnpj, 5 );
    
        // O segundo cálculo é a mesma coisa do primeiro, porém, começa na posição 6
        var segundo_calculo = calc_digitos_posicoes( primeiro_calculo, 6 );
    
        // Concatena o segundo dígito ao CNPJ
        var cnpj = segundo_calculo;
    
        // Verifica se o CNPJ gerado é idêntico ao enviado
        if ( cnpj === cnpj_original ) {
            return true;
        }
        
        // Retorna falso por padrão
        return false;
        
    } 
    function valida_cpf_cnpj ( valor ) {
    
        // Verifica se é CPF ou CNPJ
        var valida = verifica_cpf_cnpj( valor );
    
        // Garante que o valor é uma string
        valor = valor.toString();
        
        // Remove caracteres inválidos do valor
        valor = valor.replace(/[^0-9]/g, '');
    
    
        // Valida CPF
        if ( valida === 'CPF' ) {
            // Retorna true para cpf válido
            return valida_cpf( valor );
        } 
        
        // Valida CNPJ
        else if ( valida === 'CNPJ' ) {
            // Retorna true para CNPJ válido
            return valida_cnpj( valor );
        } 
        
        // Não retorna nada
        else {
            return false;
        }
        
    } 
    function formata_cpf_cnpj( valor ) {
    
        // O valor formatado
        var formatado = false;
        
        // Verifica se é CPF ou CNPJ
        var valida = verifica_cpf_cnpj( valor );
    
        // Garante que o valor é uma string
        valor = valor.toString();
        
        // Remove caracteres inválidos do valor
        valor = valor.replace(/[^0-9]/g, '');
    
    
        // Valida CPF
        if ( valida === 'CPF' ) {
        
            // Verifica se o CPF é válido
            if ( valida_cpf( valor ) ) {
            
                // Formata o CPF ###.###.###-##
                formatado  = valor.substr( 0, 3 ) + '.';
                formatado += valor.substr( 3, 3 ) + '.';
                formatado += valor.substr( 6, 3 ) + '-';
                formatado += valor.substr( 9, 2 ) + '';
                
            }
            
        }
        
        // Valida CNPJ
        else if ( valida === 'CNPJ' ) {
        
            // Verifica se o CNPJ é válido
            if ( valida_cnpj( valor ) ) {
            
                // Formata o CNPJ ##.###.###/####-##
                formatado  = valor.substr( 0,  2 ) + '.';
                formatado += valor.substr( 2,  3 ) + '.';
                formatado += valor.substr( 5,  3 ) + '/';
                formatado += valor.substr( 8,  4 ) + '-';
                formatado += valor.substr( 12, 14 ) + '';
                
            }
        } 
        // Retorna o valor 
        return formatado;
    }

    function verificaCampos(data){
        //console.log(formata_cpf_cnpj(data.userCNPJ)+data.userCNPJ);
        var cpfcnpjaux =  data.userCNPJ;

        var cpfcnpj1 = cpfcnpjaux;

        cpfcnpj1 = cpfcnpj1.replace('/','');
        cpfcnpj1 = cpfcnpj1.replace('.','');
        cpfcnpj1 = cpfcnpj1.replace('.','');

        cpfcnpj1 = cpfcnpj1.replace('-','');

        cpfcnpjaux = cpfcnpj1;
        

        var flag = true;
        if(data.userTelefone1.search('_') != -1){
            document.getElementById("Telefone1").style.borderColor = "red";
            flag = false;
        }else{
            document.getElementById("Telefone1").style.borderColor = "";
        }

        if(data.userEmail.search('@') == -1){
            document.getElementById("Email").style.borderColor = "red";
            flag = false;
        }else{
            document.getElementById("Email").style.borderColor = "";
        }

        // if(data.userTelefone2.search('_') != -1){
        //     document.getElementById("Telefone2").style.borderColor = "red";
        //     flag = false;
        // }else{
        //     document.getElementById("Telefone2").style.borderColor = "";
        // }
        if(userNovaSenha != ''){     
            if((data.userPassword).length < 6 && (data.userPassword) != ''){
                
                document.getElementById("NovaSenha").style.borderColor = "red";
                flag = false;
            }else{
                document.getElementById("NovaSenha").style.borderColor = "";
            }
        }
        
        if(valida_cpf_cnpj(cpfcnpjaux) == false){
            document.getElementById("CPFCNPJ").style.borderColor = "red";
            flag = false;
        }else{
            document.getElementById("CPFCNPJ").style.borderColor = "";
        }

        return flag;
    }
   
    function handleModal(){
            var email = user.email;
            var password = confirmaSenha1;
            try{
                api.post('api/auth/login', {email, password }).then(function(response){
                    handlerEdit();
                }).catch(() => {
                    setAlert6(true);
                });
            }catch(err){
                setAlert6(true);

            }
    }

    function handleSenha(){
        document.getElementById("Senha").style.borderColor = '';
        document.getElementById("NovaSenha").style.borderColor = '';
        document.getElementById("ConfirmaSenha").style.borderColor = '';
        if(userNovaSenha == '' && userNovaSenhaConf == ''){
            handleShow();
        }else{
            if(userPassword != ''){
                var email = user.email;
                var password = userPassword;
                
                    api.post('api/auth/login', {email, password }).then(function(response){
                        if(userNovaSenha == userNovaSenhaConf){
                            handlerEdit();
                        }else{
                            document.getElementById("NovaSenha").style.borderColor = 'red';
                            document.getElementById("ConfirmaSenha").style.borderColor = 'red';
                            erro("Senhas devem ser iguais");
                        }
                    }).catch(() => {
                        document.getElementById("Senha").style.borderColor = 'red';
                        erro("Senha incorreta.");
                    });
                
            }else{
                document.getElementById("Senha").style.borderColor = 'red';
                erro("Informe a senha atual.");
            }
            
        }
        
    }
    
    async function handlerBack(){
        history.push('/dashboard');
    }

    async function handlerDelete(){
        try {
            localStorage.setItem('token',null)
        } catch (err) {
            
            alert('Nao foi possível apagar.');
        }
        var data={
            Id_Usuario : user.Id_Usuario
        };

        const response = api.post("api/deleteUsuario",data).then(function(response) {
            console.log(response.data);
            localStorage.setItem('deslogado',1)
            history.push('/dashboard');

        });
    }

    return (
        !loading &&
        
        <Container>
            <h1 className="il">Q</h1>
            <Link to="/dashboard">
                <Button className="ir">
                        Voltar
                </Button>
            </Link>
            {alert4  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert4(false)}>Atualizado com sucesso.</Alert>}
            {alert3  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert3(false)}>Digite um CEP válido.</Alert>}
            {alert1  && <Alert variant="danger" size="sm" dismissible onClose={() =>setAlert1(false)}>Há campos inválidos.</Alert>}
            <ToastContainer autoClose={4000} />
            <Col>
            <Form className="mt-5">
                <Form.Row>
                     <Col md={6} xs={12}>    
                        <Form.Label size="md">Email*</Form.Label>
                        <input type="email" className="form-control" id="Email" aria-describedby="emailHelp" placeholder={user.email} onChange={e => (setUserEmail(e.target.value))} onBlur={() => validateEmail(userEmail)} />
                    </Col>
                    <Col md={2} xs={12}>
                        <Form.Label size="md">Senha Atual</Form.Label>
                        <Form.Control size="md" type="password" id="Senha" placeholder="Sua senha" onChange={e => setUserPassword(e.target.value)} />
                    </Col>

                    <Col md={2} xs={12}>
                        <Form.Label size="md">Nova Senha</Form.Label>
                        <Form.Control size="md" type="password" id="NovaSenha" placeholder="Digitar nova Senha" onChange={e => setUserNovaPassword(e.target.value)} />
                    </Col>

                    <Col md={2} xs={12}>
                        <Form.Label size="md">Confirmar Nova Senha</Form.Label>
                        <Form.Control size="md" type="password" id="ConfirmaSenha" placeholder="Confirmar nova Senha" onChange={e => setUserNovaPasswordConf(e.target.value)} />
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col md={8} xs={12}>
                        <Form.Label size="md">Razão Social</Form.Label>
                        <Form.Control size="md" type="text" placeholder={user.RS} onChange={e => setUserRS(e.target.value)}/>
                    </Col>
                    <Col md={4} xs={12}>
                        <Form.Label size="md">Perfil</Form.Label>
                        <Form.Control size="md" type="text" placeholder={user.perfil.Descricao} disabled/>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col md={6} xs={12}>
                        <Form.Label>Nome*</Form.Label>
                        <Form.Control type="text" id='name' placeholder={user.Nome} onChange={e => setUserName(e.target.value)} required/>
                    </Col>

                    <Col md={6} xs={12}>
                        <Form.Label>CPF/CNPJ*</Form.Label>
                        <CpfCnpj
                        id="CPFCNPJ"
                        type="text"
                        value={userCNPJ}
                        className="form-control"
                          onChange={(event, type) => {
                            setUserCNPJ(event.target.value);
                            setMask(type === "CNPJ");
                          }}
                        />
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col md={6} xs={12}>
                        <Form.Label>Telefone 1*</Form.Label>
                        
                        <InputMask className="form-control" id="Telefone1" mask="99 99999-9999"  onChange={e => setUserTelefone1(e.target.value)} placeholder={user.Telefone1}/>
                    </Col>

                    <Col md={6} xs={12}>
                        <Form.Label>Telefone 2</Form.Label>
                        <InputMask className="form-control" id="Telefone2" mask="99 99999-9999"  onChange={e => setUserTelefone2(e.target.value)} placeholder={user.Telefone2}/>
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col md={6} xs={12}>
                        <Form.Label>Inscrição Estadual</Form.Label>
                        <Form.Control type="text" placeholder={user.IE} onChange={e => setUserIE(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                    </Col>

                    <Col md={6} xs={12}>
                        <Form.Label>Inscrição Municipal</Form.Label>
                        <Form.Control type="text" placeholder={user.IM} onChange={e => setUserIM(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} />
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col md={4} xs={12}>
                        <Form.Label>CEP*</Form.Label>
                        <Form.Control placeholder={userCEP} id="CEP" onChange={(e) => setUserCEP(e.target.value)} onBlur={()=> handleCEP()}/>
                    </Col>

                    <Col md={4} xs={12}>
                        <Form.Label>Cidade</Form.Label>
                        <Form.Control value={userCidade} disabled/>

                    </Col>

                    <Col md={4} xs={12}>
                        <Form.Label>Estado</Form.Label>
                        <Form.Control value={userUF} disabled/>

                    </Col>
                    <Col md={10} xs={8}>
                        <Form.Label>Rua</Form.Label>
                        <Form.Control placeholder={userRua}  onChange={e => setUserRua(e.target.value)}/>
                    </Col>

                    <Col md={2} xs={4}>
                        <Form.Label>Numero</Form.Label>
                        <Form.Control placeholder={user.Num} onChange={e => setUserNum(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                    
                    </Col>
                    
                    <Col md={4} xs={12}>
                        <Form.Label>Bairro</Form.Label>
                        <Form.Control placeholder={userBairro} onChange={e => setUserBairro(e.target.value)}/>
                    </Col>
                    <Col md={8} xs={12}>
                     <Form.Label>Complemento</Form.Label>
                        <Form.Control placeholder={userComplemento} onChange={e => setUserComplemento(e.target.value)}/>
                        </Col>
                </Form.Row>

                <Col md={6} xs={12}>
                    <Row>
                        <Button  variant="warning" onClick={handleSenha}>
                            Confirmar
                        </Button>

                        <Button variant="danger" className="ml-2" onClick={handlerDelete}>
                            Desativar
                        </Button>
                    </Row>
                </Col>
                <Modal show={show} onHide={handleClose}>
            {alert5  && <Alert variant="danger" className="mt-2" size="sm" dismissible onClose={() =>setAlert5(false)}>Senhas não conferem.</Alert>}
            {alert6  && <Alert variant="danger" className="mt-2" size="sm" dismissible onClose={() =>setAlert6(false)}>Senha incorreta.</Alert>}


                    <Modal.Header closeButton>
                        <Modal.Title>Confirmar sua Senha</Modal.Title>
                            </Modal.Header>
                                 <Modal.Body>
                                     <Row>
                                     <Col md={6}>
                                    <Form.Control 
                                    type="password" 
                                    placeholder="Sua senha"
                                    onChange={e => setConfirmaSenha1(e.target.value)}/>
                                    </Col>
   
                                    </Row>
                       </Modal.Body>
                     <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                         Cancelar
                        </Button>
                         <Button variant="primary" onClick={handleModal}>
                             Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Form>
            </Col>
            
        </Container>
    );
}

