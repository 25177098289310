import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Card, Row , Col, Form, Modal, Accordion, Spinner, Alert,ListGroup} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import api from '../../../services/api';

import Plus from '../../../images/add.svg';
import Trash from '../../../images/trash.svg';
import Eye from '../../../images/olho.svg';
import Delete from '../../../images/close.svg';
import { toast, ToastContainer } from 'react-toastify';
import NumberFormat from 'react-number-format';

import './styles.css';
import { Collapse } from '@material-ui/core';

export default function Material(){
    const [loading, setLoading] = useState(true);
    var [material, setMaterial] = useState([]);
    const [insumos, setInsumos] = useState([]);
    const [tipos, setTipos] = useState([]);
    const [caracteristicas, setCaracteristicas] = useState([]);
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [alert1, setAlert1] = useState(false);
    const [alert2, setAlert2] = useState(false);
    const [alert3, setAlert3] = useState(false);
    const [alert4, setAlert4] = useState(false);
    const [alert5, setAlert5] = useState(false);
    const history = useHistory();
    const [selecteds, setSelecteds] = useState([]);
    var [selectedIndex, setSelectedIndex] = useState([]);
    var [selectedIndexEdit, setSelectedIndexEdit] = useState([]);
    var [editNome, setEditNome] = useState('');
    var [editCodigo, setEditCodigo] = useState('');
    var [editInsumoNome, setEditInsumoNome] = useState('');
    var [editId_Insumo, setEditId_Insumo] = useState('');
    var [editUnidade, setEditUnidade] = useState('');
    const [filtred, setFiltred] = useState([]);
    const [search, setSearch] = useState("");
    var [selectedValue, setSelectedValue] = useState('');
    var [createNome, setCreateNome] = useState('');
    var [estoqueMinimo, setEstoqueMinimo] = useState('');
    var [createUnidade, setCreateUnidade] = useState('');
    var [createCodigo, setCreateCodigo] = useState('');

    async function handleCreate(){
        var Id_Usuario = localStorage.getItem('Id_Usuario');
        if(estoqueMinimo == ''){estoqueMinimo = material.Estoque_Minimo;}
        if(selectedValue !== ''){
            if(createNome !==''){
                if(createUnidade !==''){
                    if(createCodigo !==''){
                        var data = {
                            estoqueMinimo,
                            Id_Usuario,
                            selectedValue,
                            createNome,
                            createUnidade,
                            createCodigo,
                            selecteds
                        };

                        api.post("api/createMateria",data)
                        .then(function(response){
                            console.log(response.data);
                        });
                        console.log(data);
                    }else{
                        alert("Digite um código válido");
                    }
                }else{
                    alert("Digite uma unidade válida");
                }
            }else{
                alert("Digite um nome válido");
            }
        }else{
            alert("Selecione um Insumo-Tipo válido");
        }
        
    }

    async function todo(){
        console.log(caracteristicas[selectedIndex]);
        if(!caracteristicas[selectedIndex]){
            setSelecteds([...selecteds,caracteristicas[0]]);
        }else{
            setSelecteds([...selecteds,caracteristicas[selectedIndex]]);
        }
    }
    
    function todoEdit(indexM){
        const items = [...material];
        const item = {...items[indexM]};
        if(!caracteristicas[selectedIndexEdit]){
            item.caracteristica.push(caracteristicas[0]);
            items[indexM] = item;
        }else{
            item.caracteristica.push(caracteristicas[selectedIndexEdit]);
            items[indexM] = item;
        }
        setMaterial(items);
    }    

    async function createTodo(indexM){
        const items = [...material];
        const item = {...items[indexM]};
        var toCreate ={
            "Descricao": '',
            "Nome" : ''
        }
        item.caracteristica.push(toCreate);
        items[indexM] = item;
        setMaterial(items);
    }

    function removeTodoAllEdit(materiaPrima,indexM){
        var items = [...material];
        var item = items[indexM];
        console.log(item);
        item.caracteristica = null
        console.log(item);
        items[indexM] = item;
        console.log(items);
        setMaterial(items);
    }

    function handlerEdit(materiaPrima){
        console.log(materiaPrima);
        if(estoqueMinimo == ''){estoqueMinimo = material.Estoque_Minimo;}
        var Id_Usuario = localStorage.getItem('Id_Usuario');
        var selectedsEdit = materiaPrima.caracteristica;
        var CodProduto = materiaPrima.CodProduto;
        if(editInsumoNome === ''){
             editId_Insumo = materiaPrima.Id_Insumo;
        }else{
            insumos.forEach(element => {
                if(element.Nome === editInsumoNome){
                    editId_Insumo = element.Id_Insumo;
                }
            });
        }
        if(editNome === ''){
            editNome = materiaPrima.Nome;
        }
        if(editCodigo === ''){
            editCodigo = materiaPrima.Codigo;
        }
        if(editUnidade === ''){
            editUnidade = materiaPrima.Unidade;
        }
        var data = {
            estoqueMinimo,
            CodProduto,
            Id_Usuario,
            editId_Insumo,
            editNome,
            editUnidade,
            editCodigo,
            selectedsEdit
        };
        console.log(data);
        api.post("api/updateMateria",data).then(function(response) {
            api.post("api/getMateria")
            .then(function(response) {
             setMaterial(response.data);
             notify("Atualizado com sucesso.");
            });
        });
    }

   

    function removeTodo(index){
        console.log(selecteds);
        const list = selecteds;
        list.splice(index,1);
        setSelecteds([...list]);
        console.log(selecteds);
    }

    function removeTodoEdit(indexM,index){
        var items = [...material];
        var item = items[indexM];
        const caract = item.caracteristica;
        caract.splice(index,1);
        item.caracteristica = caract;
        items[indexM] = item;
        setMaterial(items);
    }

    async function deleteTodoEdit(indexM,index){
        var items = [...material];
        var item = items[indexM];
        var caract = item.caracteristica;
        var toDelete = caract.splice(index,1);
        var CodProduto = item.CodProduto;
        var Id_Caracteristica = toDelete[0].Id_Caracteristica;
        var data = {
            CodProduto,
            Id_Caracteristica,
        };

        await api.post("api/deleteCaracteristica",data);
        
        await api.post("api/getCaracteristicas").then(function(response){
            setCaracteristicas(response.data);
            item.caracteristica = caract;
            items[indexM] = item;
            setMaterial(items);
        });
        console.log(material);
    }

    async function removeTodoAll(){
        setSelecteds([]);
    }
    const notify = (txt) => toast.success(txt, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

        
    const erro = (txt) => toast.error(txt, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });


    async function handlerDelete(id){
        console.log(id);
        api.post("api/deleteMateria",{CodProduto: id}).then(function(response) {
            api.post("api/getMateria")
            .then(function(response) {
             setMaterial(response.data);
             setAlert1(true);
            });

        });
    }
    useEffect(() => {
        if(localStorage.getItem('Entrada') == 1){
            notify("Entrada cadastrada com sucesso.");
           
        }
        if(localStorage.getItem('Saida') == 1){
            notify("Saída cadastrada com sucesso.");
        }

        localStorage.setItem('Entrada',0);
        localStorage.setItem('Saida',0);

        api.post("api/getMateria")
        .then(function(response) {
            setMaterial(response.data);
            api.post("api/getCaracteristicas")
            .then(function(response){
            setCaracteristicas(response.data);
                api.post("api/getInsumos")
                .then(function(response){
                    setInsumos(response.data);
                    api.post("api/getTipos")
                    .then(function(response){
                        setTipos(response.data);
                    })
                    setLoading(false);
                })
            });
        });
    }, []);
    useEffect(() => {
        setFiltred(
          material.filter((mat) =>
          mat.Nome.toLowerCase().includes(search.toLowerCase())
          )
        );
      }, [search, material]);

    material.forEach(materia => {
        if(materia.caracteristica === null){materia.caracteristica = []};
        tipos.forEach(tipo=>{
            if(materia.insumos.Id_Tipo == tipo.Id_Tipo){
                materia.insumos.Id_Tipo = tipo;
            }
        });
    });
    console.log(material);

if(loading == false){
    return (
       
        <Container>
            <h1 className="il">Q</h1>
            <Link to="/quansquie/">
                <Button className="ir">
                        Voltar
                </Button>
            </Link>
            <ToastContainer autoClose={4000} />
            
            {alert1  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert1(false)}>Excluído com sucesso.</Alert>}
            {alert2  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert2(false)}>Adicionado com sucesso.</Alert>}
            {alert4  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert4(false)}>Atualizado com sucesso.</Alert>}
            {alert5  && <Alert variant="success" size="sm" dismissible onClose={() =>setAlert5(false)}>Saída cadastrada com sucesso.</Alert>}
            <Row className="justify-content-md-center">
                <Col xs={12} md={8}>
                <Row>
                        <Col xs={12} md={6}><h2 className="ml-2">Matéria-Prima</h2></Col> 
                        <h2>Filtro:</h2><Col className="mb-2" xs={12} md={4}><Form.Control value={search} onChange={(e) => setSearch(e.target.value)}></Form.Control></Col>
                    </Row>
                    <Card className="card_items">
                        <Card.Body>
                            <Row><Col md={4}><h2>Nome</h2></Col></Row>
                        <Accordion defaultActiveKey="0">
                            {filtred.map((materiaPrima,indexM)=> 
                                <Row className="item_tipo  mt-2 mb-2">
                                    <Col xs={12} md={12}>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey={materiaPrima.CodProduto}> <Row><Col xs={10} md={10}><Form.Label>{materiaPrima.Nome+" - "+ materiaPrima.insumos.Nome+ " - " + materiaPrima.insumos.Id_Tipo.Nome}</Form.Label></Col>
                            
                                    <Col xs={2} md={2}>
                                    {(materiaPrima.Usado == 1)? '' :
                                        <div className="mg-top">
                                            <button className="ml-2" onClick={() => handlerDelete(materiaPrima.CodProduto,indexM)}><img className="img" src={Trash}></img></button>
                                        </div>
                                    }
                                    </Col>
                              </Row></Accordion.Toggle>
                                        <Accordion.Collapse eventKey={materiaPrima.CodProduto}>
                                            <Col xs={12}>
                                                <Row className="justify-content-md-center mt-2">
                                                    <Col  xs={12} md={6}>
                                                        <Form.Label>Nome</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={materiaPrima.Nome} onChange={(e) => setEditNome(e.target.value)} disabled/>
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <Form.Label>Código</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={materiaPrima.Codigo} onChange={(e) => setEditCodigo(e.target.value)} disabled/>
                                                    </Col>
                                               
                                                    <Col xs={12} md={6}>
                                                        <Form.Label>Estoque mínimo</Form.Label>
                                                        <NumberFormat allowNegative={false} maxLength="11"  decimalScale={2} fixedDecimalScale={true} isNumericString={true}  decimalSeparator={','} placeholder={materiaPrima.Estoque_Minimo} className="form-control form-control-md" inputMode="numeric"  onChange={(e) => setEstoqueMinimo(e.target.value.replace(",","."))} />

                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <Form.Label>Estoque</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={materiaPrima.Estoque_Total.replace(".",",")} disabled />
                                                    </Col>
                                               
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>Insumos</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={materiaPrima.insumos.Nome} onChange={(e) => setEditInsumoNome(e.target.value)} disabled/>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>Tipo</Form.Label>
                                                        <Form.Control size="md" type="text" placeholder={materiaPrima.insumos.Id_Tipo.Nome} disabled/>
                                                    </Col>

                                                <Col xs={12} md={6}>
                                                    <Form.Label>Unidade</Form.Label>
                                                    <select disabled value={materiaPrima.Unidade}  className="form-control form-control-md" onChange={(e) => (setSelectedIndexEdit(e.target.value),materiaPrima.Unidade = e.target.value)}>
                                                            <option value="1">Metro</option>
                                                            <option value="2">Quilo</option>
                                                            <option value="3">Peça</option>
                                                            <option value="4">Unidade</option>
                                                    </select>
                                                </Col>
                                                {/*
                                                <Col xs={12} md={6}>
                                                    <Form.Label>Caracteristicas</Form.Label>
                                                    <select className="form-control form-control-md" onChange={(e) => setSelectedIndexEdit(e.target.value)}>
                                                        {caracteristicas.map((caracteristica, index) =>
                                                            <option value={index}>{caracteristica.Nome +' - '+caracteristica.Descricao}</option>
                                                        )}
                                                    </select>
                                                </Col>
                                                <Col>
                                                    <Button variant="secondary" className="mt-4" onClick={()=> todoEdit(indexM)}>Adicionar</Button>
                                                </Col>
                                                */}
                                            </Row>
                                            <Row className="justify-content-md-center ml-2 mr-2 mt-2 item_tipo">
                                            <Form.Label> <h4 className="mt-3 ml-2">Lista de caracteristicas</h4></Form.Label>
                                                <Container className="justify-content-sm-center">
                                                    <Row className="ml-2"><Col xs={4} md={5}><Form.Label><h4>Nome</h4></Form.Label></Col><Col xs={3} md={4}><Form.Label><h4>Descrição</h4></Form.Label></Col><Col  xs={2} md={2}>{/*<!--<img className="img_mini ml-5" src={Trash} onClick={() => removeTodoAllEdit(materiaPrima, indexM)}/>*/}</Col></Row>
                                                    {materiaPrima.caracteristica.map((selected,index) => (
                                                        <Row className="ml-2 mr-2">
                                                            <Col xs={12} md={5}>
                                                                <Form.Label className="form-control form-control-gb">{selected.Nome}</Form.Label>
                                                            </Col>
                                                            <Col xs={12} md={5}>
                                                                <Form.Label className="form-control form-control-gb">{selected.Descricao}</Form.Label>
                                                            </Col>
                                                            <Col xs={12} md={2}> 
                                                                <Row className="justify-content-xs-md-center mb-3"> 
                                                                    {/*<!-- <Col xs={{ span: 1, offset: 4 }} md={1}><img className="img_mini" src={Trash} onClick={() =>  removeTodoEdit(indexM,index)}/></Col> */}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </Container>
                                            </Row>  
                                                <Button className="mt-2 ml-4 mb-2" variant="primary" onClick={() => handlerEdit(materiaPrima,indexM)}>
                                                    Confirmar
                                                </Button>
                                            </Col>
                                        </Accordion.Collapse>
                                    </Col>

                                </Row>
                            )}
                            </Accordion>
                            
                        </Card.Body>
                        
                    </Card>
                </Col>
            </Row>
        </Container>
    );
    }else{
        return (
            <Row className="justify-content-center">
        <Spinner animation="border" className="central" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
        </Row>
        );
    }
}